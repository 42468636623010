import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Stack, Link, Typography } from "@mui/material";
import {
  getAuthState,
  getAuthClaims,
  getJwtOptions,
  FormBuilder,
  Form,
  ErrorMessage,
} from "@redriver/cinnamon-mui";
import { SystemRoutes } from "constants/routes";
import { SubmitButton } from "components/buttons";
import { requestLogin } from "./actions";
import { EmailVerificationResender } from "../EmailVerification";
import AzureAdSignInButton from "./AzureAdSignInButton";

const loginErrors = {
  915005: "Account is inactive",
};

const Login = ({ loggedIn, emailVerified, useEmailVerification, nextPath }) => {
  if (loggedIn) {
    if (emailVerified || !useEmailVerification) {
      return (
        <Typography variant="body2">
          You have been successfully signed in.
        </Typography>
      );
    }

    return (
      <div>
        <Typography variant="body2" marginBottom={3}>
          Your email address has not been verified.
        </Typography>
        <EmailVerificationResender />
      </div>
    );
  }

  return (
    <FormBuilder
      submitAction={requestLogin}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { submitting, slowSubmitting, error },
        events
      ) => (
        <Form {...formProps}>
          <Form.Email
            field="email"
            label="Email"
            placeholder="Enter email address"
            disabled={submitting && slowSubmitting}
            autoFocus
            required
            fluid
            inputProps={{ autoComplete: "email" }}
          />
          <Form.Password
            field="password"
            label="Password"
            placeholder="Enter password"
            disabled={submitting && slowSubmitting}
            required
            fluid
            inputProps={{ autoComplete: "current-password" }}
            actions={
              <Link
                variant="caption"
                component={RouterLink}
                to={SystemRoutes.ForgottenPassword}
              >
                Forgot Password?
              </Link>
            }
            sx={{
              mb: 0,
              "> .actions": { float: "right" },
            }}
          />
          <Stack spacing={4} sx={{ clear: "both", mb: 4 }}>
            <SubmitButton
              variant="contained"
              onClick={events.onSubmit}
              submitting={submitting}
              slowSubmitting={slowSubmitting}
              sx={{ mt: 3 }}
            >
              Log In
            </SubmitButton>
            <ErrorMessage
              header="Sign In Error"
              error={error}
              overrideMessages={loginErrors}
              omitCodes
            />
          </Stack>
          <Typography variant="caption" display="block" gutterBottom>
            If you are an Energy Engineering employee
          </Typography>
          <AzureAdSignInButton nextPath={nextPath} />
        </Form>
      )}
    />
  );
};

const mapStateToProps = (state) => {
  const { loggedIn } = getAuthState(state);
  const { emailVerified } = getAuthClaims(state);
  const { useEmailVerification } = getJwtOptions();
  return {
    loggedIn,
    emailVerified,
    useEmailVerification,
  };
};

export default connect(mapStateToProps)(Login);
