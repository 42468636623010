import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 13 18"
    fill="currentColor"
  >
    <g id="Group_6596" data-name="Group 6596">
      <path
        id="Path_4608"
        data-name="Path 4608"
        d="M77.073,0a6.517,6.517,0,0,0-6.5,6.519c0,4.461,5.817,11.01,6.065,11.286a.584.584,0,0,0,.871,0c.248-.277,6.065-6.825,6.065-11.286A6.517,6.517,0,0,0,77.073,0Zm0,9.8a3.28,3.28,0,1,1,3.27-3.28A3.279,3.279,0,0,1,77.073,9.8Z"
        transform="translate(-70.573)"
      />
    </g>
  </svg>,
  "Marker"
);
