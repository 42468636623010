import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 30 30"
  >
    <g id="presentation" transform="translate(-1 -1)">
      <path
        id="Path_5857"
        data-name="Path 5857"
        d="M30,1H2A1,1,0,0,0,1,2V5H31V2A1,1,0,0,0,30,1Z"
        fill="#29abd4"
      />
      <path
        id="Path_5858"
        data-name="Path 5858"
        d="M22,14h1v5H22Z"
        fill="#ec5705"
      />
      <path
        id="Path_5859"
        data-name="Path 5859"
        d="M9,17h1v2H9Z"
        fill="#00a14b"
      />
      <path
        id="Path_5860"
        data-name="Path 5860"
        d="M15.5,12h1v7h-1Z"
        fill="#ffb400"
      />
      <path
        id="Path_5861"
        data-name="Path 5861"
        d="M2,23a1,1,0,0,0,1,1H15v2.21a2.5,2.5,0,1,0,2,0V24H29a1,1,0,0,0,1-1V7H2Zm14,6a.5.5,0,1,1,.5-.5A.5.5,0,0,1,16,29ZM6,19H7V16a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1v3h1.5V11a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1v8H20V13a1,1,0,0,1,1-1h3a1,1,0,0,1,1,1v6h1a1,1,0,0,1,0,2H6a1,1,0,0,1,0-2Z"
        fill="#0a67b2"
      />
    </g>
  </svg>,
  "Projects"
);
