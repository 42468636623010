import {
  getJwtOptions,
  useAuthClaims,
  useLoggedIn,
} from "@redriver/cinnamon-mui";
import { SystemPageContent } from "components/pages";
import { AppRoutes } from "constants/routes";
import { Login } from "features/System";
import { Navigate, useLocation } from "react-router-dom";

const LoginPage = () => {
  const location = useLocation();
  const loggedIn = useLoggedIn();

  const { emailVerified } = useAuthClaims();
  const { useEmailVerification } = getJwtOptions();

  if (loggedIn && (emailVerified || !useEmailVerification)) {
    const nextRoute = location.state?.nextLocation ?? AppRoutes.Root;
    return <Navigate to={nextRoute} state={{ loggedIn }} />;
  }

  return (
    <SystemPageContent title="Log In">
      <Login nextPath={location?.state?.nextLocation?.pathname} />
    </SystemPageContent>
  );
};

export default LoginPage;
