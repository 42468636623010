import {
  setApiOptions,
  enableJwtAuth,
  setLoggingOptions,
  enableSentryLogger,
  enableSegmentLogger,
  enableHotjarLogger,
  setPreferenceDefaults,
  enablePreferencesFromDeviceStorage,
  createDefaultReduxStore,
} from "@redriver/cinnamon-mui";
import reducers from "./reducers";
import { onRefreshJwt, onDeauthenticated } from "features/System";

export const setupApp = () => {
  configureApi();
  configureAuth();
  configureLogging();
  configurePreferences();
  const store = configureStore();
  return { store };
};

// api / auth modules

const configureApi = () =>
  setApiOptions({
    apiUrl: process.env.API_URL,
  });

const configureAuth = () =>
  enableJwtAuth({
    tokenKey: `${process.env.COOKIE_PREFIX}-token-key`,
    jwtClaims: {
      emailVerified: {
        type: "boolean",
        key: "ev",
      },
      userId: {
        type: "string",
        key: "sub",
      },
      userName: {
        type: "join",
        keys: ["fn", "ln"],
        separator: " ",
      },
      userEmail: {
        type: "string",
        key: "e",
        identity: "email",
      },
      userType: {
        type: "string",
        key: "ut",
      },
      resourceId: {
        type: "number",
        key: "ri",
      },
      contactId: {
        type: "string",
        key: "ci",
      },
      ssoProvider: {
        type: "string",
        key: "sso",
      },
      permissions: {
        type: "object",
        keys: "^p_(.+)",
      },
    },
    onRefreshJwt,
    onDeauthenticated,
  });

// logging module

const configureLogging = () => {
  setLoggingOptions({
    environment: process.env.LOGGING_ENVIRONMENT,
    release: process.env.LOGGING_RELEASE,
  });
  if (process.env.SENTRY_DSN) {
    enableSentryLogger(process.env.SENTRY_DSN);
  }
  if (process.env.SEGMENT_APIKEY) {
    enableSegmentLogger(process.env.SEGMENT_APIKEY);
  }
  if (process.env.HOTJAR_SITEID) {
    enableHotjarLogger(process.env.HOTJAR_SITEID);
  }
};

// preferences module

const configurePreferences = () => {
  setPreferenceDefaults({
    timezone: "system",
  });
  enablePreferencesFromDeviceStorage(
    `${process.env.COOKIE_PREFIX}-preferences`
  );
};

// redux store module

const configureStore = () =>
  createDefaultReduxStore({
    reducers,
    reduxDevTools: process.env.REDUX_DEVTOOLS,
  });
