import { useMatch } from "react-router-dom";
import { RoutingUtils } from "@redriver/cinnamon-mui";
import { PermissionCheck } from "components/auth";
import { NavMenu } from "components/navigation";
import { AppRoutes, AdminRoutes } from "constants/routes";
import { Actions, Targets } from "constants/permissions";
import UsersIcon from "assets/icons/menu/Users";
import RolesIcon from "assets/icons/menu/Roles";
import OfficesIcon from "assets/icons/menu/Offices";
import LookupsIcon from "assets/icons/menu/Lookups";
import CountriesIcon from "assets/icons/menu/Countries";
import CommoditiesIcon from "assets/icons/menu/Commodities";
import CertificateTypesIcon from "assets/icons/menu/CertificateTypes";
import GlobalRatesIcon from "assets/icons/menu/GlobalRates";
import GlobalInvoicingIcon from "assets/icons/menu/GlobalInvoicing";
import TaxRatesIcon from "assets/icons/menu/TaxRates";

const lookupPermissionTargets = [
  Targets.ResourceRole,
  Targets.Industry,
  Targets.Discipline,
  Targets.DisciplineType,
];

const AdminNavMenu = ({ children }) => {
  const adminPath = RoutingUtils.subRoutesPath(AppRoutes.Admin);
  const adminMatch = useMatch(adminPath);

  return (
    <PermissionCheck
      action={Actions.View}
      target={[
        ...lookupPermissionTargets,
        Targets.User,
        Targets.Role,
        Targets.Office,
        Targets.Country,
        Targets.Commodity,
        Targets.CertificateType,
        Targets.GlobalRate,
        Targets.GlobalInvoicing,
      ]}
      any
    >
      <NavMenu title={children} active={adminMatch}>
        <PermissionCheck action={Actions.View} target={Targets.User}>
          <NavMenu.Item link={AdminRoutes.Users} icon={<UsersIcon />}>
            Users
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck action={Actions.View} target={Targets.Role}>
          <NavMenu.Item link={AdminRoutes.Roles} icon={<RolesIcon />}>
            Roles
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck action={Actions.View} target={Targets.Office}>
          <NavMenu.Item link={AdminRoutes.Offices} icon={<OfficesIcon />}>
            EE Offices
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck
          action={Actions.View}
          target={lookupPermissionTargets}
          any
        >
          <NavMenu.Item link={AdminRoutes.Lookups} icon={<LookupsIcon />}>
            Lookups
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck action={Actions.View} target={Targets.Country}>
          <NavMenu.Item link={AdminRoutes.Countries} icon={<CountriesIcon />}>
            Countries
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck action={Actions.View} target={Targets.Commodity}>
          <NavMenu.Item
            link={AdminRoutes.Commodities}
            icon={<CommoditiesIcon />}
          >
            Commodities
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck action={Actions.View} target={Targets.CertificateType}>
          <NavMenu.Item
            link={AdminRoutes.CertificateTypes}
            icon={<CertificateTypesIcon />}
          >
            Certificate Types
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck action={Actions.View} target={Targets.GlobalRate}>
          <NavMenu.Item
            link={AdminRoutes.GlobalRates}
            icon={<GlobalRatesIcon />}
          >
            Global Rates
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck action={Actions.View} target={Targets.GlobalInvoicing}>
          <NavMenu.Item
            link={AdminRoutes.GlobalInvoicing}
            icon={<GlobalInvoicingIcon />}
          >
            Global Invoicing
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck action={Actions.View} target={Targets.TaxRate}>
          <NavMenu.Item link={AdminRoutes.TaxRates} icon={<TaxRatesIcon />}>
            Tax Rates
          </NavMenu.Item>
        </PermissionCheck>
      </NavMenu>
    </PermissionCheck>
  );
};

export default AdminNavMenu;
