import { useMatch } from "react-router-dom";
import { RoutingUtils } from "@redriver/cinnamon-mui";
import { PermissionCheck } from "components/auth";
import { NavMenu } from "components/navigation";
import { Actions, Targets } from "constants/permissions";
import { AppRoutes } from "constants/routes";
import ResourceIcon from "assets/icons/menu/Resource";
import AgenciesIcon from "assets/icons/menu/Agencies";
import QuickSearchIcon from "assets/icons/menu/QuickSearch";

const ResourcesNavMenu = ({ children }) => {
  const resourcesPath = RoutingUtils.subRoutesPath(AppRoutes.Resources);
  const agenciesPath = RoutingUtils.subRoutesPath(AppRoutes.Agencies);
  const resourceSearchPath = RoutingUtils.subRoutesPath(
    AppRoutes.ResourceSearch
  );

  const resourcesMatch = useMatch(resourcesPath);
  const agenciesMatch = useMatch(agenciesPath);
  const resourceSearchMatch = useMatch(resourceSearchPath);

  return (
    <PermissionCheck
      action={Actions.View}
      target={[Targets.Resource, Targets.Agency, Targets.ResourceQuickSearch]}
      any
    >
      <NavMenu
        title={children}
        link={AppRoutes.Resources}
        active={resourcesMatch || agenciesMatch || resourceSearchMatch}
      >
        <PermissionCheck action={Actions.View} target={Targets.Resource}>
          <NavMenu.Item link={AppRoutes.Resources} icon={<ResourceIcon />}>
            Resources
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck action={Actions.View} target={Targets.Agency}>
          <NavMenu.Item link={AppRoutes.Agencies} icon={<AgenciesIcon />}>
            Agencies
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck
          action={Actions.View}
          target={Targets.ResourceQuickSearch}
        >
          <NavMenu.Item
            link={AppRoutes.ResourceSearch}
            icon={<QuickSearchIcon />}
          >
            Quick Search
          </NavMenu.Item>
        </PermissionCheck>
      </NavMenu>
    </PermissionCheck>
  );
};

export default ResourcesNavMenu;
