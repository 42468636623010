import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 34 34"
  >
    <g id="puzzle" transform="translate(0 0)">
      <path
        id="Path_507"
        data-name="Path 507"
        d="M191.013,15.1l3.3-3.3a.556.556,0,0,0,0-.787l-3.332-3.332a.558.558,0,0,1,.1-.864,2.594,2.594,0,1,0-3.587-3.592.559.559,0,0,1-.865.1L183.465.163a.556.556,0,0,0-.787,0l-3.295,3.295a.558.558,0,0,0,.06.839,2.594,2.594,0,1,1-3.638,3.636.554.554,0,0,0-.834-.063l-3.2,3.2a.556.556,0,0,0,0,.787L182.621,22.7a.556.556,0,0,0,.787,0l3.194-3.194a.558.558,0,0,1,.839.06,2.594,2.594,0,1,0,3.636-3.638A.554.554,0,0,1,191.013,15.1Z"
        transform="translate(-160.475 0)"
        fill="#a4e276"
      />
      <path
        id="Path_508"
        data-name="Path 508"
        d="M4.4,188.812A2.594,2.594,0,0,1,8.147,192.4a2.629,2.629,0,0,1-.249.237.936.936,0,0,0-.077,1.384l2.757,2.757a.942.942,0,0,0,1.332,0l3.022-3.022a.657.657,0,0,1,.972.049,2.594,2.594,0,1,0,3.837-3.482q-.087-.087-.18-.164a.661.661,0,0,1-.053-.978l2.99-2.99a.62.62,0,0,0,0-.877L19.286,182.1a.648.648,0,0,1,.094-.992,2.594,2.594,0,1,0-3.587-3.635.678.678,0,0,1-1.035.1l-3.017-3.017a.62.62,0,0,0-.877,0l-2.895,2.895a.947.947,0,0,0,.016,1.345q.072.072.141.151a2.594,2.594,0,0,1-3.8,3.525l-.007-.007a.947.947,0,0,0-1.346-.015L.276,185.145a.942.942,0,0,0,0,1.332l2.579,2.579a.944.944,0,0,0,1.394-.076q.073-.086.154-.168Z"
        transform="translate(0 -163.056)"
        fill="#ff8c8c"
      />
      <path
        id="Path_509"
        data-name="Path 509"
        d="M271.483,51.593a.556.556,0,0,0,0-.787l-3.331-3.332a.558.558,0,0,1,.1-.864,2.594,2.594,0,0,0,.477-3.948L254.341,57.047l5.451,5.451a.556.556,0,0,0,.787,0l3.194-3.194a.558.558,0,0,1,.839.06,2.593,2.593,0,0,0,3.843.3,2.594,2.594,0,0,0-.207-3.939.554.554,0,0,1-.063-.834Z"
        transform="translate(-237.646 -39.797)"
        fill="#00a14b"
      />
      <path
        id="Path_510"
        data-name="Path 510"
        d="M112.968,231.584a2.633,2.633,0,0,1-.249.237.936.936,0,0,0-.077,1.384l2.757,2.757a.942.942,0,0,0,1.332,0l3.022-3.022a.657.657,0,0,1,.972.049,2.594,2.594,0,1,0,3.837-3.482q-.087-.087-.18-.164a.661.661,0,0,1-.053-.978l2.99-2.99a.62.62,0,0,0,0-.877l-3.212-3.212a.648.648,0,0,1,.094-.992,2.6,2.6,0,0,0,.49-3.839c-.022-.025-.045-.049-.068-.074L112.95,228.055A2.607,2.607,0,0,1,112.968,231.584Z"
        transform="translate(-104.977 -202.239)"
        fill="#cc0808"
      />
    </g>
  </svg>,
  "Resource"
);
