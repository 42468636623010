import { Form } from "@redriver/cinnamon-mui";
import { Fragment } from "react";
import { ClientOfficeTypeAhead, ContactStatusDropdown } from "./fields";
import ContactPointsForm from "./ContactPointsForm";

const ClientContactForm = ({ clientId, disableClientOffice }) => (
  <Fragment>
    <Form.Group columns={2}>
      <Form.Input label="First Name" field="firstName" required fluid />
      <Form.Input label="Last Name" field="lastName" required fluid />
    </Form.Group>
    <Form.Group columns={2}>
      <Form.Input label="Job Title" field="jobTitle" fluid />
      <ClientOfficeTypeAhead
        label="Office"
        field="clientOffice"
        valueField="officeId"
        clientId={clientId}
        disabled={disableClientOffice}
        required
        fluid
      />
    </Form.Group>
    <Form.Group columns={2}>
      <ContactStatusDropdown
        label="Status"
        field="contactStatus"
        valueField="status"
        required
      />
    </Form.Group>
    <ContactPointsForm />
    <Form.TextArea label="Comments" field="comments" rows={4} fluid />
  </Fragment>
);

export default ClientContactForm;
