import classNames from "classnames";
import { Box, styled } from "@mui/material";

const Swimlane = ({ className, header, children, ...props }) => (
  <Box {...props} className={classNames("swimlane", className)}>
    <header>{header}</header>
    <section>{children}</section>
  </Box>
);

export default styled(Swimlane)(({ theme, minWidth }) => ({
  minWidth,
  backgroundColor: theme.palette.background.default,
  "> header": {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1),
  },
  "> section": {
    padding: theme.spacing(1.5, 2),
  },
}));
