import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Typography, Link } from "@mui/material";
import { ErrorMessage, useApiAction } from "@redriver/cinnamon-mui";
import { SystemRoutes } from "constants/routes";
import { requestLogin } from "./actions";

const AzureAdSignIn = ({ code }) => {
  const [{ success, error, busy }] = useApiAction(requestLogin, {
    lazy: false,
    actionParams: { code },
  });

  // If login successful then user will be redirected momentarily
  // so can use same message as busy
  if (busy || success) {
    return <Typography>Please wait</Typography>;
  }

  return (
    <React.Fragment>
      <ErrorMessage error={error ?? "Unknown Error"} />
      <Typography mt={2}>
        Return to{" "}
        <Link component={RouterLink} to={SystemRoutes.Login}>
          Log In
        </Link>
      </Typography>
    </React.Fragment>
  );
};

export default AzureAdSignIn;
