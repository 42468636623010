import { apiPatch } from "@redriver/cinnamon-mui";
import * as mortar from "mortar/endpoints/auth";

const NAMESPACE = "SYSTEM/PASSWORD_RESET";
export const REQUEST_RESET = `${NAMESPACE}/REQUEST`;

export const validateResetToken = (token: string) =>
  mortar.checkResetTokenUsed({
    routeParams: {
      token,
    },
    options: { auth: false, preventErrorNotification: true },
  });

export const requestPasswordReset = (
  { newPassword }: { newPassword: string },
  token: string
) =>
  apiPatch(
    REQUEST_RESET,
    `auth/reset-tokens/${token}`,
    { isUsed: true, newPassword },
    { auth: false, preventErrorNotification: true }
  );
