import { useState, useRef } from "react";
import { Menu, styled } from "@mui/material";
import NavButton from "./NavButton";
import NavMenuItem from "./NavMenuItem";

const NavMenuTrigger = styled("div", {
  shouldForwardProp: (prop) =>
    prop !== "open" && prop !== "direction" && prop !== "sx",
})(({ theme, open, direction }) => ({
  alignSelf: "stretch",
  padding: "0.2em 1.2em",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  transition: "background-color linear 150ms",
  backgroundColor: open ? theme.palette.menu.hover : undefined,
  "&:hover": {
    backgroundColor: theme.palette.menu.hover,
  },
  "> .MuiButton-root": {
    margin: "-0.2em -1.2em",
  },
  "> .MuiPopover-root": {
    cursor: "default",
    pointerEvents: "none",
    "> .MuiPaper-root": {
      pointerEvents: "auto",
      borderRadius: 20,
      padding: "0.25em 1em",
      marginTop: "10px",
      overflow: "visible",
      "&::before": {
        backgroundColor: theme.palette.background.paper,
        content: '""',
        display: "block",
        position: "absolute",
        width: 16,
        height: 16,
        top: -6,
        transform: "rotate(45deg)",
        left: direction === "left" ? undefined : 44,
        right: direction === "left" ? 44 : undefined,
        zIndex: 1,
      },
    },
  },
}));

const NavMenu = ({
  title,
  active,
  link,
  trigger,
  className,
  sx,
  direction = "right",
  children,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const mouseOver = useRef(false);
  const open = !!anchorEl;

  return (
    <NavMenuTrigger
      open={open}
      direction={direction}
      onClick={(e) => {
        setAnchorEl(e?.currentTarget);
      }}
      onMouseOver={(e) => {
        mouseOver.current = true;
        setAnchorEl(e?.currentTarget);
      }}
      onMouseLeave={() => {
        mouseOver.current = false;
        setTimeout(() => {
          if (!mouseOver.current) {
            setAnchorEl(null);
          }
        }, 50);
      }}
      className={className}
      sx={sx}
    >
      {trigger ?? (
        <NavButton active={active} link={link} disableRipple>
          {title}
        </NavButton>
      )}
      <Menu
        keepMounted
        disablePortal
        hideBackdrop
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(null);
        }}
        MenuListProps={{
          onMouseEnter: () => {
            mouseOver.current = true;
          },
          onMouseLeave: () => {
            mouseOver.current = false;
            setAnchorEl(null);
          },
        }}
        transformOrigin={{
          horizontal: direction === "left" ? "right" : "left",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: direction === "left" ? "right" : "left",
          vertical: "bottom",
        }}
      >
        {children}
      </Menu>
    </NavMenuTrigger>
  );
};

NavMenu.Item = NavMenuItem;

export default NavMenu;
