import { Form } from "@redriver/cinnamon-mui";

// temporary component until file type validation is supported on Cinnamon FormFile

const FileTypeError = ({
  field,
  notifiedFields = [],
  mimeTypes = [],
  error,
}) => (
  <Form.CustomErrors
    field={`${field}_type`}
    triggerFields={[field, ...notifiedFields]}
    validator={({ fields }) => {
      const value = fields[field];
      const files = value ? (Array.isArray(value) ? value : [value]) : [];

      if (
        mimeTypes.length > 0 &&
        files.some(
          (f) =>
            !mimeTypes.some((t) => t.toLowerCase() === f.type?.toLowerCase())
        )
      ) {
        return error
          ? [error]
          : [
              `Uploaded files must be one of the following types: ${mimeTypes.join(
                ", "
              )}`,
            ];
      }
      return [];
    }}
  />
);

export default FileTypeError;
