import classNames from "classnames";
import { Grid, Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import Logo from "assets/logos/ee-logo.png";
import Splash from "assets/backgrounds/system-splash.jpg";

const SystemPageContent = ({ title, className, children }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Grid
      container
      className={classNames("system-page-content", className)}
      sx={{
        minHeight: "100vh",
        backgroundColor: "background.white",
      }}
    >
      <Grid
        item
        xs={12}
        md={8}
        lg={6}
        xl={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "7rem",
        }}
      >
        <Box
          sx={{
            width: "30rem",
            "> .logo": {
              display: "block",
              mx: "auto",
              mb: "2.5rem",
              height: "91px",
            },
          }}
        >
          <img src={Logo} alt="Energy Engineering" className="logo" />
          <Typography variant="title" marginBottom={3}>
            Welcome
          </Typography>
          {title && (
            <Typography variant="h5" marginBottom={3}>
              {title}
            </Typography>
          )}
          {children}
        </Box>
      </Grid>
      <Grid
        item
        xs={0}
        md={4}
        lg={6}
        xl={7}
        sx={{
          position: "relative",
          "> .splash": {
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            backgroundImage: `url(${Splash})`,
            backgroundSize: "auto 100%",
            backgroundRepeat: "no-repeat",
            backgroundPositionX: isExtraLargeScreen
              ? "right"
              : isLargeScreen
              ? "90%"
              : "75%",
          },
        }}
      >
        <div className="splash" />
      </Grid>
    </Grid>
  );
};

export default SystemPageContent;
