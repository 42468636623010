import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 21.213 26.388"
  >
    <g id="visit" transform="translate(-7 -2)">
      <g id="Research" transform="translate(7 2)">
        <path
          id="Path_5941"
          data-name="Path 5941"
          d="M22.524,8.9A2.878,2.878,0,0,1,19.65,6.025V2H9.875A2.875,2.875,0,0,0,7,4.875V24.424A2.875,2.875,0,0,0,9.875,27.3h11.5a5.75,5.75,0,1,1,5.175-8.243V8.9Zm-11.5-1.725H17.35a.575.575,0,1,1,0,1.15H11.025a.575.575,0,1,1,0-1.15Zm2.875,16.1H11.025a.575.575,0,1,1,0-1.15H13.9a.575.575,0,0,1,0,1.15Zm-3.45-3.45a.575.575,0,0,1,.575-.575h2.3a.575.575,0,0,1,0,1.15h-2.3A.575.575,0,0,1,10.45,19.824Zm4.025-2.3h-3.45a.575.575,0,1,1,0-1.15h3.45a.575.575,0,1,1,0,1.15Zm8.05-2.875h-11.5a.575.575,0,0,1,0-1.15h11.5a.575.575,0,0,1,0,1.15Zm0-2.875H12.75a.575.575,0,0,1,0-1.15h9.775a.575.575,0,0,1,0,1.15Z"
          transform="translate(-7 -2)"
          fill="#bde0f1"
        />
        <path
          id="Path_5942"
          data-name="Path 5942"
          d="M36.413,7.492,31.337,2.416A1.143,1.143,0,0,0,31,2.186V6.1a1.725,1.725,0,0,0,1.725,1.725h3.918a1.143,1.143,0,0,0-.23-.337Z"
          transform="translate(-17.2 -2.079)"
          fill="#207d9a"
        />
        <path
          id="Path_5943"
          data-name="Path 5943"
          d="M35.086,37.464l-2.544-2.548a4.611,4.611,0,1,0-1.626,1.626l2.548,2.544a1.147,1.147,0,0,0,1.621-1.621Zm-9.378-4.882a2.875,2.875,0,1,1,2.875,2.875,2.875,2.875,0,0,1-2.875-2.875Z"
          transform="translate(-14.208 -13.034)"
          fill="#1d7894"
        />
      </g>
    </g>
  </svg>,
  "VisitReport"
);
