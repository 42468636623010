// Auto Generated file, do not modify
import {
    apiDelete,
    apiGet,
    apiPost,
    apiPut,
    ApiRequestOptions,
} from "@redriver/cinnamon-mui";
import {
    CreateBillingAreaRequest,
    UpdateBillingAreaRequest,
} from "mortar/EnergyEngineering/Endpoints/Lookups/BillingAreas";
import {
    CreateClientTypeRequest,
    UpdateClientTypeRequest,
} from "mortar/EnergyEngineering/Endpoints/Lookups/ClientTypes";
import {
    CreateDisciplineRequest,
    UpdateDisciplineRequest,
} from "mortar/EnergyEngineering/Endpoints/Lookups/Disciplines";
import {
    CreateDisciplineTypeRequest,
    UpdateDisciplineTypeRequest,
} from "mortar/EnergyEngineering/Endpoints/Lookups/DisciplineTypes";
import {
    CreateEndUserRequest,
    UpdateEndUserRequest,
} from "mortar/EnergyEngineering/Endpoints/Lookups/EndUsers";
import {
    CreateEpcContractorRequest,
    UpdateEpcContractorRequest,
} from "mortar/EnergyEngineering/Endpoints/Lookups/EpcContractors";
import {
    CreateExpenseTypeRequest,
    UpdateExpenseTypeRequest,
} from "mortar/EnergyEngineering/Endpoints/Lookups/ExpenseTypes";
import {
    CreateIndustryRequest,
    UpdateIndustryRequest,
} from "mortar/EnergyEngineering/Endpoints/Lookups/Industries";
import {
    CreateNonComplianceReportTypeRequest,
    UpdateNonComplianceReportTypeRequest,
} from "mortar/EnergyEngineering/Endpoints/Lookups/NonComplianceReportType";
import {
    CreateProjectTypeRequest,
    UpdateProjectTypeRequest,
} from "mortar/EnergyEngineering/Endpoints/Lookups/ProjectTypes";
import {
    CreateResourceRoleRequest,
    UpdateResourceRoleRequest,
} from "mortar/EnergyEngineering/Endpoints/Lookups/ResourceRoles";
import {
    CreateScopeRejectionReasonRequest,
    UpdateScopeRejectionReasonRequest,
} from "mortar/EnergyEngineering/Endpoints/Lookups/ScopeRejectionReasons";
import {
    ContractStatus,
    DocumentViewType,
    EnumDescriptionType,
    OfficeType,
} from "mortar/EnergyEngineering/Enums";
import {
    BillingAreaLookupView,
    CertificateTypeLookupView,
    ClientContactLookupView,
    ClientOfficeAddressLookupView,
    CountryLookupView,
    CurrencyLookupView,
    DisciplineLookupView,
    DisciplineTypeLookupView,
    IndustryLookupView,
    JobResourceLookupView,
    JobScopeLookupView,
    ProjectLookupView,
    RateLookupView,
    RegionOfficeView,
    ResourceLookupView,
    ResourceRateSetLookupView,
    ResourceRoleLookupView,
    WorkOrderLookupView,
} from "mortar/EnergyEngineering/Logic/Lookups/Views";
import {
    AgencyLookupView,
    LookupView,
} from "mortar/EnergyEngineering/Logic/Views";
import { makeAction, makeFormData } from "../lib";

export interface AgreementTypeLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/agreementTypeLookup">
    >;
}

export const agreementTypeLookup = makeAction((
    { options }: AgreementTypeLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/agreementTypeLookup">(
    "lookups/agreementTypeLookup",
    `agreement-types`,
    undefined,
    options,
), "lookups/agreementTypeLookup");

export interface ActionLinkTypesLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/actionLinkTypesLookup">
    >;
}

export const actionLinkTypesLookup = makeAction((
    { options }: ActionLinkTypesLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/actionLinkTypesLookup">(
    "lookups/actionLinkTypesLookup",
    `lookups/action-link-types`,
    undefined,
    options,
), "lookups/actionLinkTypesLookup");

export interface ActionPrioritiesLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/actionPrioritiesLookup">
    >;
}

export const actionPrioritiesLookup = makeAction((
    { options }: ActionPrioritiesLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/actionPrioritiesLookup">(
    "lookups/actionPrioritiesLookup",
    `lookups/action-priorities`,
    undefined,
    options,
), "lookups/actionPrioritiesLookup");

export interface ActionStatusLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/actionStatusLookup">
    >;
}

export const actionStatusLookup = makeAction((
    { options }: ActionStatusLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/actionStatusLookup">(
    "lookups/actionStatusLookup",
    `lookups/action-status`,
    undefined,
    options,
), "lookups/actionStatusLookup");

export interface AgenciesLookupActionRequest {
    queryParams: {
        maxResults: number;
        search: string;
    };
    options?: Partial<
        ApiRequestOptions<AgencyLookupView[], "lookups/agenciesLookup">
    >;
}

export const agenciesLookup = makeAction((
    { queryParams, options }: AgenciesLookupActionRequest,
) => apiGet<AgencyLookupView[], "lookups/agenciesLookup">(
    "lookups/agenciesLookup",
    `lookups/agencies`,
    queryParams,
    options,
), "lookups/agenciesLookup");

export interface AgencyContactsLookupActionRequest {
    queryParams: {
        maxResults: number;
        search: string;
        agencyId: string;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/agencyContactsLookup">
    >;
}

export const agencyContactsLookup = makeAction((
    { queryParams, options }: AgencyContactsLookupActionRequest,
) => apiGet<LookupView[], "lookups/agencyContactsLookup">(
    "lookups/agencyContactsLookup",
    `lookups/agency-contacts`,
    queryParams,
    options,
), "lookups/agencyContactsLookup");

export interface ApprovalStatusLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/approvalStatusLookup">
    >;
}

export const approvalStatusLookup = makeAction((
    { options }: ApprovalStatusLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/approvalStatusLookup">(
    "lookups/approvalStatusLookup",
    `lookups/approval-status`,
    undefined,
    options,
), "lookups/approvalStatusLookup");

export interface BankAccountsLookupActionRequest {
    queryParams: {
        maxResults: number;
        search: string;
        officeId: string;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/bankAccountsLookup">
    >;
}

export const bankAccountsLookup = makeAction((
    { queryParams, options }: BankAccountsLookupActionRequest,
) => apiGet<LookupView[], "lookups/bankAccountsLookup">(
    "lookups/bankAccountsLookup",
    `lookups/bank-accounts`,
    queryParams,
    options,
), "lookups/bankAccountsLookup");

export interface BankPaymentTermsLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/bankPaymentTermsLookup">
    >;
}

export const bankPaymentTermsLookup = makeAction((
    { options }: BankPaymentTermsLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/bankPaymentTermsLookup">(
    "lookups/bankPaymentTermsLookup",
    `lookups/bank-payment-terms`,
    undefined,
    options,
), "lookups/bankPaymentTermsLookup");

export interface BillingAreasLookupActionRequest {
    queryParams: {
        maxResults: number;
        search: string;
    };
    options?: Partial<
        ApiRequestOptions<BillingAreaLookupView[], "lookups/billingAreasLookup">
    >;
}

export const billingAreasLookup = makeAction((
    { queryParams, options }: BillingAreasLookupActionRequest,
) => apiGet<BillingAreaLookupView[], "lookups/billingAreasLookup">(
    "lookups/billingAreasLookup",
    `lookups/billing-areas`,
    queryParams,
    options,
), "lookups/billingAreasLookup");

export interface CreateBillingAreaActionRequest {
    request: CreateBillingAreaRequest;
    options?: Partial<ApiRequestOptions<void, "lookups/createBillingArea">>;
}

export const createBillingArea = makeAction((
    { request, options }: CreateBillingAreaActionRequest,
) => apiPost<void, "lookups/createBillingArea">(
    "lookups/createBillingArea",
    `lookups/billing-areas`,
    request,
    options,
), "lookups/createBillingArea");

export interface UpdateBillingAreaActionRequest {
    routeParams: {
        billingAreaId: string;
    };
    request: UpdateBillingAreaRequest;
    options?: Partial<ApiRequestOptions<void, "lookups/updateBillingArea">>;
}

export const updateBillingArea = makeAction((
    { routeParams, request, options }: UpdateBillingAreaActionRequest,
) => apiPut<void, "lookups/updateBillingArea">(
    "lookups/updateBillingArea",
    `lookups/billing-areas/${routeParams.billingAreaId}`,
    request,
    options,
), "lookups/updateBillingArea");

export interface DeleteBillingAreaActionRequest {
    routeParams: {
        billingAreaId: string;
    };
    options?: Partial<ApiRequestOptions<void, "lookups/deleteBillingArea">>;
}

export const deleteBillingArea = makeAction((
    { routeParams, options }: DeleteBillingAreaActionRequest,
) => apiDelete<void, "lookups/deleteBillingArea">(
    "lookups/deleteBillingArea",
    `lookups/billing-areas/${routeParams.billingAreaId}`,
    undefined,
    options,
), "lookups/deleteBillingArea");

export interface BillingFrequenciesLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/billingFrequenciesLookup">
    >;
}

export const billingFrequenciesLookup = makeAction((
    { options }: BillingFrequenciesLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/billingFrequenciesLookup">(
    "lookups/billingFrequenciesLookup",
    `lookups/billing-frequencies`,
    undefined,
    options,
), "lookups/billingFrequenciesLookup");

export interface CertificateTypesLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<
            CertificateTypeLookupView[],
            "lookups/certificateTypesLookup"
        >
    >;
}

export const certificateTypesLookup = makeAction((
    { options }: CertificateTypesLookupActionRequest = {},
) => apiGet<CertificateTypeLookupView[], "lookups/certificateTypesLookup">(
    "lookups/certificateTypesLookup",
    `lookups/certificate-types`,
    undefined,
    options,
), "lookups/certificateTypesLookup");

export interface ClientContactsLookupActionRequest {
    queryParams: {
        clientId: string;
        clientOfficeIds: string[];
        clientContactId: string;
        maxResults: number;
        search: string;
        jobId: string;
        projectId: string;
    };
    options?: Partial<
        ApiRequestOptions<
            ClientContactLookupView[],
            "lookups/clientContactsLookup"
        >
    >;
}

export const clientContactsLookup = makeAction((
    { queryParams, options }: ClientContactsLookupActionRequest,
) => apiGet<ClientContactLookupView[], "lookups/clientContactsLookup">(
    "lookups/clientContactsLookup",
    `lookups/client-contacts`,
    queryParams,
    options,
), "lookups/clientContactsLookup");

export interface ClientOfficeAddressesLookupActionRequest {
    queryParams: {
        maxResults: number;
        search: string;
    };
    options?: Partial<
        ApiRequestOptions<
            ClientOfficeAddressLookupView[],
            "lookups/clientOfficeAddressesLookup"
        >
    >;
}

export const clientOfficeAddressesLookup = makeAction((
    { queryParams, options }: ClientOfficeAddressesLookupActionRequest,
) => apiGet<
    ClientOfficeAddressLookupView[],
    "lookups/clientOfficeAddressesLookup"
>(
    "lookups/clientOfficeAddressesLookup",
    `lookups/client-office-addresses`,
    queryParams,
    options,
), "lookups/clientOfficeAddressesLookup");

export interface ClientOfficesLookupActionRequest {
    queryParams: {
        clientId: string;
        clientOfficeId: string;
        maxResults: number;
        search: string;
        type: OfficeType;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/clientOfficesLookup">
    >;
}

export const clientOfficesLookup = makeAction((
    { queryParams, options }: ClientOfficesLookupActionRequest,
) => apiGet<LookupView[], "lookups/clientOfficesLookup">(
    "lookups/clientOfficesLookup",
    `lookups/client-offices`,
    queryParams,
    options,
), "lookups/clientOfficesLookup");

export interface ClientStatusLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/clientStatusLookup">
    >;
}

export const clientStatusLookup = makeAction((
    { options }: ClientStatusLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/clientStatusLookup">(
    "lookups/clientStatusLookup",
    `lookups/client-status`,
    undefined,
    options,
), "lookups/clientStatusLookup");

export interface ClientTypesLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/clientTypesLookup">
    >;
}

export const clientTypesLookup = makeAction((
    { options }: ClientTypesLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/clientTypesLookup">(
    "lookups/clientTypesLookup",
    `lookups/client-types`,
    undefined,
    options,
), "lookups/clientTypesLookup");

export interface CreateClientTypeActionRequest {
    request: CreateClientTypeRequest;
    options?: Partial<ApiRequestOptions<void, "lookups/createClientType">>;
}

export const createClientType = makeAction((
    { request, options }: CreateClientTypeActionRequest,
) => apiPost<void, "lookups/createClientType">(
    "lookups/createClientType",
    `lookups/client-types`,
    request,
    options,
), "lookups/createClientType");

export interface UpdateClientTypeActionRequest {
    routeParams: {
        clientTypeId: string;
    };
    request: UpdateClientTypeRequest;
    options?: Partial<ApiRequestOptions<void, "lookups/updateClientType">>;
}

export const updateClientType = makeAction((
    { routeParams, request, options }: UpdateClientTypeActionRequest,
) => apiPut<void, "lookups/updateClientType">(
    "lookups/updateClientType",
    `lookups/client-types/${routeParams.clientTypeId}`,
    request,
    options,
), "lookups/updateClientType");

export interface DeleteClientTypeActionRequest {
    routeParams: {
        clientTypeId: string;
    };
    options?: Partial<ApiRequestOptions<void, "lookups/deleteClientType">>;
}

export const deleteClientType = makeAction((
    { routeParams, options }: DeleteClientTypeActionRequest,
) => apiDelete<void, "lookups/deleteClientType">(
    "lookups/deleteClientType",
    `lookups/client-types/${routeParams.clientTypeId}`,
    undefined,
    options,
), "lookups/deleteClientType");

export interface ClientsLookupActionRequest {
    queryParams: {
        clientId: string;
        maxResults: number;
        search: string;
    };
    options?: Partial<ApiRequestOptions<LookupView[], "lookups/clientsLookup">>;
}

export const clientsLookup = makeAction((
    { queryParams, options }: ClientsLookupActionRequest,
) => apiGet<LookupView[], "lookups/clientsLookup">(
    "lookups/clientsLookup",
    `lookups/clients`,
    queryParams,
    options,
), "lookups/clientsLookup");

export interface ContactMethodLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/contactMethodLookup">
    >;
}

export const contactMethodLookup = makeAction((
    { options }: ContactMethodLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/contactMethodLookup">(
    "lookups/contactMethodLookup",
    `lookups/contact-method`,
    undefined,
    options,
), "lookups/contactMethodLookup");

export interface ContractApproverUsersLookupActionRequest {
    queryParams: {
        maxResults: number;
        search: string;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/contractApproverUsersLookup">
    >;
}

export const contractApproverUsersLookup = makeAction((
    { queryParams, options }: ContractApproverUsersLookupActionRequest,
) => apiGet<LookupView[], "lookups/contractApproverUsersLookup">(
    "lookups/contractApproverUsersLookup",
    `lookups/contract-approver-users`,
    queryParams,
    options,
), "lookups/contractApproverUsersLookup");

export interface ContractInvoicingBillingAssignmentOfficesLookupActionRequest {
    queryParams: {
        contractId: string;
        selectedOfficeIds: string[];
        maxResults: number;
        search: string;
    };
    options?: Partial<
        ApiRequestOptions<
            LookupView[],
            "lookups/contractInvoicingBillingAssignmentOfficesLookup"
        >
    >;
}

export const contractInvoicingBillingAssignmentOfficesLookup = makeAction((
    { queryParams, options }:
        ContractInvoicingBillingAssignmentOfficesLookupActionRequest,
) => apiGet<
    LookupView[],
    "lookups/contractInvoicingBillingAssignmentOfficesLookup"
>(
    "lookups/contractInvoicingBillingAssignmentOfficesLookup",
    `lookups/contract-invoicing-billing-assignment-office`,
    queryParams,
    options,
), "lookups/contractInvoicingBillingAssignmentOfficesLookup");

export interface ContractStatusLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/contractStatusLookup">
    >;
}

export const contractStatusLookup = makeAction((
    { options }: ContractStatusLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/contractStatusLookup">(
    "lookups/contractStatusLookup",
    `lookups/contract-status`,
    undefined,
    options,
), "lookups/contractStatusLookup");

export interface ContractsLookupActionRequest {
    queryParams: {
        clientId: string;
        maxResults: number;
        search: string;
        includeExpired: boolean;
        contractId: string;
        status: ContractStatus[];
        enquiryId: string;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/contractsLookup">
    >;
}

export const contractsLookup = makeAction((
    { queryParams, options }: ContractsLookupActionRequest,
) => apiGet<LookupView[], "lookups/contractsLookup">(
    "lookups/contractsLookup",
    `lookups/contracts`,
    queryParams,
    options,
), "lookups/contractsLookup");

export interface CoordinatorUsersLookupActionRequest {
    queryParams: {
        maxResults: number;
        search: string;
        officeId: string;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/coordinatorUsersLookup">
    >;
}

export const coordinatorUsersLookup = makeAction((
    { queryParams, options }: CoordinatorUsersLookupActionRequest,
) => apiGet<LookupView[], "lookups/coordinatorUsersLookup">(
    "lookups/coordinatorUsersLookup",
    `lookups/coordinator-users`,
    queryParams,
    options,
), "lookups/coordinatorUsersLookup");

export interface CountriesLookupActionRequest {
    queryParams: {
        search: string;
    };
    options?: Partial<
        ApiRequestOptions<CountryLookupView[], "lookups/countriesLookup">
    >;
}

export const countriesLookup = makeAction((
    { queryParams, options }: CountriesLookupActionRequest,
) => apiGet<CountryLookupView[], "lookups/countriesLookup">(
    "lookups/countriesLookup",
    `lookups/countries`,
    queryParams,
    options,
), "lookups/countriesLookup");

export interface CurrenciesLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<CurrencyLookupView[], "lookups/currenciesLookup">
    >;
}

export const currenciesLookup = makeAction((
    { options }: CurrenciesLookupActionRequest = {},
) => apiGet<CurrencyLookupView[], "lookups/currenciesLookup">(
    "lookups/currenciesLookup",
    `lookups/currencies`,
    undefined,
    options,
), "lookups/currenciesLookup");

export interface DaysOfWeekLookupActionRequest {
    queryParams: {
        descriptionType: EnumDescriptionType;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/daysOfWeekLookup">
    >;
}

export const daysOfWeekLookup = makeAction((
    { queryParams, options }: DaysOfWeekLookupActionRequest,
) => apiGet<LookupView[], "lookups/daysOfWeekLookup">(
    "lookups/daysOfWeekLookup",
    `lookups/days-of-week`,
    queryParams,
    options,
), "lookups/daysOfWeekLookup");

export interface DisciplineTypesLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<
            DisciplineTypeLookupView[],
            "lookups/disciplineTypesLookup"
        >
    >;
}

export const disciplineTypesLookup = makeAction((
    { options }: DisciplineTypesLookupActionRequest = {},
) => apiGet<DisciplineTypeLookupView[], "lookups/disciplineTypesLookup">(
    "lookups/disciplineTypesLookup",
    `lookups/discipline-types`,
    undefined,
    options,
), "lookups/disciplineTypesLookup");

export interface CreateDisciplineTypeActionRequest {
    request: CreateDisciplineTypeRequest;
    options?: Partial<ApiRequestOptions<void, "lookups/createDisciplineType">>;
}

export const createDisciplineType = makeAction((
    { request, options }: CreateDisciplineTypeActionRequest,
) => apiPost<void, "lookups/createDisciplineType">(
    "lookups/createDisciplineType",
    `lookups/discipline-types`,
    request,
    options,
), "lookups/createDisciplineType");

export interface UpdateDisciplineTypeActionRequest {
    routeParams: {
        disciplineTypeId: string;
    };
    request: UpdateDisciplineTypeRequest;
    options?: Partial<ApiRequestOptions<void, "lookups/updateDisciplineType">>;
}

export const updateDisciplineType = makeAction((
    { routeParams, request, options }: UpdateDisciplineTypeActionRequest,
) => apiPut<void, "lookups/updateDisciplineType">(
    "lookups/updateDisciplineType",
    `lookups/discipline-types/${routeParams.disciplineTypeId}`,
    request,
    options,
), "lookups/updateDisciplineType");

export interface DeleteDisciplineTypeActionRequest {
    routeParams: {
        disciplineTypeId: string;
    };
    options?: Partial<ApiRequestOptions<void, "lookups/deleteDisciplineType">>;
}

export const deleteDisciplineType = makeAction((
    { routeParams, options }: DeleteDisciplineTypeActionRequest,
) => apiDelete<void, "lookups/deleteDisciplineType">(
    "lookups/deleteDisciplineType",
    `lookups/discipline-types/${routeParams.disciplineTypeId}`,
    undefined,
    options,
), "lookups/deleteDisciplineType");

export interface DisciplinesLookupActionRequest {
    queryParams: {
        contractId: string;
        jobId: string;
        withValidRates: boolean;
    };
    options?: Partial<
        ApiRequestOptions<DisciplineLookupView[], "lookups/disciplinesLookup">
    >;
}

export const disciplinesLookup = makeAction((
    { queryParams, options }: DisciplinesLookupActionRequest,
) => apiGet<DisciplineLookupView[], "lookups/disciplinesLookup">(
    "lookups/disciplinesLookup",
    `lookups/disciplines`,
    queryParams,
    options,
), "lookups/disciplinesLookup");

export interface CreateDisciplineActionRequest {
    request: CreateDisciplineRequest;
    options?: Partial<ApiRequestOptions<void, "lookups/createDiscipline">>;
}

export const createDiscipline = makeAction((
    { request, options }: CreateDisciplineActionRequest,
) => apiPost<void, "lookups/createDiscipline">(
    "lookups/createDiscipline",
    `lookups/disciplines`,
    request,
    options,
), "lookups/createDiscipline");

export interface UpdateDisciplineActionRequest {
    routeParams: {
        disciplineId: string;
    };
    request: UpdateDisciplineRequest;
    options?: Partial<ApiRequestOptions<void, "lookups/updateDiscipline">>;
}

export const updateDiscipline = makeAction((
    { routeParams, request, options }: UpdateDisciplineActionRequest,
) => apiPut<void, "lookups/updateDiscipline">(
    "lookups/updateDiscipline",
    `lookups/disciplines/${routeParams.disciplineId}`,
    request,
    options,
), "lookups/updateDiscipline");

export interface DeleteDisciplineActionRequest {
    routeParams: {
        disciplineId: string;
    };
    options?: Partial<ApiRequestOptions<void, "lookups/deleteDiscipline">>;
}

export const deleteDiscipline = makeAction((
    { routeParams, options }: DeleteDisciplineActionRequest,
) => apiDelete<void, "lookups/deleteDiscipline">(
    "lookups/deleteDiscipline",
    `lookups/disciplines/${routeParams.disciplineId}`,
    undefined,
    options,
), "lookups/deleteDiscipline");

export interface DistanceUnitsLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/distanceUnitsLookup">
    >;
}

export const distanceUnitsLookup = makeAction((
    { options }: DistanceUnitsLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/distanceUnitsLookup">(
    "lookups/distanceUnitsLookup",
    `lookups/distance-units`,
    undefined,
    options,
), "lookups/distanceUnitsLookup");

export interface DocumentCategoriesLookupActionRequest {
    queryParams: {
        viewType: DocumentViewType;
        categoryIds: number[];
        excludeCategoryIds: number[];
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/documentCategoriesLookup">
    >;
}

export const documentCategoriesLookup = makeAction((
    { queryParams, options }: DocumentCategoriesLookupActionRequest,
) => apiGet<LookupView[], "lookups/documentCategoriesLookup">(
    "lookups/documentCategoriesLookup",
    `lookups/document-categories`,
    queryParams,
    options,
), "lookups/documentCategoriesLookup");

export interface EndUsersLookupActionRequest {
    queryParams: {
        search: string;
        maxResults: number;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/endUsersLookup">
    >;
}

export const endUsersLookup = makeAction((
    { queryParams, options }: EndUsersLookupActionRequest,
) => apiGet<LookupView[], "lookups/endUsersLookup">(
    "lookups/endUsersLookup",
    `lookups/end-users`,
    queryParams,
    options,
), "lookups/endUsersLookup");

export interface CreateEndUserActionRequest {
    request: CreateEndUserRequest;
    options?: Partial<ApiRequestOptions<void, "lookups/createEndUser">>;
}

export const createEndUser = makeAction((
    { request, options }: CreateEndUserActionRequest,
) => apiPost<void, "lookups/createEndUser">(
    "lookups/createEndUser",
    `lookups/end-users`,
    request,
    options,
), "lookups/createEndUser");

export interface UpdateEndUserActionRequest {
    routeParams: {
        endUserId: string;
    };
    request: UpdateEndUserRequest;
    options?: Partial<ApiRequestOptions<void, "lookups/updateEndUser">>;
}

export const updateEndUser = makeAction((
    { routeParams, request, options }: UpdateEndUserActionRequest,
) => apiPut<void, "lookups/updateEndUser">(
    "lookups/updateEndUser",
    `lookups/end-users/${routeParams.endUserId}`,
    request,
    options,
), "lookups/updateEndUser");

export interface DeleteEndUserActionRequest {
    routeParams: {
        endUserId: string;
    };
    options?: Partial<ApiRequestOptions<void, "lookups/deleteEndUser">>;
}

export const deleteEndUser = makeAction((
    { routeParams, options }: DeleteEndUserActionRequest,
) => apiDelete<void, "lookups/deleteEndUser">(
    "lookups/deleteEndUser",
    `lookups/end-users/${routeParams.endUserId}`,
    undefined,
    options,
), "lookups/deleteEndUser");

export interface EnquiriesLookupActionRequest {
    queryParams: {
        maxResults: number;
        search: string;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/enquiriesLookup">
    >;
}

export const enquiriesLookup = makeAction((
    { queryParams, options }: EnquiriesLookupActionRequest,
) => apiGet<LookupView[], "lookups/enquiriesLookup">(
    "lookups/enquiriesLookup",
    `lookups/enquiries`,
    queryParams,
    options,
), "lookups/enquiriesLookup");

export interface EnquiryDeadlinesLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/enquiryDeadlinesLookup">
    >;
}

export const enquiryDeadlinesLookup = makeAction((
    { options }: EnquiryDeadlinesLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/enquiryDeadlinesLookup">(
    "lookups/enquiryDeadlinesLookup",
    `lookups/enquiry-deadlines`,
    undefined,
    options,
), "lookups/enquiryDeadlinesLookup");

export interface EnquiryScopeStatusLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/enquiryScopeStatusLookup">
    >;
}

export const enquiryScopeStatusLookup = makeAction((
    { options }: EnquiryScopeStatusLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/enquiryScopeStatusLookup">(
    "lookups/enquiryScopeStatusLookup",
    `lookups/enquiry-scope-status`,
    undefined,
    options,
), "lookups/enquiryScopeStatusLookup");

export interface EnquiryScopesLookupActionRequest {
    queryParams: {
        maxResults: number;
        search: string;
        enquiryId: string;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/enquiryScopesLookup">
    >;
}

export const enquiryScopesLookup = makeAction((
    { queryParams, options }: EnquiryScopesLookupActionRequest,
) => apiGet<LookupView[], "lookups/enquiryScopesLookup">(
    "lookups/enquiryScopesLookup",
    `lookups/enquiry-scopes`,
    queryParams,
    options,
), "lookups/enquiryScopesLookup");

export interface EnquiryStatusLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/enquiryStatusLookup">
    >;
}

export const enquiryStatusLookup = makeAction((
    { options }: EnquiryStatusLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/enquiryStatusLookup">(
    "lookups/enquiryStatusLookup",
    `lookups/enquiry-status`,
    undefined,
    options,
), "lookups/enquiryStatusLookup");

export interface EnquiryVisibilityLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/enquiryVisibilityLookup">
    >;
}

export const enquiryVisibilityLookup = makeAction((
    { options }: EnquiryVisibilityLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/enquiryVisibilityLookup">(
    "lookups/enquiryVisibilityLookup",
    `lookups/enquiry-visibility`,
    undefined,
    options,
), "lookups/enquiryVisibilityLookup");

export interface EpcContractorsLookupActionRequest {
    queryParams: {
        maxResults: number;
        search: string;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/epcContractorsLookup">
    >;
}

export const epcContractorsLookup = makeAction((
    { queryParams, options }: EpcContractorsLookupActionRequest,
) => apiGet<LookupView[], "lookups/epcContractorsLookup">(
    "lookups/epcContractorsLookup",
    `lookups/epc-contractors`,
    queryParams,
    options,
), "lookups/epcContractorsLookup");

export interface CreateEpcContractorActionRequest {
    request: CreateEpcContractorRequest;
    options?: Partial<ApiRequestOptions<void, "lookups/createEpcContractor">>;
}

export const createEpcContractor = makeAction((
    { request, options }: CreateEpcContractorActionRequest,
) => apiPost<void, "lookups/createEpcContractor">(
    "lookups/createEpcContractor",
    `lookups/epc-contractors`,
    request,
    options,
), "lookups/createEpcContractor");

export interface UpdateEpcContractorActionRequest {
    routeParams: {
        epcContractorId: string;
    };
    request: UpdateEpcContractorRequest;
    options?: Partial<ApiRequestOptions<void, "lookups/updateEpcContractor">>;
}

export const updateEpcContractor = makeAction((
    { routeParams, request, options }: UpdateEpcContractorActionRequest,
) => apiPut<void, "lookups/updateEpcContractor">(
    "lookups/updateEpcContractor",
    `lookups/epc-contractors/${routeParams.epcContractorId}`,
    request,
    options,
), "lookups/updateEpcContractor");

export interface DeleteEpcContractorActionRequest {
    routeParams: {
        epcContractorId: string;
    };
    options?: Partial<ApiRequestOptions<void, "lookups/deleteEpcContractor">>;
}

export const deleteEpcContractor = makeAction((
    { routeParams, options }: DeleteEpcContractorActionRequest,
) => apiDelete<void, "lookups/deleteEpcContractor">(
    "lookups/deleteEpcContractor",
    `lookups/epc-contractors/${routeParams.epcContractorId}`,
    undefined,
    options,
), "lookups/deleteEpcContractor");

export interface ExpenseTypesLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/expenseTypesLookup">
    >;
}

export const expenseTypesLookup = makeAction((
    { options }: ExpenseTypesLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/expenseTypesLookup">(
    "lookups/expenseTypesLookup",
    `lookups/expense-types`,
    undefined,
    options,
), "lookups/expenseTypesLookup");

export interface CreateExpenseTypeActionRequest {
    request: CreateExpenseTypeRequest;
    options?: Partial<ApiRequestOptions<void, "lookups/createExpenseType">>;
}

export const createExpenseType = makeAction((
    { request, options }: CreateExpenseTypeActionRequest,
) => apiPost<void, "lookups/createExpenseType">(
    "lookups/createExpenseType",
    `lookups/expense-types`,
    request,
    options,
), "lookups/createExpenseType");

export interface UpdateExpenseTypeActionRequest {
    routeParams: {
        expenseTypeId: string;
    };
    request: UpdateExpenseTypeRequest;
    options?: Partial<ApiRequestOptions<void, "lookups/updateExpenseType">>;
}

export const updateExpenseType = makeAction((
    { routeParams, request, options }: UpdateExpenseTypeActionRequest,
) => apiPut<void, "lookups/updateExpenseType">(
    "lookups/updateExpenseType",
    `lookups/expense-types/${routeParams.expenseTypeId}`,
    request,
    options,
), "lookups/updateExpenseType");

export interface DeleteExpenseTypeActionRequest {
    routeParams: {
        expenseTypeId: string;
    };
    options?: Partial<ApiRequestOptions<void, "lookups/deleteExpenseType">>;
}

export const deleteExpenseType = makeAction((
    { routeParams, options }: DeleteExpenseTypeActionRequest,
) => apiDelete<void, "lookups/deleteExpenseType">(
    "lookups/deleteExpenseType",
    `lookups/expense-types/${routeParams.expenseTypeId}`,
    undefined,
    options,
), "lookups/deleteExpenseType");

export interface IndustriesLookupActionRequest {
    queryParams: {
        search: string;
    };
    options?: Partial<
        ApiRequestOptions<IndustryLookupView[], "lookups/industriesLookup">
    >;
}

export const industriesLookup = makeAction((
    { queryParams, options }: IndustriesLookupActionRequest,
) => apiGet<IndustryLookupView[], "lookups/industriesLookup">(
    "lookups/industriesLookup",
    `lookups/industries`,
    queryParams,
    options,
), "lookups/industriesLookup");

export interface CreateIndustryActionRequest {
    request: CreateIndustryRequest;
    options?: Partial<ApiRequestOptions<void, "lookups/createIndustry">>;
}

export const createIndustry = makeAction((
    { request, options }: CreateIndustryActionRequest,
) => apiPost<void, "lookups/createIndustry">(
    "lookups/createIndustry",
    `lookups/industries`,
    request,
    options,
), "lookups/createIndustry");

export interface UpdateIndustryActionRequest {
    routeParams: {
        industryId: string;
    };
    request: UpdateIndustryRequest;
    options?: Partial<ApiRequestOptions<void, "lookups/updateIndustry">>;
}

export const updateIndustry = makeAction((
    { routeParams, request, options }: UpdateIndustryActionRequest,
) => apiPut<void, "lookups/updateIndustry">(
    "lookups/updateIndustry",
    `lookups/industries/${routeParams.industryId}`,
    request,
    options,
), "lookups/updateIndustry");

export interface DeleteIndustryActionRequest {
    routeParams: {
        industryId: string;
    };
    options?: Partial<ApiRequestOptions<void, "lookups/deleteIndustry">>;
}

export const deleteIndustry = makeAction((
    { routeParams, options }: DeleteIndustryActionRequest,
) => apiDelete<void, "lookups/deleteIndustry">(
    "lookups/deleteIndustry",
    `lookups/industries/${routeParams.industryId}`,
    undefined,
    options,
), "lookups/deleteIndustry");

export interface InternalUsersLookupActionRequest {
    queryParams: {
        maxResults: number;
        search: string;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/internalUsersLookup">
    >;
}

export const internalUsersLookup = makeAction((
    { queryParams, options }: InternalUsersLookupActionRequest,
) => apiGet<LookupView[], "lookups/internalUsersLookup">(
    "lookups/internalUsersLookup",
    `lookups/internal-users`,
    queryParams,
    options,
), "lookups/internalUsersLookup");

export interface InvoiceGroupingLevelsLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/invoiceGroupingLevelsLookup">
    >;
}

export const invoiceGroupingLevelsLookup = makeAction((
    { options }: InvoiceGroupingLevelsLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/invoiceGroupingLevelsLookup">(
    "lookups/invoiceGroupingLevelsLookup",
    `lookups/invoice-grouping-levels`,
    undefined,
    options,
), "lookups/invoiceGroupingLevelsLookup");

export interface JobAssignmentVisitsLookupActionRequest {
    queryParams: {
        jobReportId: string;
        jobAssignmentId: string;
        maxVisitDate: string;
        maxResults: number;
        documentCategoryId: number;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/jobAssignmentVisitsLookup">
    >;
}

export const jobAssignmentVisitsLookup = makeAction((
    { queryParams, options }: JobAssignmentVisitsLookupActionRequest,
) => apiGet<LookupView[], "lookups/jobAssignmentVisitsLookup">(
    "lookups/jobAssignmentVisitsLookup",
    `lookups/job-assignment-visits`,
    queryParams,
    options,
), "lookups/jobAssignmentVisitsLookup");

export interface JobAssignmentsLookupActionRequest {
    queryParams: {
        search: string;
        jobScopeId: string;
        maxResults: number;
        maxVisitDate: string;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/jobAssignmentsLookup">
    >;
}

export const jobAssignmentsLookup = makeAction((
    { queryParams, options }: JobAssignmentsLookupActionRequest,
) => apiGet<LookupView[], "lookups/jobAssignmentsLookup">(
    "lookups/jobAssignmentsLookup",
    `lookups/job-assignments`,
    queryParams,
    options,
), "lookups/jobAssignmentsLookup");

export interface JobLocationsLookupActionRequest {
    queryParams: {
        jobId: string;
        maxResults: number;
        search: string;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/jobLocationsLookup">
    >;
}

export const jobLocationsLookup = makeAction((
    { queryParams, options }: JobLocationsLookupActionRequest,
) => apiGet<LookupView[], "lookups/jobLocationsLookup">(
    "lookups/jobLocationsLookup",
    `lookups/job-locations`,
    queryParams,
    options,
), "lookups/jobLocationsLookup");

export interface JobReportsLookupActionRequest {
    queryParams: {
        jobId: string;
        jobAssignmentId: string;
        maxResults: number;
        search: string;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/jobReportsLookup">
    >;
}

export const jobReportsLookup = makeAction((
    { queryParams, options }: JobReportsLookupActionRequest,
) => apiGet<LookupView[], "lookups/jobReportsLookup">(
    "lookups/jobReportsLookup",
    `lookups/job-reports`,
    queryParams,
    options,
), "lookups/jobReportsLookup");

export interface JobResourcesLookupActionRequest {
    queryParams: {
        jobId: string;
        jobScopeId: string;
        jobAssignmentId: string;
        maxResults: number;
        search: string;
    };
    options?: Partial<
        ApiRequestOptions<JobResourceLookupView[], "lookups/jobResourcesLookup">
    >;
}

export const jobResourcesLookup = makeAction((
    { queryParams, options }: JobResourcesLookupActionRequest,
) => apiGet<JobResourceLookupView[], "lookups/jobResourcesLookup">(
    "lookups/jobResourcesLookup",
    `lookups/job-resources`,
    queryParams,
    options,
), "lookups/jobResourcesLookup");

export interface JobScopesLookupActionRequest {
    queryParams: {
        jobIds: string[];
        maxResults: number;
        search: string;
        vendorId: string;
        excludeResourceId: number;
        contractId: string;
    };
    options?: Partial<
        ApiRequestOptions<JobScopeLookupView[], "lookups/jobScopesLookup">
    >;
}

export const jobScopesLookup = makeAction((
    { queryParams, options }: JobScopesLookupActionRequest,
) => apiGet<JobScopeLookupView[], "lookups/jobScopesLookup">(
    "lookups/jobScopesLookup",
    `lookups/job-scopes`,
    queryParams,
    options,
), "lookups/jobScopesLookup");

export interface JobStatusLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/jobStatusLookup">
    >;
}

export const jobStatusLookup = makeAction((
    { options }: JobStatusLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/jobStatusLookup">(
    "lookups/jobStatusLookup",
    `lookups/job-status`,
    undefined,
    options,
), "lookups/jobStatusLookup");

export interface JobsLookupActionRequest {
    queryParams: {
        maxResults: number;
        search: string;
        contractId: string;
    };
    options?: Partial<ApiRequestOptions<LookupView[], "lookups/jobsLookup">>;
}

export const jobsLookup = makeAction((
    { queryParams, options }: JobsLookupActionRequest,
) => apiGet<LookupView[], "lookups/jobsLookup">(
    "lookups/jobsLookup",
    `lookups/jobs`,
    queryParams,
    options,
), "lookups/jobsLookup");

export interface NonComplianceReportCategoriesActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/nonComplianceReportCategories">
    >;
}

export const nonComplianceReportCategories = makeAction((
    { options }: NonComplianceReportCategoriesActionRequest = {},
) => apiGet<LookupView[], "lookups/nonComplianceReportCategories">(
    "lookups/nonComplianceReportCategories",
    `lookups/non-compliance-report-categories`,
    undefined,
    options,
), "lookups/nonComplianceReportCategories");

export interface NonComplianceReportIdentificationsActionRequest {
    options?: Partial<
        ApiRequestOptions<
            LookupView[],
            "lookups/nonComplianceReportIdentifications"
        >
    >;
}

export const nonComplianceReportIdentifications = makeAction((
    { options }: NonComplianceReportIdentificationsActionRequest = {},
) => apiGet<LookupView[], "lookups/nonComplianceReportIdentifications">(
    "lookups/nonComplianceReportIdentifications",
    `lookups/non-compliance-report-identifications`,
    undefined,
    options,
), "lookups/nonComplianceReportIdentifications");

export interface NonComplianceReportTypesLookupActionRequest {
    queryParams: {
        search: string;
        maxResults: number;
    };
    options?: Partial<
        ApiRequestOptions<
            LookupView[],
            "lookups/nonComplianceReportTypesLookup"
        >
    >;
}

export const nonComplianceReportTypesLookup = makeAction((
    { queryParams, options }: NonComplianceReportTypesLookupActionRequest,
) => apiGet<LookupView[], "lookups/nonComplianceReportTypesLookup">(
    "lookups/nonComplianceReportTypesLookup",
    `lookups/non-compliance-report-types`,
    queryParams,
    options,
), "lookups/nonComplianceReportTypesLookup");

export interface CreateNonComplianceReportTypeActionRequest {
    request: CreateNonComplianceReportTypeRequest;
    options?: Partial<
        ApiRequestOptions<void, "lookups/createNonComplianceReportType">
    >;
}

export const createNonComplianceReportType = makeAction((
    { request, options }: CreateNonComplianceReportTypeActionRequest,
) => apiPost<void, "lookups/createNonComplianceReportType">(
    "lookups/createNonComplianceReportType",
    `lookups/non-compliance-report-types`,
    request,
    options,
), "lookups/createNonComplianceReportType");

export interface UpdateNonComplianceReportTypeActionRequest {
    routeParams: {
        typeId: string;
    };
    request: UpdateNonComplianceReportTypeRequest;
    options?: Partial<
        ApiRequestOptions<void, "lookups/updateNonComplianceReportType">
    >;
}

export const updateNonComplianceReportType = makeAction((
    { routeParams, request, options }:
        UpdateNonComplianceReportTypeActionRequest,
) => apiPut<void, "lookups/updateNonComplianceReportType">(
    "lookups/updateNonComplianceReportType",
    `lookups/non-compliance-report-types/${routeParams.typeId}`,
    request,
    options,
), "lookups/updateNonComplianceReportType");

export interface DeleteNonComplianceReportTypeActionRequest {
    routeParams: {
        typeId: string;
    };
    options?: Partial<
        ApiRequestOptions<void, "lookups/deleteNonComplianceReportType">
    >;
}

export const deleteNonComplianceReportType = makeAction((
    { routeParams, options }: DeleteNonComplianceReportTypeActionRequest,
) => apiDelete<void, "lookups/deleteNonComplianceReportType">(
    "lookups/deleteNonComplianceReportType",
    `lookups/non-compliance-report-types/${routeParams.typeId}`,
    undefined,
    options,
), "lookups/deleteNonComplianceReportType");

export interface OfficeTypesLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/officeTypesLookup">
    >;
}

export const officeTypesLookup = makeAction((
    { options }: OfficeTypesLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/officeTypesLookup">(
    "lookups/officeTypesLookup",
    `lookups/office-types`,
    undefined,
    options,
), "lookups/officeTypesLookup");

export interface OfficesLookupActionRequest {
    queryParams: {
        search: string;
        userId: string;
        overrideAccess: boolean;
    };
    options?: Partial<ApiRequestOptions<LookupView[], "lookups/officesLookup">>;
}

export const officesLookup = makeAction((
    { queryParams, options }: OfficesLookupActionRequest,
) => apiGet<LookupView[], "lookups/officesLookup">(
    "lookups/officesLookup",
    `lookups/offices`,
    queryParams,
    options,
), "lookups/officesLookup");

export interface ProjectTypesLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/projectTypesLookup">
    >;
}

export const projectTypesLookup = makeAction((
    { options }: ProjectTypesLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/projectTypesLookup">(
    "lookups/projectTypesLookup",
    `lookups/project-types`,
    undefined,
    options,
), "lookups/projectTypesLookup");

export interface CreateProjectTypeActionRequest {
    request: CreateProjectTypeRequest;
    options?: Partial<ApiRequestOptions<void, "lookups/createProjectType">>;
}

export const createProjectType = makeAction((
    { request, options }: CreateProjectTypeActionRequest,
) => apiPost<void, "lookups/createProjectType">(
    "lookups/createProjectType",
    `lookups/project-types`,
    request,
    options,
), "lookups/createProjectType");

export interface UpdateProjectTypeActionRequest {
    routeParams: {
        projectTypeId: string;
    };
    request: UpdateProjectTypeRequest;
    options?: Partial<ApiRequestOptions<void, "lookups/updateProjectType">>;
}

export const updateProjectType = makeAction((
    { routeParams, request, options }: UpdateProjectTypeActionRequest,
) => apiPut<void, "lookups/updateProjectType">(
    "lookups/updateProjectType",
    `lookups/project-types/${routeParams.projectTypeId}`,
    request,
    options,
), "lookups/updateProjectType");

export interface DeleteProjectTypeActionRequest {
    routeParams: {
        projectTypeId: string;
    };
    options?: Partial<ApiRequestOptions<void, "lookups/deleteProjectType">>;
}

export const deleteProjectType = makeAction((
    { routeParams, options }: DeleteProjectTypeActionRequest,
) => apiDelete<void, "lookups/deleteProjectType">(
    "lookups/deleteProjectType",
    `lookups/project-types/${routeParams.projectTypeId}`,
    undefined,
    options,
), "lookups/deleteProjectType");

export interface ProjectsLookupActionRequest {
    queryParams: {
        clientId: string;
        clientOfficeId: string;
        projectId: string;
        maxResults: number;
        search: string;
        contractId: string;
    };
    options?: Partial<
        ApiRequestOptions<ProjectLookupView[], "lookups/projectsLookup">
    >;
}

export const projectsLookup = makeAction((
    { queryParams, options }: ProjectsLookupActionRequest,
) => apiGet<ProjectLookupView[], "lookups/projectsLookup">(
    "lookups/projectsLookup",
    `lookups/projects`,
    queryParams,
    options,
), "lookups/projectsLookup");

export interface QualityUsersLookupActionRequest {
    queryParams: {
        maxResults: number;
        search: string;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/qualityUsersLookup">
    >;
}

export const qualityUsersLookup = makeAction((
    { queryParams, options }: QualityUsersLookupActionRequest,
) => apiGet<LookupView[], "lookups/qualityUsersLookup">(
    "lookups/qualityUsersLookup",
    `lookups/quality-users`,
    queryParams,
    options,
), "lookups/qualityUsersLookup");

export interface RateSetsLookupActionRequest {
    queryParams: {
        contractId: string;
        jobId: string;
        maxResults: number;
        search: string;
        includeExpired: boolean;
        includeExpiredRateSetId: string;
        includeGlobalRates: boolean;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/rateSetsLookup">
    >;
}

export const rateSetsLookup = makeAction((
    { queryParams, options }: RateSetsLookupActionRequest,
) => apiGet<LookupView[], "lookups/rateSetsLookup">(
    "lookups/rateSetsLookup",
    `lookups/rate-sets`,
    queryParams,
    options,
), "lookups/rateSetsLookup");

export interface RatesLookupActionRequest {
    queryParams: {
        contractId: string;
        enquiryScopeId: string;
        maxResults: number;
        search: string;
        includeExpired: boolean;
    };
    options?: Partial<
        ApiRequestOptions<RateLookupView[], "lookups/ratesLookup">
    >;
}

export const ratesLookup = makeAction((
    { queryParams, options }: RatesLookupActionRequest,
) => apiGet<RateLookupView[], "lookups/ratesLookup">(
    "lookups/ratesLookup",
    `lookups/rates`,
    queryParams,
    options,
), "lookups/ratesLookup");

export interface RegionOfficesLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<RegionOfficeView[], "lookups/regionOfficesLookup">
    >;
}

export const regionOfficesLookup = makeAction((
    { options }: RegionOfficesLookupActionRequest = {},
) => apiGet<RegionOfficeView[], "lookups/regionOfficesLookup">(
    "lookups/regionOfficesLookup",
    `lookups/region-offices`,
    undefined,
    options,
), "lookups/regionOfficesLookup");

export interface RegionsLookupActionRequest {
    queryParams: {
        overrideAccess: boolean;
    };
    options?: Partial<ApiRequestOptions<LookupView[], "lookups/regionsLookup">>;
}

export const regionsLookup = makeAction((
    { queryParams, options }: RegionsLookupActionRequest,
) => apiGet<LookupView[], "lookups/regionsLookup">(
    "lookups/regionsLookup",
    `lookups/regions`,
    queryParams,
    options,
), "lookups/regionsLookup");

export interface ReportingScheduleLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/reportingScheduleLookup">
    >;
}

export const reportingScheduleLookup = makeAction((
    { options }: ReportingScheduleLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/reportingScheduleLookup">(
    "lookups/reportingScheduleLookup",
    `lookups/reporting-schedule`,
    undefined,
    options,
), "lookups/reportingScheduleLookup");

export interface RequestedCorrectionsLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/requestedCorrectionsLookup">
    >;
}

export const requestedCorrectionsLookup = makeAction((
    { options }: RequestedCorrectionsLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/requestedCorrectionsLookup">(
    "lookups/requestedCorrectionsLookup",
    `lookups/requested-corrections`,
    undefined,
    options,
), "lookups/requestedCorrectionsLookup");

export interface ResourceBackupTypesLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/resourceBackupTypesLookup">
    >;
}

export const resourceBackupTypesLookup = makeAction((
    { options }: ResourceBackupTypesLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/resourceBackupTypesLookup">(
    "lookups/resourceBackupTypesLookup",
    `lookups/resource-back-up-types`,
    undefined,
    options,
), "lookups/resourceBackupTypesLookup");

export interface ResourceDivisionLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/resourceDivisionLookup">
    >;
}

export const resourceDivisionLookup = makeAction((
    { options }: ResourceDivisionLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/resourceDivisionLookup">(
    "lookups/resourceDivisionLookup",
    `lookups/resource-division`,
    undefined,
    options,
), "lookups/resourceDivisionLookup");

export interface ResourceRateSetsLookupActionRequest {
    queryParams: {
        resourceId: number;
        maxResults: number;
        search: string;
    };
    options?: Partial<
        ApiRequestOptions<
            ResourceRateSetLookupView[],
            "lookups/resourceRateSetsLookup"
        >
    >;
}

export const resourceRateSetsLookup = makeAction((
    { queryParams, options }: ResourceRateSetsLookupActionRequest,
) => apiGet<ResourceRateSetLookupView[], "lookups/resourceRateSetsLookup">(
    "lookups/resourceRateSetsLookup",
    `lookups/resource-rate-sets`,
    queryParams,
    options,
), "lookups/resourceRateSetsLookup");

export interface ResourceRolesLookupActionRequest {
    queryParams: {
        contractId: string;
        jobId: string;
        withValidRates: boolean;
    };
    options?: Partial<
        ApiRequestOptions<
            ResourceRoleLookupView[],
            "lookups/resourceRolesLookup"
        >
    >;
}

export const resourceRolesLookup = makeAction((
    { queryParams, options }: ResourceRolesLookupActionRequest,
) => apiGet<ResourceRoleLookupView[], "lookups/resourceRolesLookup">(
    "lookups/resourceRolesLookup",
    `lookups/resource-roles`,
    queryParams,
    options,
), "lookups/resourceRolesLookup");

export interface CreateResourceRoleActionRequest {
    request: CreateResourceRoleRequest;
    options?: Partial<ApiRequestOptions<void, "lookups/createResourceRole">>;
}

export const createResourceRole = makeAction((
    { request, options }: CreateResourceRoleActionRequest,
) => apiPost<void, "lookups/createResourceRole">(
    "lookups/createResourceRole",
    `lookups/resource-roles`,
    request,
    options,
), "lookups/createResourceRole");

export interface UpdateResourceRoleActionRequest {
    routeParams: {
        resourceRoleId: string;
    };
    request: UpdateResourceRoleRequest;
    options?: Partial<ApiRequestOptions<void, "lookups/updateResourceRole">>;
}

export const updateResourceRole = makeAction((
    { routeParams, request, options }: UpdateResourceRoleActionRequest,
) => apiPut<void, "lookups/updateResourceRole">(
    "lookups/updateResourceRole",
    `lookups/resource-roles/${routeParams.resourceRoleId}`,
    request,
    options,
), "lookups/updateResourceRole");

export interface DeleteResourceRoleActionRequest {
    routeParams: {
        resourceRoleId: string;
    };
    options?: Partial<ApiRequestOptions<void, "lookups/deleteResourceRole">>;
}

export const deleteResourceRole = makeAction((
    { routeParams, options }: DeleteResourceRoleActionRequest,
) => apiDelete<void, "lookups/deleteResourceRole">(
    "lookups/deleteResourceRole",
    `lookups/resource-roles/${routeParams.resourceRoleId}`,
    undefined,
    options,
), "lookups/deleteResourceRole");

export interface ResourceStatusLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/resourceStatusLookup">
    >;
}

export const resourceStatusLookup = makeAction((
    { options }: ResourceStatusLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/resourceStatusLookup">(
    "lookups/resourceStatusLookup",
    `lookups/resource-status`,
    undefined,
    options,
), "lookups/resourceStatusLookup");

export interface ResourceTypesLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/resourceTypesLookup">
    >;
}

export const resourceTypesLookup = makeAction((
    { options }: ResourceTypesLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/resourceTypesLookup">(
    "lookups/resourceTypesLookup",
    `lookups/resource-types`,
    undefined,
    options,
), "lookups/resourceTypesLookup");

export interface ResourcesLookupActionRequest {
    queryParams: {
        maxResults: number;
        search: string;
    };
    options?: Partial<
        ApiRequestOptions<ResourceLookupView[], "lookups/resourcesLookup">
    >;
}

export const resourcesLookup = makeAction((
    { queryParams, options }: ResourcesLookupActionRequest,
) => apiGet<ResourceLookupView[], "lookups/resourcesLookup">(
    "lookups/resourcesLookup",
    `lookups/resources`,
    queryParams,
    options,
), "lookups/resourcesLookup");

export interface ScopeRejectionReasonsLookupActionRequest {
    queryParams: {
        search: string;
        maxResults: number;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/scopeRejectionReasonsLookup">
    >;
}

export const scopeRejectionReasonsLookup = makeAction((
    { queryParams, options }: ScopeRejectionReasonsLookupActionRequest,
) => apiGet<LookupView[], "lookups/scopeRejectionReasonsLookup">(
    "lookups/scopeRejectionReasonsLookup",
    `lookups/scope-rejection-reasons`,
    queryParams,
    options,
), "lookups/scopeRejectionReasonsLookup");

export interface CreateScopeRejectionReasonActionRequest {
    request: CreateScopeRejectionReasonRequest;
    options?: Partial<
        ApiRequestOptions<void, "lookups/createScopeRejectionReason">
    >;
}

export const createScopeRejectionReason = makeAction((
    { request, options }: CreateScopeRejectionReasonActionRequest,
) => apiPost<void, "lookups/createScopeRejectionReason">(
    "lookups/createScopeRejectionReason",
    `lookups/scope-rejection-reasons`,
    request,
    options,
), "lookups/createScopeRejectionReason");

export interface UpdateScopeRejectionReasonActionRequest {
    routeParams: {
        reasonId: string;
    };
    request: UpdateScopeRejectionReasonRequest;
    options?: Partial<
        ApiRequestOptions<void, "lookups/updateScopeRejectionReason">
    >;
}

export const updateScopeRejectionReason = makeAction((
    { routeParams, request, options }: UpdateScopeRejectionReasonActionRequest,
) => apiPut<void, "lookups/updateScopeRejectionReason">(
    "lookups/updateScopeRejectionReason",
    `lookups/scope-rejection-reasons/${routeParams.reasonId}`,
    request,
    options,
), "lookups/updateScopeRejectionReason");

export interface DeleteScopeRejectionReasonActionRequest {
    routeParams: {
        reasonId: string;
    };
    options?: Partial<
        ApiRequestOptions<void, "lookups/deleteScopeRejectionReason">
    >;
}

export const deleteScopeRejectionReason = makeAction((
    { routeParams, options }: DeleteScopeRejectionReasonActionRequest,
) => apiDelete<void, "lookups/deleteScopeRejectionReason">(
    "lookups/deleteScopeRejectionReason",
    `lookups/scope-rejection-reasons/${routeParams.reasonId}`,
    undefined,
    options,
), "lookups/deleteScopeRejectionReason");

export interface ServiceTypesLookupActionRequest {
    queryParams: {
        contractId: string;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/serviceTypesLookup">
    >;
}

export const serviceTypesLookup = makeAction((
    { queryParams, options }: ServiceTypesLookupActionRequest,
) => apiGet<LookupView[], "lookups/serviceTypesLookup">(
    "lookups/serviceTypesLookup",
    `lookups/service-types`,
    queryParams,
    options,
), "lookups/serviceTypesLookup");

export interface ShortlistedResourceAvailabilityLookupActionRequest {
    options?: Partial<
        ApiRequestOptions<
            LookupView[],
            "lookups/shortlistedResourceAvailabilityLookup"
        >
    >;
}

export const shortlistedResourceAvailabilityLookup = makeAction((
    { options }: ShortlistedResourceAvailabilityLookupActionRequest = {},
) => apiGet<LookupView[], "lookups/shortlistedResourceAvailabilityLookup">(
    "lookups/shortlistedResourceAvailabilityLookup",
    `lookups/shortlisted-resource-availability`,
    undefined,
    options,
), "lookups/shortlistedResourceAvailabilityLookup");

export interface TaxRatesLookupActionRequest {
    queryParams: {
        maxResults: number;
        search: string;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/taxRatesLookup">
    >;
}

export const taxRatesLookup = makeAction((
    { queryParams, options }: TaxRatesLookupActionRequest,
) => apiGet<LookupView[], "lookups/taxRatesLookup">(
    "lookups/taxRatesLookup",
    `lookups/tax-rates`,
    queryParams,
    options,
), "lookups/taxRatesLookup");

export interface VendorContactsLookupActionRequest {
    queryParams: {
        vendorId: string;
        vendorSiteId: string;
        maxResults: number;
        search: string;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/vendorContactsLookup">
    >;
}

export const vendorContactsLookup = makeAction((
    { queryParams, options }: VendorContactsLookupActionRequest,
) => apiGet<LookupView[], "lookups/vendorContactsLookup">(
    "lookups/vendorContactsLookup",
    `lookups/vendor-contacts`,
    queryParams,
    options,
), "lookups/vendorContactsLookup");

export interface VendorSitesLookupActionRequest {
    queryParams: {
        vendorId: string;
        maxResults: number;
        search: string;
        vendorSiteId: string;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/vendorSitesLookup">
    >;
}

export const vendorSitesLookup = makeAction((
    { queryParams, options }: VendorSitesLookupActionRequest,
) => apiGet<LookupView[], "lookups/vendorSitesLookup">(
    "lookups/vendorSitesLookup",
    `lookups/vendor-sites`,
    queryParams,
    options,
), "lookups/vendorSitesLookup");

export interface VendorsLookupActionRequest {
    queryParams: {
        maxResults: number;
        search: string;
        vendorId: string;
    };
    options?: Partial<ApiRequestOptions<LookupView[], "lookups/vendorsLookup">>;
}

export const vendorsLookup = makeAction((
    { queryParams, options }: VendorsLookupActionRequest,
) => apiGet<LookupView[], "lookups/vendorsLookup">(
    "lookups/vendorsLookup",
    `lookups/vendors`,
    queryParams,
    options,
), "lookups/vendorsLookup");

export interface WorkOrdersLookupActionRequest {
    queryParams: {
        clientId: string;
        maxResults: number;
        search: string;
        contractId: string;
    };
    options?: Partial<
        ApiRequestOptions<WorkOrderLookupView[], "lookups/workOrdersLookup">
    >;
}

export const workOrdersLookup = makeAction((
    { queryParams, options }: WorkOrdersLookupActionRequest,
) => apiGet<WorkOrderLookupView[], "lookups/workOrdersLookup">(
    "lookups/workOrdersLookup",
    `lookups/work-orders`,
    queryParams,
    options,
), "lookups/workOrdersLookup");

export interface ServiceContractTypeLookupActionRequest {
    queryParams: {
        descriptionType: EnumDescriptionType;
    };
    options?: Partial<
        ApiRequestOptions<LookupView[], "lookups/serviceContractTypeLookup">
    >;
}

export const serviceContractTypeLookup = makeAction((
    { queryParams, options }: ServiceContractTypeLookupActionRequest,
) => apiGet<LookupView[], "lookups/serviceContractTypeLookup">(
    "lookups/serviceContractTypeLookup",
    `service-contract-types`,
    queryParams,
    options,
), "lookups/serviceContractTypeLookup");
