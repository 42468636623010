import * as mortar from "mortar/endpoints/auth";

export const requestPasswordChange = (
  { oldPassword, newPassword }: { oldPassword: string; newPassword: string },
  email: string
) =>
  mortar.updateSecret({
    queryParams: {
      key: email,
    },
    request: {
      oldSecret: oldPassword,
      newSecret: newPassword,
    },
    options: { preventErrorNotification: true },
  });
