import { Form } from "@redriver/cinnamon-mui";
import { regionsLookup } from "modules/lookups";

const RegionDropdown = ({ overrideAccess = false, ...props }) => (
  <Form.Dropdown
    {...props}
    lookup={regionsLookup}
    lookupParams={{ overrideAccess }}
  />
);

export default RegionDropdown;
