import { Form } from "@redriver/cinnamon-mui";
import SearchIcon from "@mui/icons-material/Search";

const SearchInput = (props) => (
  <Form.Input
    placeholder="Search"
    subLabel={<SearchIcon />}
    subLabelPosition="right"
    {...props}
  />
);

export default SearchInput;
