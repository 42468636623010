import { Form } from "@redriver/cinnamon-mui";
import { documentCategoriesLookup } from "modules/lookups";

const DocumentCategoryDropdown = ({
  viewType,
  categoryIds,
  excludeCategoryIds,
  ...props
}) => (
  <Form.Dropdown
    {...props}
    lookup={documentCategoriesLookup}
    lookupParams={{ viewType, categoryIds, excludeCategoryIds }}
  />
);

export default DocumentCategoryDropdown;
