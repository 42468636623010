// Auto Generated file, do not modify
import {
    apiDelete,
    apiGet,
    apiPost,
    apiPut,
    ApiRequestOptions,
} from "@redriver/cinnamon-mui";
import { AuthenticateResult } from "mortar/RedRiver/Saffron/Auth/Contracts";
import { UpdateSecretRequest } from "mortar/RedRiver/Saffron/Auth/Endpoints/Auth/Credentials";
import { SendResetLinkRequest } from "mortar/RedRiver/Saffron/Auth/Endpoints/Auth/ResetTokens";
import {
    AuthenticateResultResponse,
    AuthWithRefreshTokenRequest,
    CredentialsRequest,
    RevokeRefreshTokenRequest,
} from "mortar/RedRiver/Saffron/Auth/Endpoints/Auth/Token";
import { makeAction, makeFormData } from "../lib";

export interface AzureAdSignInActionRequest {
    queryParams: {
        code: string;
    };
    options?: Partial<
        ApiRequestOptions<AuthenticateResult, "auth/azureAdSignIn">
    >;
}

export const azureAdSignIn = makeAction((
    { queryParams, options }: AzureAdSignInActionRequest,
) => apiGet<AuthenticateResult, "auth/azureAdSignIn">(
    "auth/azureAdSignIn",
    `auth/azure-ad-sign-in`,
    queryParams,
    options,
), "auth/azureAdSignIn");

export interface UpdateSecretActionRequest {
    queryParams: {
        key: string;
    };
    request: UpdateSecretRequest;
    options?: Partial<ApiRequestOptions<void, "auth/updateSecret">>;
}

export const updateSecret = makeAction((
    { queryParams, request, options }: UpdateSecretActionRequest,
) => apiPost<void, "auth/updateSecret">(
    "auth/updateSecret",
    `auth/credentials`,
    request,
    { params: queryParams, ...options },
), "auth/updateSecret");

export interface SendResetLinkActionRequest {
    request: SendResetLinkRequest;
    options?: Partial<ApiRequestOptions<void, "auth/sendResetLink">>;
}

export const sendResetLink = makeAction((
    { request, options }: SendResetLinkActionRequest,
) => apiPost<void, "auth/sendResetLink">(
    "auth/sendResetLink",
    `auth/reset-tokens`,
    request,
    options,
), "auth/sendResetLink");

export interface CheckResetTokenUsedActionRequest {
    routeParams: {
        token: string;
    };
    options?: Partial<ApiRequestOptions<void, "auth/checkResetTokenUsed">>;
}

export const checkResetTokenUsed = makeAction((
    { routeParams, options }: CheckResetTokenUsedActionRequest,
) => apiGet<void, "auth/checkResetTokenUsed">(
    "auth/checkResetTokenUsed",
    `auth/reset-tokens/${routeParams.token}`,
    undefined,
    options,
), "auth/checkResetTokenUsed");

export interface CheckSetupTokenUsedActionRequest {
    routeParams: {
        token: string;
    };
    options?: Partial<ApiRequestOptions<void, "auth/checkSetupTokenUsed">>;
}

export const checkSetupTokenUsed = makeAction((
    { routeParams, options }: CheckSetupTokenUsedActionRequest,
) => apiGet<void, "auth/checkSetupTokenUsed">(
    "auth/checkSetupTokenUsed",
    `auth/setup-tokens/${routeParams.token}`,
    undefined,
    options,
), "auth/checkSetupTokenUsed");

export interface AuthWithCredsActionRequest {
    request: CredentialsRequest;
    options?: Partial<
        ApiRequestOptions<AuthenticateResultResponse, "auth/authWithCreds">
    >;
}

export const authWithCreds = makeAction((
    { request, options }: AuthWithCredsActionRequest,
) => apiPost<AuthenticateResultResponse, "auth/authWithCreds">(
    "auth/authWithCreds",
    `auth/token/credentials`,
    request,
    options,
), "auth/authWithCreds");

export interface AuthWithRefreshTokenActionRequest {
    request: AuthWithRefreshTokenRequest;
    options?: Partial<
        ApiRequestOptions<
            AuthenticateResultResponse,
            "auth/authWithRefreshToken"
        >
    >;
}

export const authWithRefreshToken = makeAction((
    { request, options }: AuthWithRefreshTokenActionRequest,
) => apiPost<AuthenticateResultResponse, "auth/authWithRefreshToken">(
    "auth/authWithRefreshToken",
    `auth/token/refresh`,
    request,
    options,
), "auth/authWithRefreshToken");

export interface RevokeRefreshTokenActionRequest {
    request: RevokeRefreshTokenRequest;
    options?: Partial<ApiRequestOptions<void, "auth/revokeRefreshToken">>;
}

export const revokeRefreshToken = makeAction((
    { request, options }: RevokeRefreshTokenActionRequest,
) => apiDelete<void, "auth/revokeRefreshToken">(
    "auth/revokeRefreshToken",
    `auth/token/refresh`,
    request,
    options,
), "auth/revokeRefreshToken");
