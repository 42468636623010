import { Format } from "@redriver/cinnamon-mui";
import ClockIcon from "assets/icons/Clock";
import IconLabel from "./IconLabel";

const DateTimeLabel = ({ value, ...props }) => (
  <IconLabel
    {...props}
    icon={<ClockIcon />}
    value={<Format.DateTime value={value} />}
  />
);

export default DateTimeLabel;
