import { Stack } from "@mui/material";

const Title = ({
  as: As = "span",
  spacing = 1,
  startAdornment,
  endAdornment,
  ...props
}) => (
  <Stack direction="row" spacing={spacing} alignItems="center">
    {startAdornment}
    <As {...props} />
    {endAdornment}
  </Stack>
);

export default Title;
