import { connect } from "react-redux";
import { getAuthClaims } from "@redriver/cinnamon-mui";
import { claimExists } from "./utils";

const withPermissions = (Component) => {
  const PermissionWrapper = ({ permissions, ...props }) => {
    const hasPermission = (target, action, any) =>
      claimExists(permissions, target, action, any);
    return <Component {...props} hasPermission={hasPermission} />;
  };

  const mapStateToProps = (state) => {
    const { permissions } = getAuthClaims(state);
    return { permissions };
  };

  return connect(mapStateToProps, () => ({}))(PermissionWrapper);
};

export default withPermissions;
