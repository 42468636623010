import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 26 35"
  >
    <g id="pdf_1_" data-name="pdf (1)" transform="translate(-99.773)">
      <g id="x0020_1_15_" transform="translate(99.773 0)">
        <path
          id="Path_4826"
          data-name="Path 4826"
          d="M104.3,0h11.623l9.851,10.337V30.444A4.537,4.537,0,0,1,121.256,35H104.3a4.538,4.538,0,0,1-4.526-4.556V4.556A4.538,4.538,0,0,1,104.3,0Z"
          transform="translate(-99.773 0)"
          fill="#cc0808"
          fillRule="evenodd"
        />
        <g id="Group_7539" data-name="Group 7539" transform="translate(5.03 0)">
          <path
            id="Path_4827"
            data-name="Path 4827"
            d="M467.219,0V10.19h9.86Z"
            transform="translate(-456.108)"
            fill="#fff"
            fillRule="evenodd"
            opacity="0.302"
          />
          <path
            id="Path_4828"
            data-name="Path 4828"
            d="M214.278,452.309v-6.35h2.7a2.239,2.239,0,0,1,1.6.556,2.119,2.119,0,0,1,0,2.954,2.239,2.239,0,0,1-1.6.556H215.9v2.285Zm1.624-3.666h.895a.8.8,0,0,0,.565-.174.671.671,0,0,0,0-.956.8.8,0,0,0-.565-.174H215.9Zm3.944,3.666v-6.35h2.25a3.929,3.929,0,0,1,1.251.191,3.363,3.363,0,0,1,1.06.565,2.494,2.494,0,0,1,.747.99,3.926,3.926,0,0,1,0,2.858,2.494,2.494,0,0,1-.747.99,3.364,3.364,0,0,1-1.06.565,3.929,3.929,0,0,1-1.251.191Zm1.59-1.381h.469a2.6,2.6,0,0,0,.7-.087,2.11,2.11,0,0,0,.6-.287,1.288,1.288,0,0,0,.434-.556,2.467,2.467,0,0,0,0-1.729,1.288,1.288,0,0,0-.434-.556,2.109,2.109,0,0,0-.6-.287,2.6,2.6,0,0,0-.7-.087h-.469Zm4.8,1.381v-6.35h4.517v1.381h-2.893v1.016h2.311v1.373h-2.311v2.58Z"
            transform="translate(-214.278 -426.37)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>,
  "Pdf"
);
