import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 12 15"
    fill="currentColor"
  >
    <path
      id="resources"
      d="M26.874,17.365l-.623-.08a.529.529,0,0,0-.452.161,3.489,3.489,0,0,0-.817,1.426.539.539,0,0,0,.087.475l.38.5-.38.5a.539.539,0,0,0-.087.475,3.489,3.489,0,0,0,.817,1.426.529.529,0,0,0,.452.161l.623-.08.242.584a.533.533,0,0,0,.365.314,3.43,3.43,0,0,0,1.634,0,.533.533,0,0,0,.365-.314l.242-.584.623.08a.529.529,0,0,0,.452-.161,3.487,3.487,0,0,0,.817-1.426.539.539,0,0,0-.087-.475l-.38-.5.38-.5a.539.539,0,0,0,.087-.475,3.488,3.488,0,0,0-.817-1.426.529.529,0,0,0-.452-.161l-.623.08-.242-.584a.533.533,0,0,0-.365-.314,3.427,3.427,0,0,0-1.634,0,.533.533,0,0,0-.365.314Zm-2.409,4.9a4.568,4.568,0,0,1-.5-1.131,1.616,1.616,0,0,1,.164-1.28,1.617,1.617,0,0,1-.164-1.28,4.56,4.56,0,0,1,1.068-1.865,1.588,1.588,0,0,1,1.182-.5,1.6,1.6,0,0,1,1.018-.783h0a2.638,2.638,0,0,0-1.753-.664H22.3a2.671,2.671,0,0,0-2.66,2.681V20.68A1.576,1.576,0,0,0,21.2,22.262ZM28.3,17.976a1.875,1.875,0,1,0,1.86,1.875A1.869,1.869,0,0,0,28.3,17.976Zm0,1.065a.81.81,0,1,1-.8.81A.808.808,0,0,1,28.3,19.041ZM23.888,8.333a2.679,2.679,0,1,0,2.658,2.679A2.67,2.67,0,0,0,23.888,8.333Z"
      transform="scale(0.85) translate(-18.635 -7.333)"
      fillRule="evenodd"
    />
  </svg>,
  "Resource"
);
