import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 512 512"
  >
    <g>
      <g>
        <circle fill="#0A67B2" cx="355" cy="352.2" r="131.6" />
        <circle fill="#3B9FE2" cx="354.8" cy="352.2" r="109.3" />
        <g>
          <path
            fill="#FFFFFF"
            d="M340.4,365.9c1.9,19.4,8.1,29.1,18.7,29.1c3.9,0,7.6-0.9,10.8-2.8c3.3-1.9,6.4-6.3,9.4-13.3
				c1.9-4.5,3.6-7.4,5-8.5c1.4-1.1,2.9-1.7,4.5-1.7c3.2,0,5.8,0.9,7.6,2.8c1.8,1.9,2.7,4.5,2.7,7.8c0,7.9-3.9,15.3-11.7,22
				c-7.8,6.7-17.2,10.1-28.3,10.1c-10.4,0-19.4-3.8-27.2-11.3c-7.7-7.5-12.4-19-13.9-34.3h-10.3c-1,0-1.5-0.7-1.5-2.1
				c0-1.3,0.5-3.1,1.5-5.1c1-2.1,1.9-3.1,2.6-3.1h7v-2.9c0-2.4,0.1-5.8,0.3-10.3h-10c-1,0-1.5-0.7-1.5-2c0-1.4,0.5-3.1,1.5-5.2
				c1-2.1,1.9-3.1,2.6-3.1h8.1c3.5-28.2,16.8-42.3,39.9-42.3c10.2,0,18.9,2.6,26.3,7.9c7.4,5.2,11,11.8,11,19.5
				c0,2.6-0.9,4.9-2.6,6.7c-1.7,1.8-3.8,2.8-6.3,2.8c-2.8,0-4.8-0.7-6-2.1c-1.3-1.4-2.5-3.6-3.8-6.5c-1.6-3.8-3.4-6.8-5.6-8.8
				c-2.2-2-5.8-3-11-3c-5.9,0-10.5,2.1-13.7,6.2c-3.2,4.2-5.3,10.7-6.2,19.5h26.1c1.1,0,1.6,0.7,1.6,2.1c0,1.5-0.5,3.3-1.5,5.2
				c-1,2-1.9,3-2.6,3h-24.2l-0.2,7.9c0,2.4,0.1,4.2,0.2,5.3h26.8c1.1,0,1.6,0.7,1.6,2c0,1.6-0.5,3.3-1.5,5.3c-1,2-1.9,3-2.6,3H340.4
				z"
          />
        </g>
      </g>
      <path
        opacity="0.3"
        fill="#0A67B2"
        d="M484.4,352.2c0,72.7-58.9,131.6-131.6,131.6V220.6C425.5,220.6,484.4,279.5,484.4,352.2z"
      />
    </g>
    <g>
      <g>
        <path
          fill="#1D7894"
          d="M68.9,222.2c-3.5,0-7-1.3-9.6-4c-5.3-5.3-5.3-14,0-19.3L171.8,86.3l115.6,83.8L423.1,32.4
			c5.3-5.4,13.9-5.4,19.3-0.1c5.4,5.3,5.4,13.9,0.1,19.3L290.4,206l-115.8-83.9l-96.1,96.1C75.9,220.9,72.4,222.2,68.9,222.2z"
        />
      </g>
      <g>
        <path
          fill="#1D7894"
          d="M447.2,103.1c-6.5,0-12.3-4.7-13.4-11.3l-8.2-46.7h-44.3c-7.5,0-13.6-6.1-13.6-13.6s6.1-13.6,13.6-13.6h67.2
			l12.2,69.3c1.3,7.4-3.6,14.5-11.1,15.8C448.8,103,448,103.1,447.2,103.1z"
        />
      </g>
    </g>
    <g>
      <g>
        <circle fill="#29ABD4" cx="169.1" cy="352.2" r="131.6" />
        <circle fill="#56CDE8" cx="169.1" cy="352.2" r="109.3" />
        <g>
          <path
            fill="#FFFFFF"
            d="M174,281.6v8.7c5.7,0.6,10.6,1.8,14.7,3.5c4.1,1.8,7.7,4.4,10.7,8c2.4,2.7,4.3,5.5,5.6,8.4
				c1.3,2.9,2,5.5,2,8c0,2.7-1,5-2.9,7c-2,2-4.3,2.9-7.1,2.9c-5.3,0-8.7-2.8-10.2-8.5c-1.8-6.7-6-11.1-12.8-13.3v33.4
				c6.6,1.8,11.9,3.5,15.9,5c3.9,1.5,7.5,3.7,10.6,6.5c3.3,2.9,5.9,6.5,7.7,10.6c1.8,4.1,2.7,8.6,2.7,13.5c0,6.1-1.4,11.8-4.3,17.2
				c-2.9,5.3-7.1,9.7-12.7,13.1c-5.6,3.4-12.2,5.4-19.8,6v20c0,3.2-0.3,5.5-0.9,6.9c-0.6,1.4-2,2.2-4.1,2.2c-1.9,0-3.3-0.6-4.1-1.8
				c-0.8-1.2-1.2-3-1.2-5.5v-21.7c-6.3-0.7-11.7-2.2-16.4-4.4c-4.7-2.3-8.6-5-11.7-8.4c-3.1-3.3-5.5-6.8-7-10.4
				c-1.5-3.6-2.3-7.2-2.3-10.6c0-2.6,1-4.9,3-7c2-2.1,4.5-3.1,7.5-3.1c2.4,0,4.4,0.6,6.1,1.7c1.7,1.1,2.8,2.7,3.5,4.7
				c1.4,4.4,2.7,7.8,3.8,10.1c1.1,2.3,2.7,4.5,4.9,6.4c2.2,1.9,5,3.4,8.6,4.4v-37.4c-7.2-2-13.2-4.2-18-6.6
				c-4.8-2.4-8.7-5.9-11.7-10.4c-3-4.5-4.5-10.3-4.5-17.4c0-9.2,2.9-16.8,8.8-22.7c5.9-5.9,14.3-9.3,25.4-10.3v-8.5
				c0-4.5,1.7-6.8,5.1-6.8C172.3,275,174,277.2,174,281.6z M163.8,336.7V306c-4.5,1.3-8,3.1-10.5,5.3c-2.5,2.2-3.8,5.5-3.8,10
				c0,4.2,1.2,7.4,3.5,9.6C155.4,333,158.9,335,163.8,336.7z M174,360.6v35.2c5.4-1.1,9.6-3.2,12.5-6.5c2.9-3.3,4.4-7.1,4.4-11.4
				c0-4.7-1.4-8.3-4.3-10.8C183.8,364.5,179.5,362.4,174,360.6z"
          />
        </g>
      </g>
      <path
        opacity="0.28"
        fill="#29ABD4"
        d="M301,352.2c0,72.7-58.9,131.6-131.6,131.6V220.6C242.1,220.6,301,279.5,301,352.2z"
      />
    </g>
  </svg>,
  "ExchangeRate"
);
