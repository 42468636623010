import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
  >
    <g id="file" transform="translate(-1)">
      <path
        id="Path_4781"
        data-name="Path 4781"
        d="M134.765,0H123.294A2.289,2.289,0,0,0,121,2.283V23.691a2.289,2.289,0,0,0,2.294,2.283h15.294a2.289,2.289,0,0,0,2.294-2.283V6.088Z"
        transform="translate(-113.882)"
        fill="#bde0f1"
      />
      <path
        id="Path_4783"
        data-name="Path 4783"
        d="M391,0V5.327a.763.763,0,0,0,.765.761h5.353Z"
        transform="translate(-370.118)"
        fill="#77bee2"
      />
      <g
        id="Group_7465"
        data-name="Group 7465"
        transform="translate(10.686 9.233)"
      >
        <path
          id="Path_4784"
          data-name="Path 4784"
          d="M202.98,273.522H191.765a.761.761,0,1,1,0-1.522H202.98a.761.761,0,1,1,0,1.522Z"
          transform="translate(-191 -267.434)"
          fill="#082a4e"
        />
        <path
          id="Path_4785"
          data-name="Path 4785"
          d="M202.98,183.522H191.765a.761.761,0,1,1,0-1.522H202.98a.761.761,0,1,1,0,1.522Z"
          transform="translate(-191 -182)"
          fill="#082a4e"
        />
        <path
          id="Path_4786"
          data-name="Path 4786"
          d="M202.98,363.522H191.765a.761.761,0,1,1,0-1.522H202.98a.761.761,0,1,1,0,1.522Z"
          transform="translate(-191 -352.868)"
          fill="#082a4e"
        />
      </g>
      <path
        id="Path_4790"
        data-name="Path 4790"
        d="M7.882,255.7a6.849,6.849,0,1,1,6.882-6.849A6.873,6.873,0,0,1,7.882,255.7Z"
        transform="translate(0 -229.697)"
        fill="#ffb400"
      />
      <g
        id="Group_7466"
        data-name="Group 7466"
        transform="translate(4.783 15.725)"
      >
        <g id="Group_7162" data-name="Group 7162" transform="translate(0 0)">
          <path
            id="Path_4739"
            data-name="Path 4739"
            d="M87.977,70.8c-1.483,0-2.644-.831-2.644-1.888V65.888C85.333,64.831,86.5,64,87.977,64s2.645.831,2.645,1.888a.378.378,0,1,1-.756,0c0-.616-.865-1.133-1.888-1.133s-1.888.519-1.888,1.133v3.022c0,.616.865,1.133,1.888,1.133a.378.378,0,0,1,0,.756Z"
            transform="translate(-84.585 -64)"
            fill="#eceff1"
          />
          <path
            id="Path_4740"
            data-name="Path 4740"
            d="M68.161,128.756H64.387a.378.378,0,1,1,0-.756h3.774a.378.378,0,0,1,0,.756Z"
            transform="translate(-64.009 -125.733)"
            fill="#eceff1"
          />
          <path
            id="Path_4741"
            data-name="Path 4741"
            d="M68.161,171.422H64.387a.378.378,0,0,1,0-.756h3.774a.378.378,0,0,1,0,.756Z"
            transform="translate(-64.009 -166.888)"
            fill="#eceff1"
          />
        </g>
        <g id="Group_7162-2" data-name="Group 7162" transform="translate(0 0)">
          <path
            id="Path_4739-2"
            data-name="Path 4739"
            d="M87.977,64c-1.482,0-2.644.831-2.644,1.888v3.023c0,1.057,1.162,1.888,2.644,1.888s2.645-.831,2.645-1.888a.378.378,0,0,0-.756,0c0,.616-.865,1.133-1.888,1.133s-1.888-.519-1.888-1.133V65.889c0-.616.865-1.133,1.888-1.133a.378.378,0,1,0,0-.755Z"
            transform="translate(-84.585 -64)"
            fill="#eceff1"
          />
          <path
            id="Path_4740-2"
            data-name="Path 4740"
            d="M68.161,128H64.387a.378.378,0,1,0,0,.755h3.774a.378.378,0,1,0,0-.755Z"
            transform="translate(-64.009 -124.223)"
            fill="#eceff1"
          />
          <path
            id="Path_4741-2"
            data-name="Path 4741"
            d="M68.161,170.666H64.387a.378.378,0,0,0,0,.755h3.774a.378.378,0,1,0,0-.755Z"
            transform="translate(-64.009 -168.4)"
            fill="#eceff1"
          />
        </g>
      </g>
    </g>
  </svg>,
  "CommittedInvoices"
);
