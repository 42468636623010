import React from "react";
import { Checkbox, Button } from "@mui/material";
import { useFormField, useLookup } from "@redriver/cinnamon-mui";
import DocumentCategoryDropdown from "./DocumentCategoryDropdown";
import { documentCategoriesLookup } from "modules/lookups";

const DocumentCategoryListbox = React.forwardRef(
  ({ field, children, viewType, ...props }, ref) => {
    const [value, setValue] = useFormField(field);

    const [{ response: documentCategoryValues }] = useLookup(
      documentCategoriesLookup,
      { viewType }
    );

    const noneSelected = !value || value.length === 0;
    const allSelected =
      value &&
      documentCategoryValues?.every((v) =>
        value.some((x) => x.value === v.value)
      );

    return (
      <ul {...props} ref={ref}>
        <li style={{ marginLeft: 8 }}>
          <Button
            variant="text"
            disabled={allSelected}
            onClick={() =>
              setValue(documentCategoryValues?.map((v) => ({ value: v.value })))
            }
          >
            Select All
          </Button>
          <Button
            variant="text"
            disabled={noneSelected}
            onClick={() => setValue([])}
          >
            Deselect All
          </Button>
        </li>
        {children}
      </ul>
    );
  }
);

const DocumentCategoryFilter = ({ field, viewType, ...props }) => (
  <DocumentCategoryDropdown
    {...props}
    multiple
    disableClearable
    disableCloseOnSelect
    filterSelectedOptions={false}
    field={field}
    viewType={viewType}
    ListboxComponent={DocumentCategoryListbox}
    ListboxProps={{ field, viewType }}
    renderOption={(props, option, { selected }) => (
      <li {...props}>
        <Checkbox style={{ padding: 0, marginRight: 8 }} checked={selected} />
        {option.label}
      </li>
    )}
  />
);

export default DocumentCategoryFilter;
