import { useMatch } from "react-router-dom";
import { RoutingUtils } from "@redriver/cinnamon-mui";
import { PermissionCheck } from "components/auth";
import { NavMenu } from "components/navigation";
import { Actions, Targets } from "constants/permissions";
import { AppRoutes } from "constants/routes";
import JobsIcon from "assets/icons/menu/Jobs";

const JobsNavMenu = ({ children }) => {
  const jobsPath = RoutingUtils.subRoutesPath(AppRoutes.Jobs);

  const jobsMatch = useMatch(jobsPath);

  return (
    <PermissionCheck action={Actions.View} target={[Targets.Job]} any>
      <NavMenu title={children} link={AppRoutes.Jobs} active={jobsMatch}>
        <PermissionCheck action={Actions.View} target={Targets.Job}>
          <NavMenu.Item link={AppRoutes.Jobs} icon={<JobsIcon />}>
            Jobs
          </NavMenu.Item>
        </PermissionCheck>
      </NavMenu>
    </PermissionCheck>
  );
};

export default JobsNavMenu;
