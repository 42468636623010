import { Form } from "@redriver/cinnamon-mui";
import { serviceTypesLookup } from "modules/lookups";

const ServiceTypeDropdown = ({ contractId, ...props }) => (
  <Form.Dropdown
    {...props}
    search
    lookup={serviceTypesLookup}
    lookupParams={{ contractId }}
  />
);

export default ServiceTypeDropdown;
