import { apiPatch } from "@redriver/cinnamon-mui";
import * as mortar from "mortar/endpoints/auth";

const NAMESPACE = "SYSTEM/USER_SETUP";
export const REQUEST_SETUP = `${NAMESPACE}/REQUEST`;

export const validateSetupToken = (token: string) =>
  mortar.checkSetupTokenUsed({
    routeParams: { token },
    options: { auth: false, preventErrorNotification: true },
  });

export const requestUserSetup = (
  { newPassword }: { newPassword: string },
  token: string
) =>
  apiPatch(
    REQUEST_SETUP,
    `auth/setup-tokens/${token}`,
    { isUsed: true, newPassword },
    { auth: false, preventErrorNotification: true }
  );
