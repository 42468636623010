import { ListBuilderState } from "@redriver/cinnamon-mui";
import * as mortar from "mortar/endpoints/globalSearch";

export const getSearchResults = (
  { pagination }: ListBuilderState,
  search: string
) =>
  mortar.globalSearch({
    queryParams: {
      ...pagination,
      search,
    },
  });
