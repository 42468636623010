import { useMemo } from "react";
import LightBox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import { getFileExtension, imageExtensions } from "modules/helpers";
import { Box } from "@mui/material";
const ImageLightbox = ({ files, open, onClose, targetSrc }) => {
  const slides = useMemo(
    () =>
      (files || [])
        .filter((x) => {
          const extension = getFileExtension(x.fileName);
          return imageExtensions.includes(extension);
        })
        .map((x) => {
          const extension = getFileExtension(x.fileName);
          return {
            src: x.url,
            isSvg: extension == "svg",
          };
        }),
    [files]
  );

  const targetIndex = slides.findIndex((s) => s.src == targetSrc && targetSrc);

  return (
    <div
      onKeyDown={(e) => {
        e.stopPropagation();
      }}
    >
      <LightBox
        open={open}
        close={onClose}
        slides={slides}
        index={targetIndex > -1 ? targetIndex : 0}
        styles={{
          container: { backgroundColor: "rgba(0, 0, 0, .7)" },
        }}
        controller={{
          closeOnBackdropClick: true,
        }}
        render={{
          buttonPrev: slides.length <= 1 ? () => null : undefined,
          buttonNext: slides.length <= 1 ? () => null : undefined,
          slideContainer: ({ slide, children }) => (
            <Box
              sx={{
                img: {
                  minHeight: slide.isSvg ? "10em" : undefined,
                  minWidth: slide.isSvg ? "10em" : undefined,
                  maxHeight: "90vh !important",
                  maxWidth: "90vw !important",
                },
              }}
            >
              {children}
            </Box>
          ),
        }}
        plugins={[Zoom]}
      />
    </div>
  );
};

export default ImageLightbox;
