import EmailIcon from "assets/icons/Email";
import PhoneIcon from "assets/icons/Phone";
import StarFilled from "assets/icons/StarFilled";
import { ContactMethod } from "constants/enums";
import IconLabel from "./IconLabel";

const ContactPointLabel = ({ type, primary, ...props }) => (
  <IconLabel
    icon={type === ContactMethod.Email ? <EmailIcon /> : <PhoneIcon />}
    fallback="Not entered"
    endIcon={primary ? <StarFilled /> : undefined}
    {...props}
  />
);

export default ContactPointLabel;
