import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 591.857 791.454"
  >
    <g id="xls" transform="translate(-99.799)">
      <g id="x0020_1_30_">
        <path
          id="Path_4841"
          data-name="Path 4841"
          d="M202.63,0H467.507L691.656,233.97V688.334c0,56.9-45.927,103.12-102.831,103.12H202.63c-56.615,0-102.831-46.216-102.831-103.12v-585.5C99.8,46.216,146.015,0,202.63,0Z"
          fill="#00a14b"
          fillRule="evenodd"
        />
        <g id="Group_7613" data-name="Group 7613">
          <path
            id="Path_4842"
            data-name="Path 4842"
            d="M467.218,0V231.948H691.656Z"
            fill="#a4e276"
            fillRule="evenodd"
            opacity="0.302"
          />
          <path
            id="Path_4843"
            data-name="Path 4843"
            d="M345.9,576.26H307.483L281.2,532.066,254.912,576.26H216.206l45.639-76.835L221.7,432.7H260.4l20.8,34.662L301.705,432.7h38.706l-39.862,67.014Zm13.576,0V432.7H396.16V545.063h62.392v31.2H359.476Zm159.735,1.733a58.686,58.686,0,0,1-38.128-13.576c-10.688-8.666-16.753-19.642-17.62-32.64l31.485-9.243a28.517,28.517,0,0,0,8.666,17.62,25.472,25.472,0,0,0,17.331,6.932q7.8,0,13-3.466a11.134,11.134,0,0,0,4.91-9.243c0-3.177-1.444-5.777-4.044-8.088a36.739,36.739,0,0,0-10.11-5.2,133.569,133.569,0,0,0-13.865-4.044,96.659,96.659,0,0,1-15.309-5.488,53.9,53.9,0,0,1-13.865-7.8c-4.333-2.889-7.51-7.221-10.11-12.71a41.864,41.864,0,0,1-4.044-18.775c0-11.843,4.91-21.664,15.02-29.463,10.11-8.088,22.242-11.843,36.4-11.843s26.574,3.466,36.973,10.11c10.4,6.932,17.042,15.887,19.642,26.863l-32.929,13.865a28.744,28.744,0,0,0-8.377-14.731,23.063,23.063,0,0,0-15.309-5.488,20.538,20.538,0,0,0-10.976,2.889,8.465,8.465,0,0,0-3.755,7.51c0,2.889,1.733,5.488,5.2,7.51a48.208,48.208,0,0,0,13.287,4.044,113.7,113.7,0,0,1,17.042,4.333,105.286,105.286,0,0,1,17.331,7.8c5.2,2.889,9.532,7.8,13,14.154,3.755,6.644,5.488,14.443,5.488,23.4,0,13.287-5.2,24.264-15.6,32.351s-23.972,12.419-40.726,12.419Z"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>,
  "Xls"
);
