import { Box, Button } from "@mui/material";
import { ModalBuilder, Modal, Form, FormFile } from "@redriver/cinnamon-mui";
import { FileSizeError, FileTypeError } from "components/forms";
import { uploadUserSignature } from "./actions";
import { useState } from "react";

const UploadUserSignature = ({ as: As = Button, onUploaded, ...props }) => {
  const [previewUrl, setPreviewUrl] = useState(null);
  return (
    <ModalBuilder
      withForm
      submitAction={uploadUserSignature}
      onSubmitted={onUploaded}
      renderTrigger={(showModal) => <As onClick={showModal} {...props} />}
      renderModal={(modalProps, formProps) => (
        <Modal.Upload {...modalProps} header="Upload Signature">
          <Form {...formProps}>
            {previewUrl && (
              <Box mb={2} display="flex" justifyContent="center" width="100%">
                <img
                  src={previewUrl}
                  style={{
                    minWidth: "10em",
                    maxWidth: "30em",
                    maxHeight: "30em",
                  }}
                />
              </Box>
            )}
            <FormFile field="file" dropzone fluid />
            <FileSizeError field="file" maxSizeMB={100} />
            <FileTypeError
              field="file"
              mimeTypes={[
                "image/jpeg",
                "image/png",
                "image/gif",
                "image/bmp",
                "image/svg+xml",
              ]}
              error="Only image file types can be uploaded"
            />
            <Form.Trigger
              field="file"
              action={({ fields }) => {
                if (fields.file) {
                  setPreviewUrl(URL.createObjectURL(fields.file));
                }
              }}
            />
          </Form>
        </Modal.Upload>
      )}
    />
  );
};

export default UploadUserSignature;
