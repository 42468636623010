import { useState } from "react";
import { useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import { UserSetup } from "features/System";
import { SystemPageContent } from "components/pages";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const UserSetupPage = () => {
  const { token } = useParams();
  const [setupSuccess, setSetupSuccess] = useState(false);

  return (
    <SystemPageContent
      title={
        setupSuccess ? (
          <Stack flexDirection="row" alignItems="center">
            Account Created
            <CheckCircleIcon color="success" sx={{ ml: 1 }} />
          </Stack>
        ) : (
          "Create New Account"
        )
      }
    >
      <UserSetup token={token} onSetupSuccess={() => setSetupSuccess(true)} />
    </SystemPageContent>
  );
};

export default UserSetupPage;
