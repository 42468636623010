import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 26.18 35"
  >
    <g id="image" transform="translate(-523.727 406)">
      <g id="x0020_1_9_" transform="translate(523.727 -406)">
        <path
          id="Path_4841"
          data-name="Path 4841"
          d="M104.285,0h11.706l9.915,10.345v20.1A4.56,4.56,0,0,1,121.348,35H104.285a4.56,4.56,0,0,1-4.558-4.558V4.558A4.56,4.56,0,0,1,104.285,0Z"
          transform="translate(-99.727)"
          fill="#05c031"
          fillRule="evenodd"
        />
        <g id="Group_7613" data-name="Group 7613" transform="translate(7.429)">
          <path
            id="Path_4842"
            data-name="Path 4842"
            d="M467.176,0V10.256h9.93Z"
            transform="translate(-458.356)"
            fill="#97cba3"
            fillRule="evenodd"
            opacity="0.302"
          />
          <path
            id="Path_4843"
            data-name="Path 4843"
            d="M277.83,395.56h-8.894a1.219,1.219,0,0,0-1.214,1.213v5.653a1.219,1.219,0,0,0,1.214,1.214h8.894a1.206,1.206,0,0,0,1.2-1.214v-5.653A1.206,1.206,0,0,0,277.83,395.56Zm-6.467,1.524a1.31,1.31,0,1,1-1.31,1.31A1.31,1.31,0,0,1,271.363,397.084Zm6.867,5.342a.411.411,0,0,1-.4.414h-8.894a.411.411,0,0,1-.4-.414v-.237l1.613-1.613,1.332,1.332a.4.4,0,0,0,.577,0l3.345-3.345,2.827,2.827Z"
            transform="translate(-267.722 -378.067)"
            fill="#fff"
            fillRule="evenodd"
          />
        </g>
      </g>
    </g>
  </svg>,
  "Image"
);
