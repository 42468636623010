import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 22.904 26.291"
  >
    <g
      id="Group_8023"
      data-name="Group 8023"
      transform="translate(-1167.785 -656.74)"
    >
      <g id="sketching" transform="translate(1167.785 656.74)">
        <path
          id="Rectangle_4589"
          data-name="Rectangle 4589"
          d="M2,0H9.781a0,0,0,0,1,0,0V26.291a0,0,0,0,1,0,0H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0Z"
          transform="translate(0)"
          fill="#bde0f1"
        />
        <path
          id="Rectangle_4592"
          data-name="Rectangle 4592"
          d="M0,0H7.781a2,2,0,0,1,2,2V24.291a2,2,0,0,1-2,2H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z"
          transform="translate(9.781)"
          fill="#b2d2e2"
        />
        <path
          id="Path_5985"
          data-name="Path 5985"
          d="M189.591,322.774l-3.367,5.842h6.734Z"
          transform="translate(-176.863 -306.2)"
          fill="#d64c00"
        />
        <circle
          id="Ellipse_28"
          data-name="Ellipse 28"
          cx="3.321"
          cy="3.321"
          r="3.321"
          transform="translate(4.019 9.382)"
          fill="#efa900"
        />
        <path
          id="Path_5986"
          data-name="Path 5986"
          d="M189.591,322.774l-3.367,5.842h3.367Z"
          transform="translate(-176.863 -306.2)"
          fill="#ec5705"
        />
        <path
          id="Path_5987"
          data-name="Path 5987"
          d="M85.5,182.7a3.321,3.321,0,1,0,0,6.642Z"
          transform="translate(-78.161 -173.317)"
          fill="#ffb400"
        />
        <path
          id="Path_5988"
          data-name="Path 5988"
          d="M76.252,273.206l-4.834,8.387h9.668Z"
          transform="translate(-67.952 -259.177)"
          fill="#d64c00"
        />
        <path
          id="Path_5989"
          data-name="Path 5989"
          d="M76.252,273.206l-4.834,8.387h4.834Z"
          transform="translate(-67.952 -259.177)"
          fill="#ec5705"
        />
        <g
          id="Group_8021"
          data-name="Group 8021"
          transform="translate(9.244 2.82)"
        >
          <path
            id="Path_5990"
            data-name="Path 5990"
            d="M189.712,56.53h-5.229l-.537-.8.537-.8h5.229Z"
            transform="translate(-183.946 -54.92)"
            fill="#082a4e"
          />
          <path
            id="Path_5991"
            data-name="Path 5991"
            d="M188.524,110.428h-4.042l-.537-.8.537-.8h4.042Z"
            transform="translate(-183.946 -106.05)"
            fill="#082a4e"
          />
        </g>
        <g
          id="Group_8022"
          data-name="Group 8022"
          transform="translate(4.552 2.82)"
        >
          <rect
            id="Rectangle_4590"
            data-name="Rectangle 4590"
            width="5.229"
            height="1.61"
            fill="#1d7894"
          />
          <rect
            id="Rectangle_4591"
            data-name="Rectangle 4591"
            width="4.042"
            height="1.61"
            transform="translate(1.187 2.768)"
            fill="#1d7894"
          />
        </g>
      </g>
      <g id="pencil" transform="translate(1180.731 660.452)">
        <path
          id="Path_5954"
          data-name="Path 5954"
          d="M4.391,440.639a.3.3,0,0,0,.327.065l.718-.3.018-.832Z"
          transform="translate(-4.303 -430.768)"
          fill="#16202d"
        />
        <path
          id="Path_5955"
          data-name="Path 5955"
          d="M.318,440.46l-.3.718a.3.3,0,0,0,.065.327l1.328-.8Z"
          transform="translate(0 -431.635)"
          fill="#2d3e53"
        />
        <path
          id="Path_5956"
          data-name="Path 5956"
          d="M45.919,381.755l.018-1.4-1.771,1.771.248.248Z"
          transform="translate(-43.281 -372.735)"
          fill="#ffd19c"
        />
        <path
          id="Path_5957"
          data-name="Path 5957"
          d="M16.5,365.33l-.62,1.505.567.567,1.86-1.116Z"
          transform="translate(-15.563 -358.01)"
          fill="#fff0be"
        />
        <path
          id="Path_5958"
          data-name="Path 5958"
          d="M334.034,30.425,333.609,30l-2.727,2.94,2.3-.815.85-.85A.6.6,0,0,0,334.034,30.425Z"
          transform="translate(-324.252 -29.399)"
          fill="#a80a0a"
        />
        <path
          id="Path_5959"
          data-name="Path 5959"
          d="M349.2.6l-.425-.425a.6.6,0,0,0-.85,0l-.85.85-.283,2.834L349.2,1.452a.6.6,0,0,0,0-.85Z"
          transform="translate(-339.845 0)"
          fill="#f00f0f"
        />
        <path
          id="Path_5960"
          data-name="Path 5960"
          d="M297.294,115.278l2.338.638.638-.638-.425-.425Z"
          transform="translate(-291.337 -112.552)"
          fill="#dff5ff"
        />
        <path
          id="Path_5961"
          data-name="Path 5961"
          d="M331.027,52.488l-1.275-1.275-.638.638.425,2.125Z"
          transform="translate(-322.52 -50.187)"
          fill="#fff"
        />
        <path
          id="Path_5962"
          data-name="Path 5962"
          d="M110.457,152.329l.425.425,5.656-5.656-.425-.425-3.863,1.793Z"
          transform="translate(-108.244 -143.734)"
          fill="#db9537"
        />
        <path
          id="Path_5963"
          data-name="Path 5963"
          d="M0,0H8V1.8H0Z"
          transform="translate(0.938 7.32) rotate(-45)"
          fill="#ffb400"
        />
      </g>
    </g>
  </svg>,
  "DraftInvoices"
);
