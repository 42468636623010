import { RoutingUtils } from "@redriver/cinnamon-mui";
import CurrenciesIcon from "assets/icons/menu/Currencies";
import ExchangeRatesIcon from "assets/icons/menu/ExchangeRate";
import ReadyToInvoiceIcon from "assets/icons/menu/ReadyToInvoice";
import { PermissionCheck } from "components/auth";
import { NavMenu } from "components/navigation";
import { Actions, Targets } from "constants/permissions";
import { FinanceRoutes } from "constants/routes";
import { useMatch } from "react-router-dom";
import DraftInvoicesIcon from "assets/icons/menu/DraftInvoices";
import CommittedInvoicesIcon from "assets/icons/menu/CommittedInvoices";
import CreditNotesIcon from "assets/icons/menu/CreditNotes";

const FinanceNavMenu = ({ children }) => {
  const readyToInvoicePath = RoutingUtils.subRoutesPath(
    FinanceRoutes.ReadyToInvoice
  );
  const currenciesPath = RoutingUtils.subRoutesPath(FinanceRoutes.Currencies);
  const exchangeRatesPath = RoutingUtils.subRoutesPath(
    FinanceRoutes.ExchangeRates
  );
  const draftInvoicesPath = RoutingUtils.subRoutesPath(
    FinanceRoutes.DraftInvoices
  );

  const readyToInvoiceMatch = useMatch(readyToInvoicePath);
  const currenciesMatch = useMatch(currenciesPath);
  const exchangeRatesMatch = useMatch(exchangeRatesPath);
  const draftInvoicesMatch = useMatch(draftInvoicesPath);

  return (
    <PermissionCheck
      action={Actions.View}
      target={[
        Targets.ReadyToInvoice,
        Targets.Currency,
        Targets.ExchangeRate,
        Targets.DraftInvoice,
      ]}
      any
    >
      <NavMenu
        title={children}
        active={
          readyToInvoiceMatch ||
          currenciesMatch ||
          exchangeRatesMatch ||
          draftInvoicesMatch
        }
      >
        <PermissionCheck action={Actions.View} target={Targets.ReadyToInvoice}>
          <NavMenu.Item
            link={FinanceRoutes.ReadyToInvoice}
            icon={<ReadyToInvoiceIcon />}
          >
            Ready To Invoice
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck action={Actions.View} target={Targets.DraftInvoice}>
          <NavMenu.Item
            link={FinanceRoutes.DraftInvoices}
            icon={<DraftInvoicesIcon />}
          >
            Draft Invoices
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck
          action={Actions.View}
          target={Targets.CommittedInvoice}
        >
          <NavMenu.Item
            link={FinanceRoutes.CommittedInvoices}
            icon={<CommittedInvoicesIcon />}
          >
            Committed Invoices
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck action={Actions.View} target={Targets.CreditNote}>
          <NavMenu.Item
            link={FinanceRoutes.CreditNotes}
            icon={<CreditNotesIcon />}
          >
            Credit Notes
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck action={Actions.View} target={Targets.Currency}>
          <NavMenu.Item
            link={FinanceRoutes.Currencies}
            icon={<CurrenciesIcon />}
          >
            Currencies
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck action={Actions.View} target={Targets.ExchangeRate}>
          <NavMenu.Item
            link={FinanceRoutes.ExchangeRates}
            icon={<ExchangeRatesIcon />}
          >
            Exchange Rates
          </NavMenu.Item>
        </PermissionCheck>
      </NavMenu>
    </PermissionCheck>
  );
};

export default FinanceNavMenu;
