import { useState } from "react";
import { Navigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { useLoggedIn } from "@redriver/cinnamon-mui";
import { PasswordForgotten } from "features/System";
import { SystemRoutes } from "constants/routes";
import { SystemPageContent } from "components/pages";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const PasswordForgottenPage = () => {
  const loggedIn = useLoggedIn();
  const [requestSent, setRequestSent] = useState(false);

  if (loggedIn) {
    return <Navigate to={SystemRoutes.ChangePassword} />;
  }

  return (
    <SystemPageContent
      title={
        requestSent ? (
          <Stack flexDirection="row" alignItems="center">
            Reset Email Sent
            <CheckCircleIcon color="success" sx={{ ml: 1 }} />
          </Stack>
        ) : (
          "Reset Password"
        )
      }
    >
      <PasswordForgotten onRequestSent={() => setRequestSent(true)} />
    </SystemPageContent>
  );
};

export default PasswordForgottenPage;
