import { Form } from "@redriver/cinnamon-mui";
import { getClientOffices } from "./actions";

const ClientOfficeTypeAhead = ({ clientId, type, ...props }) => (
  <Form.TypeAhead
    {...props}
    lookupAction={getClientOffices}
    lookupParams={{ clientId, type }}
  />
);

export default ClientOfficeTypeAhead;
