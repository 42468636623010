import { Form } from "@redriver/cinnamon-mui";

const CharacterLimitTrigger = ({ field, limit }) => (
  <Form.Trigger
    field={field}
    event="onChange"
    action="change"
    target={field}
    value={({ fields }) => {
      if (fields[field]?.length > limit) {
        return fields[field].substr(0, limit);
      }
      return fields[field];
    }}
  />
);

export default CharacterLimitTrigger;
