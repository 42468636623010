import { useMatch } from "react-router-dom";
import { RoutingUtils } from "@redriver/cinnamon-mui";
import { PermissionCheck } from "components/auth";
import { NavMenu } from "components/navigation";
import { Actions, Targets } from "constants/permissions";
import { AppRoutes } from "constants/routes";
import ClientsIcon from "assets/icons/menu/Clients";
import ProjectsIcon from "assets/icons/menu/Projects";
import ContractsIcon from "assets/icons/menu/Contracts";

const ClientsNavMenu = ({ children }) => {
  const clientsPath = RoutingUtils.subRoutesPath(AppRoutes.Clients);
  const projectsPath = RoutingUtils.subRoutesPath(AppRoutes.Projects);

  const clientsMatch = useMatch(clientsPath);
  const projectMatch = useMatch(projectsPath);

  return (
    <PermissionCheck
      action={Actions.View}
      target={[Targets.Client, Targets.Project]}
      any
    >
      <NavMenu
        title={children}
        link={AppRoutes.Clients}
        active={clientsMatch || projectMatch}
      >
        <PermissionCheck action={Actions.View} target={Targets.Client}>
          <NavMenu.Item link={AppRoutes.Clients} icon={<ClientsIcon />}>
            Clients
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck action={Actions.View} target={Targets.Project}>
          <NavMenu.Item link={AppRoutes.Projects} icon={<ProjectsIcon />}>
            Projects
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck action={Actions.View} target={Targets.Contract}>
          <NavMenu.Item link={AppRoutes.Contracts} icon={<ContractsIcon />}>
            Contracts
          </NavMenu.Item>
        </PermissionCheck>
      </NavMenu>
    </PermissionCheck>
  );
};

export default ClientsNavMenu;
