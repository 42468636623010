import { Form } from "@redriver/cinnamon-mui";
import { UserType, enumOptionsArray } from "constants/enums";

const userTypeOptions = enumOptionsArray(UserType);

const UserTypeDropdown = (props) => (
  <Form.Dropdown {...props} options={userTypeOptions} />
);

export default UserTypeDropdown;
