import { createTheme } from "@mui/material";
import palette from "./palette";
import breakpoints from "./breakpoints";
import typography from "./typography";
import components from "./components";

export default createTheme({
  palette,
  breakpoints,
  typography,
  components,
});
