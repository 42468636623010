import { Form } from "@redriver/cinnamon-mui";
import { getJobScopes } from "./actions";

const JobScopeTypeAhead = ({
  jobIds = [],
  vendorId,
  excludeResourceId,
  contractId,
  ...props
}) => (
  <Form.TypeAhead
    {...props}
    lookupAction={getJobScopes}
    lookupParams={{ jobIds, vendorId, excludeResourceId, contractId }}
  />
);

export default JobScopeTypeAhead;
