import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 27.004 27.002"
  >
    <g id="logout" transform="translate(0 -0.004)">
      <path
        id="Path_4676"
        data-name="Path 4676"
        d="M41.01,14.63a3.375,3.375,0,0,1,0-6.751h2.25v-4.5A3.379,3.379,0,0,0,39.885,0H27.79A1.124,1.124,0,0,0,26.74,1.53L34.9,22.907a1.123,1.123,0,0,0,1.051.725h3.938a3.379,3.379,0,0,0,3.375-3.375V14.63Zm0,0"
        transform="translate(-25.259)"
        fill="#1d7894"
      />
      <path
        id="Path_4677"
        data-name="Path 4677"
        d="M283.652,117.832a1.127,1.127,0,0,1-.694-1.04v-3.375h-4.5a1.125,1.125,0,1,1,0-2.25h4.5v-3.375a1.125,1.125,0,0,1,1.92-.8l4.5,4.5a1.124,1.124,0,0,1,0,1.591l-4.5,4.5a1.125,1.125,0,0,1-1.226.244Zm0,0"
        transform="translate(-262.706 -101.036)"
        fill="#969696"
      />
      <path
        id="Path_4678"
        data-name="Path 4678"
        d="M9.728,2.431,2.967.178A2.266,2.266,0,0,0,0,2.316V22.568A2.262,2.262,0,0,0,1.524,24.7l6.759,2.254A2.381,2.381,0,0,0,9,27.069a2.252,2.252,0,0,0,2.25-2.25V4.567A2.262,2.262,0,0,0,9.728,2.431Zm0,0"
        transform="translate(0 -0.062)"
        fill="#7edaf7"
      />
    </g>
  </svg>,
  "LogOut"
);
