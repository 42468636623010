import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 27 27"
  >
    <path
      id="Path_4682"
      data-name="Path 4682"
      d="M27,13.5A13.5,13.5,0,1,1,13.5,0,13.466,13.466,0,0,1,27,13.5Zm0,0"
      transform="translate(0 0)"
      fill="#ff8c8c"
    />
    <path
      id="Path_4683"
      data-name="Path 4683"
      d="M269.67,13.5a13.392,13.392,0,0,1-4.458,9.975A13.718,13.718,0,0,1,256,27V0a13.586,13.586,0,0,1,13.67,13.5Zm0,0"
      transform="translate(-242.67)"
      fill="#ff8c8c"
      opacity="0.42"
      style={{ mixBlendMode: "multiply", isolation: "isolate" }}
    />
    <path
      id="Path_4684"
      data-name="Path 4684"
      d="M101.572,280.833v.054a13.236,13.236,0,0,1-18.084,0v-.054A9.22,9.22,0,0,1,89.4,272.19a8.886,8.886,0,0,1,6.253,0,9.22,9.22,0,0,1,5.915,8.644Zm0,0"
      transform="translate(-79.03 -257.458)"
      fill="#207d9a"
    />
    <path
      id="Path_4685"
      data-name="Path 4685"
      d="M265.212,280.833v.054a13.622,13.622,0,0,1-9.212,3.57V271.621a9.211,9.211,0,0,1,9.212,9.212Zm0,0"
      transform="translate(-242.587 -257.458)"
      fill="#207d9a"
      opacity="0.4"
      style={{ mixBlendMode: "multiply", isolation: "isolate" }}
    />
    <path
      id="Path_4686"
      data-name="Path 4686"
      d="M161.491,94.763a5.65,5.65,0,1,1-5.65-5.65A5.656,5.656,0,0,1,161.491,94.763Zm0,0"
      transform="translate(-142.342 -84.456)"
      fill="#ffdba9"
    />
    <path
      id="Path_4687"
      data-name="Path 4687"
      d="M261.65,94.764a5.657,5.657,0,0,1-5.65,5.65v-11.3a5.656,5.656,0,0,1,5.65,5.65Zm0,0"
      transform="translate(-242.545 -84.457)"
      fill="#ffdba9"
      opacity="0.5"
      style={{ mixBlendMode: "multiply", isolation: "isolate" }}
    />
  </svg>,
  "MyAccount"
);
