import { Form } from "@redriver/cinnamon-mui";
import { getVendorContacts } from "./actions";

const VendorContactTypeAhead = ({ vendorId, vendorSiteId, ...props }) => (
  <Form.TypeAhead
    {...props}
    lookupAction={getVendorContacts}
    lookupParams={{ vendorId, vendorSiteId }}
  />
);

export default VendorContactTypeAhead;
