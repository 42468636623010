import { Form } from "@redriver/cinnamon-mui";
import { getJobLocations } from "./actions";

const JobLocationTypeAhead = ({ jobId, ...props }) => (
  <Form.TypeAhead
    {...props}
    lookupAction={getJobLocations}
    lookupParams={{ jobId }}
  />
);

export default JobLocationTypeAhead;
