import { Box, CircularProgress } from "@mui/material";
import { NotificationStack, useAuthClaims } from "@redriver/cinnamon-mui";
import { NotificationItem } from "components/notifications";
import { UserType } from "constants/enums";
import { UpdateUserLastAccessed } from "features/System";
import React from "react";
import EnergyEngineeringMenu from "./EnergyEngineeringMenu";

const InternalRouter = React.lazy(() =>
  import(/* webpackChunkName: "internal" */ "./internal/InternalRouter")
);

const ResourceRouter = React.lazy(() =>
  import(/* webpackChunkName: "resource" */ "./resource/ResourceRouter")
);

const ClientRouter = React.lazy(() =>
  import(/* webpackChunkName: "client" */ "./client/ClientRouter")
);

const RouterLoader = () => (
  <CircularProgress
    color="inherit"
    sx={{ display: "block", margin: "5em auto" }}
  />
);

/**
 * The authenticated app
 */
const EnergyEngineering = () => {
  const { userType } = useAuthClaims();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <UpdateUserLastAccessed />
      <EnergyEngineeringMenu />
      {userType === UserType.Internal && (
        <React.Suspense fallback={<RouterLoader />}>
          <InternalRouter />
        </React.Suspense>
      )}
      {userType === UserType.Resource && (
        <React.Suspense fallback={<RouterLoader />}>
          <ResourceRouter />
        </React.Suspense>
      )}
      {userType === UserType.Client && (
        <React.Suspense fallback={<RouterLoader />}>
          <ClientRouter />
        </React.Suspense>
      )}
      <NotificationStack itemComponent={NotificationItem} />
    </Box>
  );
};

export default EnergyEngineering;
