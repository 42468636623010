import { useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Stack } from "@mui/material";
import { useLoggedIn } from "@redriver/cinnamon-mui";
import { PasswordReset } from "features/System";
import { SystemRoutes } from "constants/routes";
import { SystemPageContent } from "components/pages";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const PasswordResetPage = () => {
  const loggedIn = useLoggedIn();
  const { token } = useParams();
  const [resetSuccess, setResetSuccess] = useState(false);

  if (loggedIn) {
    return <Navigate to={SystemRoutes.ChangePassword} />;
  }

  return (
    <SystemPageContent
      title={
        resetSuccess ? (
          <Stack flexDirection="row" alignItems="center">
            Password Reset
            <CheckCircleIcon color="success" sx={{ ml: 1 }} />
          </Stack>
        ) : (
          "Create New Password"
        )
      }
    >
      <PasswordReset
        token={token}
        onResetSuccess={() => setResetSuccess(true)}
      />
    </SystemPageContent>
  );
};

export default PasswordResetPage;
