import { useMatch } from "react-router-dom";
import { RoutingUtils } from "@redriver/cinnamon-mui";
import { PermissionCheck } from "components/auth";
import { NavMenu } from "components/navigation";
import { AppRoutes, ReportRoutes } from "constants/routes";
import { Actions, Targets } from "constants/permissions";
import EnquiryIcon from "assets/icons/menu/EnquiryReport";
import VisitIcon from "assets/icons/menu/VisitReport";
import ResourceReportIcon from "assets/icons/menu/ResourceReport";
import WorkOrderReportIcon from "assets/icons/menu/WorkOrderReport";
import ContractReportIcon from "assets/icons/menu/ContractReport";

const ReportNavMenu = ({ children }) => {
  const reportPath = RoutingUtils.subRoutesPath(AppRoutes.Reports);
  const reportMatch = useMatch(reportPath);

  return (
    <PermissionCheck action={Actions.View} target={[Targets.EnquiryReport]} any>
      <NavMenu title={children} active={reportMatch}>
        <PermissionCheck action={Actions.View} target={Targets.EnquiryReport}>
          <NavMenu.Item
            link={ReportRoutes.EnquiryReport}
            icon={<EnquiryIcon />}
          >
            Enquiry Report
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck action={Actions.View} target={Targets.VisitReport}>
          <NavMenu.Item link={ReportRoutes.VisitReport} icon={<VisitIcon />}>
            Visit Report
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck action={Actions.View} target={Targets.ResourceReport}>
          <NavMenu.Item
            link={ReportRoutes.ResourceReport}
            icon={<ResourceReportIcon />}
          >
            Resource Report
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck action={Actions.View} target={Targets.WorkOrderReport}>
          <NavMenu.Item
            link={ReportRoutes.WorkOrderReport}
            icon={<WorkOrderReportIcon />}
          >
            Work Order Report
          </NavMenu.Item>
        </PermissionCheck>
        <PermissionCheck action={Actions.View} target={Targets.ContractReport}>
          <NavMenu.Item
            link={ReportRoutes.ContractReport}
            icon={<ContractReportIcon />}
          >
            Contract Report
          </NavMenu.Item>
        </PermissionCheck>
      </NavMenu>
    </PermissionCheck>
  );
};

export default ReportNavMenu;
