import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.831"
    height="28.977"
    viewBox="0 0 20.831 28.977"
  >
    <g id="medal" transform="translate(-71.962 0)">
      <path
        id="Path_4846"
        data-name="Path 4846"
        d="M87.013,20.307l1.325-2.15,2.391-.816.265-2.512,1.8-1.771-.855-2.377L92.793,8.3l-1.8-1.771-.265-2.511-2.391-.816-1.325-2.15-2.506.3L82.378,0,80.249,1.359l-2.506-.3-1.325,2.15-2.391.816-.265,2.511L71.962,8.3l.855,2.377-.855,2.377,1.8,1.771.265,2.512,2.391.816,1.325,2.15,2.506-.3,2.129,1.359L84.507,20Zm-11.96-9.626a7.325,7.325,0,1,1,7.325,7.325,7.333,7.333,0,0,1-7.325-7.325Z"
        fill="#29abd4"
      />
      <path
        id="Path_4847"
        data-name="Path 4847"
        d="M162.216,89.333a5.626,5.626,0,1,0,5.626,5.626A5.632,5.632,0,0,0,162.216,89.333Z"
        transform="translate(-79.838 -84.277)"
        fill="#7edaf7"
      />
      <path
        id="Path_4848"
        data-name="Path 4848"
        d="M87.913,344.914l-2.981.36-1.575-2.556-.535-.183-2.31,7.22,4.158-.229,3.253,2.6,1.914-5.984Z"
        transform="translate(-8.065 -323.149)"
        fill="#207d9a"
      />
      <path
        id="Path_4849"
        data-name="Path 4849"
        d="M273.182,342.718l-1.575,2.556-2.981-.36-1.924,1.228,1.914,5.984,3.253-2.6,4.158.229-2.31-7.22Z"
        transform="translate(-183.718 -323.149)"
        fill="#207d9a"
      />
    </g>
  </svg>,
  "CertificateTypes"
);
