import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Stack, Button, Link, Typography } from "@mui/material";
import { FormBuilder, Form, ErrorMessage } from "@redriver/cinnamon-mui";
import { SystemRoutes } from "constants/routes";
import { SubmitButton } from "components/buttons";
import { requestPasswordForgotten } from "./actions";

const PasswordForgotten = ({ onRequestSent }) => {
  const [requestSent, setRequestSent] = useState(false);

  if (requestSent) {
    return (
      <div>
        <Typography variant="body2" marginBottom={3}>
          Password reset email has been sent, please check your emails for the
          reset instructions.
        </Typography>
        <Button fullWidth component={RouterLink} to={SystemRoutes.Login}>
          Return To Login
        </Button>
      </div>
    );
  }

  return (
    <FormBuilder
      submitAction={requestPasswordForgotten}
      onSubmitted={() => {
        setRequestSent(true);
        if (onRequestSent) onRequestSent();
      }}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { submitting, slowSubmitting, error },
        events
      ) => (
        <Form {...formProps}>
          <Typography variant="body2" marginBottom={3}>
            Please enter your email address associated with this account, you
            will receive an email to reset your password.
          </Typography>
          <Form.Email
            field="email"
            label="Email"
            placeholder="Enter email address"
            disabled={submitting && slowSubmitting}
            autoFocus
            required
            fluid
            inputProps={{ autoComplete: "email" }}
          />
          <Stack>
            <SubmitButton
              variant="contained"
              onClick={events.onSubmit}
              submitting={submitting}
              slowSubmitting={slowSubmitting}
            >
              Reset Password
            </SubmitButton>
            <Typography variant="caption" sx={{ mt: 1, mb: 4 }}>
              &lt;{" "}
              <Link component={RouterLink} to={SystemRoutes.Login}>
                Return to Log In
              </Link>
            </Typography>
            <ErrorMessage error={error} />
          </Stack>
        </Form>
      )}
    />
  );
};

export default PasswordForgotten;
