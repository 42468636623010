import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24.198 33.001"
  >
    <g id="layer1" transform="translate(-2.646 -280.596)">
      <path
        id="path16260-1"
        d="M9.8,289.592a7.147,7.147,0,0,0-7.15,7.15v6.6a.55.55,0,0,0,.548.552H26.3a.55.55,0,0,0,.548-.552v-6.6a7.147,7.147,0,0,0-7.15-7.15Z"
        transform="translate(0 9.705)"
        fill="#29abd4"
      />
      <path
        id="path16263-7"
        d="M2.646,294.883v2.748a.55.55,0,0,0,.548.552H26.3a.55.55,0,0,0,.548-.552v-2.748Z"
        transform="translate(0 15.413)"
        fill="#fdd5a6"
      />
      <g
        id="Group_7564"
        data-name="Group 7564"
        transform="translate(7.596 290.484)"
      >
        <path
          id="path5354"
          d="M6.88,288v3.853a.55.55,0,0,0,.162.39,4.758,4.758,0,0,0,3.138,1.259,4.762,4.762,0,0,0,3.139-1.259.55.55,0,0,0,.158-.39V288Z"
          transform="translate(-3.029 -282.494)"
          fill="#fdce97"
        />
        <path
          id="path16026-2"
          d="M11.51,285.355a1.748,1.748,0,0,0-.927.319v4.089h0a2.732,2.732,0,0,0,2.749-2.749c0-.007,0-.014,0-.021a1.719,1.719,0,0,0-1.3-1.583,1.754,1.754,0,0,0-.524-.055Z"
          transform="translate(0.966 -285.353)"
          fill="#fdce97"
        />
        <path
          id="path16030-2"
          d="M6.85,285.355a1.743,1.743,0,0,0-1.823,1.638c0,.007,0,.014,0,.021a2.732,2.732,0,0,0,2.748,2.749h0V285.67a1.749,1.749,0,0,0-.926-.315Z"
          transform="translate(-5.027 -285.353)"
          fill="#fdce97"
        />
      </g>
      <path
        id="path16017-8"
        d="M6.086,285.094v3.3a4.949,4.949,0,1,0,9.9,0v-3.3Z"
        transform="translate(3.71 4.852)"
        fill="#fdd5a6"
      />
      <path
        id="path16045-6"
        d="M11.606,281.125a6.048,6.048,0,0,0-6.05,6.05h12.1a6.048,6.048,0,0,0-6.05-6.05Z"
        transform="translate(3.139 0.571)"
        fill="#ffd500"
      />
      <path
        id="path16041-8"
        d="M6.676,287.068a1.648,1.648,0,0,1,0-3.3h11a1.648,1.648,0,0,1,0,3.3Z"
        transform="translate(2.568 3.425)"
        fill="#ffb400"
      />
      <path
        id="path16049-37"
        d="M9.062,280.6a1.73,1.73,0,0,0-1.194.475,1.439,1.439,0,0,0-.447,1.267l.548,3.3a.55.55,0,0,0,.544.463h2.2a.55.55,0,0,0,.54-.463l.548-3.281a1.4,1.4,0,0,0-.438-1.283,1.753,1.753,0,0,0-1.2-.475Z"
        transform="translate(5.132)"
        fill="#ffb400"
      />
      <path
        id="rect5359"
        d="M6.942,289.592a7.16,7.16,0,0,0-1.65.2v5.851h2.75v-6.05Zm8.8,0v6.05h2.75v-5.851a7.154,7.154,0,0,0-1.652-.2Z"
        transform="translate(2.854 9.705)"
        fill="#0a67b2"
      />
      <path
        id="path5377"
        d="M6.711,292.237a.55.55,0,0,0-.471.268l-1.665,2.761a.55.55,0,0,0-.077.284v5.487H21V295.55a.549.549,0,0,0-.081-.284l-1.661-2.761a.55.55,0,0,0-.472-.268Z"
        transform="translate(1.998 12.558)"
        fill="#0a67b2"
      />
      <path
        id="path5384"
        d="M7.738,294.355A1.661,1.661,0,0,0,6.084,296v2.75h9.9V296a1.661,1.661,0,0,0-1.653-1.649Z"
        transform="translate(3.709 14.843)"
        fill="#d1dde7"
      />
    </g>
  </svg>,
  "ResourceVisibility"
);
