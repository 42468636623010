import classNames from "classnames";
import { Container, Box, styled } from "@mui/material";

const ContentContainer = ({ className, children, tabs }) => {
  const container = (
    <Container
      maxWidth={false}
      disableGutters
      className={classNames("content-container", className)}
    >
      {children}
    </Container>
  );

  if (!tabs) return container;

  return (
    <Box
      className="tabbed-content-container"
      sx={(theme) => ({
        "> .MuiTabs-root": {
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
          ".MuiTab-root": {
            borderBottom: "none",
            borderLeft: "none",
          },
        },
      })}
    >
      {tabs}
      {container}
    </Box>
  );
};

export default styled(ContentContainer)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  padding: theme.spacing(0, 2),
  "& + .content-container": {
    marginTop: 0,
  },
  "> :not(style) + :not(style)": {
    marginTop: theme.spacing(2),
  },
  "> .MuiTabPanel-root": {
    padding: 0,
    marginTop: 0,
  },
}));
