import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 21.84 21"
  >
    <g id="_Color" data-name=" ↳Color" fill="none" strokeMiterlimit="10">
      <path
        d="M10.92,16.877,17.669,21l-1.791-7.77L21.84,8l-7.851-.674L10.92,0,7.851,7.328,0,8,5.962,13.23,4.171,21Z"
        stroke="none"
      />
      <path
        d="M 16.15981674194336 18.90649032592773 L 14.76898002624512 12.87215995788574 L 19.41591453552246 8.797629356384277 L 13.29991054534912 8.27245044708252 L 10.92000007629395 2.58899974822998 L 8.54008960723877 8.27245044708252 L 2.424086093902588 8.797629356384277 L 7.071020126342773 12.87215995788574 L 5.680182933807373 18.90649032592773 L 10.92000007629395 15.70553970336914 L 16.15981674194336 18.90649032592773 M 17.66856002807617 21 L 10.92000007629395 16.87737083435059 L 4.171440124511719 21 L 5.962319850921631 13.22999954223633 L 7.629394360719743e-08 8.002109527587891 L 7.851480007171631 7.327899932861328 L 10.92000007629395 0 L 13.9885196685791 7.327899932861328 L 21.84000015258789 8.002109527587891 L 15.8776798248291 13.22999954223633 L 17.66856002807617 21 Z"
        stroke="none"
        fill="#979797"
      />
    </g>
  </svg>,
  "StarEmpty"
);
