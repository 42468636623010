import { AppDispatch, authenticate } from "@redriver/cinnamon-mui";
import * as auth from "mortar/endpoints/auth";

export const getTokens = (code: string) =>
  auth.azureAdSignIn({
    queryParams: {
      code,
    },
    options: {
      auth: false,
    },
  });

export const requestLogin =
  ({ code }: { code: string }) =>
  async (dispatch: AppDispatch) => {
    // make the login api call
    const result = await dispatch(getTokens(code));

    const { success, response } = result;
    if (success) {
      // authenticate the user session
      const { accessToken, refreshToken } = response.tokens;
      dispatch(authenticate(accessToken, refreshToken));
    }

    return result;
  };
