import { Form } from "@redriver/cinnamon-mui";
import { CountryDropdown } from "./fields";

const AddressForm = ({ optional = false }) => (
  <Form.Object field="address">
    <Form.Group columns={2}>
      <Form.Input label="Address Line 1" field="addressLine1" fluid />
      <Form.Input label="Address Line 2" field="addressLine2" fluid />
    </Form.Group>
    <Form.Group columns={2}>
      <Form.Input
        label="Town/City"
        field="townOrCity"
        required={!optional}
        fluid
      />
      <Form.Input label="County/State" field="countyOrState" fluid />
    </Form.Group>
    <Form.Group columns={2}>
      <Form.Input label="Postcode/ZIP" field="postcode" fluid />
      <CountryDropdown
        label="Country"
        field="country"
        valueField="countryId"
        required
        fluid
      />
    </Form.Group>
  </Form.Object>
);

export default AddressForm;
