import { useParams } from "react-router-dom";
import { EmailVerification } from "features/System";
import { SystemPageContent } from "components/pages";

const EmailVerificationPage = () => {
  const { token } = useParams();

  return (
    <SystemPageContent title="Verify Email Address">
      <EmailVerification token={token} />
    </SystemPageContent>
  );
};

export default EmailVerificationPage;
