import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 22.015 26.388"
  >
    <g id="taxes" transform="translate(-42.426)">
      <path
        id="Path_5934"
        data-name="Path 5934"
        d="M102.353,26.388H85.575a1.546,1.546,0,0,1-1.546-1.546V1.546A1.546,1.546,0,0,1,85.575,0h16.778A1.546,1.546,0,0,1,103.9,1.546v23.3A1.546,1.546,0,0,1,102.353,26.388Z"
        transform="translate(-39.459 0)"
        fill="#bde0f1"
      />
      <path
        id="Path_5935"
        data-name="Path 5935"
        d="M102.307,0h-.159V23.2a1.593,1.593,0,0,1-1.593,1.593H84.029a1.593,1.593,0,0,0,1.593,1.593h16.685a1.593,1.593,0,0,0,1.593-1.593V1.593A1.593,1.593,0,0,0,102.307,0Z"
        transform="translate(-39.459)"
        fill="#77bee2"
      />
      <path
        id="Path_5936"
        data-name="Path 5936"
        d="M54.637,62.031H43.457A1.031,1.031,0,0,1,42.426,61V55.365a1.031,1.031,0,0,1,1.031-1.031h11.18a1.031,1.031,0,0,1,1.031,1.031V61A1.031,1.031,0,0,1,54.637,62.031Z"
        transform="translate(0 -51.534)"
        fill="#a80a0a"
      />
      <path
        id="Path_5953"
        data-name="Path 5953"
        d="M5.085,0A5.085,5.085,0,1,1,0,5.085,5.085,5.085,0,0,1,5.085,0Z"
        transform="translate(52.252 13.325)"
        fill="#ffb400"
      />
      <g
        id="Group_8018"
        data-name="Group 8018"
        transform="translate(43.84 4.442)"
      >
        <path
          id="Path_5937"
          data-name="Path 5937"
          d="M123.748,86.5l0-.011a.479.479,0,0,0-.444-.3h0a.479.479,0,0,0-.444.3l0,.009-1.358,3.566a.4.4,0,1,0,.744.283l.241-.632h1.632l.238.63a.4.4,0,1,0,.745-.281Zm-.966,2.42.517-1.356.512,1.356Z"
          transform="translate(-118.808 -86.189)"
          fill="#fcf5f5"
        />
        <path
          id="Path_5938"
          data-name="Path 5938"
          d="M207.006,89.974l-1.2-1.708,1.022-1.451a.4.4,0,0,0-.651-.459l-.858,1.218-.856-1.217a.4.4,0,1,0-.651.458l1.021,1.451-1.2,1.708a.4.4,0,1,0,.651.459l1.039-1.474,1.037,1.474a.4.4,0,0,0,.651-.458Z"
          transform="translate(-196.664 -86.188)"
          fill="#fcf5f5"
        />
        <path
          id="Path_5939"
          data-name="Path 5939"
          d="M72.649,86.587a.4.4,0,0,0-.4-.4h-2a.4.4,0,0,0,0,.8h.6V90.2a.4.4,0,0,0,.8,0V86.985h.6A.4.4,0,0,0,72.649,86.587Z"
          transform="translate(-69.855 -86.189)"
          fill="#fcf5f5"
        />
      </g>
      <path
        id="Path_5940"
        data-name="Path 5940"
        d="M337.067,70h-4.6a.4.4,0,1,1,0-.8h4.6a.4.4,0,1,1,0,.8Z"
        transform="translate(-274.71 -65.636)"
        fill="#207d9a"
      />
      <path
        id="Path_5941"
        data-name="Path 5941"
        d="M337.067,108.9h-4.6a.4.4,0,1,1,0-.8h4.6a.4.4,0,1,1,0,.8Z"
        transform="translate(-274.71 -102.534)"
        fill="#207d9a"
      />
      <path
        id="Path_5942"
        data-name="Path 5942"
        d="M337.067,147.8h-4.6a.4.4,0,1,1,0-.8h4.6a.4.4,0,1,1,0,.8Z"
        transform="translate(-274.71 -139.431)"
        fill="#207d9a"
      />
      <path
        id="Path_5943"
        data-name="Path 5943"
        d="M337.067,186.707h-4.6a.4.4,0,0,1,0-.8h4.6a.4.4,0,0,1,0,.8Z"
        transform="translate(-274.71 -176.329)"
        fill="#207d9a"
      />
      <path
        id="Path_5944"
        data-name="Path 5944"
        d="M274.984,306.178a1.3,1.3,0,1,1,.1,0Q275.031,306.178,274.984,306.178Zm0-1.791h-.036a.5.5,0,1,0,.036,0Z"
        transform="translate(-219.347 -287.944)"
        fill="#ffe6aa"
      />
      <path
        id="Path_5945"
        data-name="Path 5945"
        d="M340.886,363.2a1.3,1.3,0,1,1,.1,0C340.949,363.2,340.917,363.2,340.886,363.2Zm0-1.791h-.037a.5.5,0,1,0,.037,0Z"
        transform="translate(-281.853 -342.027)"
        fill="#ffe6aa"
      />
      <path
        id="Path_5946"
        data-name="Path 5946"
        d="M297.637,323.559a.4.4,0,0,1-.3-.659l2.739-3.165a.4.4,0,1,1,.6.521l-2.739,3.165A.4.4,0,0,1,297.637,323.559Z"
        transform="translate(-241.68 -303.125)"
        fill="#ffe6aa"
      />
      <g
        id="Group_8019"
        data-name="Group 8019"
        transform="translate(46.191 12.927)"
      >
        <path
          id="Path_5947"
          data-name="Path 5947"
          d="M121.935,251.61h-6.06a.4.4,0,1,1,0-.8h6.059a.4.4,0,0,1,0,.8Z"
          transform="translate(-115.477 -250.814)"
          fill="#207d9a"
        />
        <path
          id="Path_5948"
          data-name="Path 5948"
          d="M120.663,289.736h-4.787a.4.4,0,1,1,0-.8h4.787a.4.4,0,0,1,0,.8Z"
          transform="translate(-115.478 -286.975)"
          fill="#207d9a"
        />
        <path
          id="Path_5949"
          data-name="Path 5949"
          d="M119.554,327.862h-3.677a.4.4,0,0,1,0-.8h3.677a.4.4,0,0,1,0,.8Z"
          transform="translate(-115.478 -323.136)"
          fill="#207d9a"
        />
        <path
          id="Path_5950"
          data-name="Path 5950"
          d="M119.554,365.988h-3.677a.4.4,0,1,1,0-.8h3.677a.4.4,0,1,1,0,.8Z"
          transform="translate(-115.478 -359.297)"
          fill="#207d9a"
        />
        <path
          id="Path_5951"
          data-name="Path 5951"
          d="M120.663,404.114h-4.787a.4.4,0,1,1,0-.8h4.787a.4.4,0,1,1,0,.8Z"
          transform="translate(-115.478 -395.458)"
          fill="#207d9a"
        />
        <path
          id="Path_5952"
          data-name="Path 5952"
          d="M121.935,442.24h-6.06a.4.4,0,0,1,0-.8h6.059a.4.4,0,0,1,0,.8Z"
          transform="translate(-115.477 -431.619)"
          fill="#207d9a"
        />
      </g>
    </g>
  </svg>,
  "TaxRates"
);
