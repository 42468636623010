import { Form } from "@redriver/cinnamon-mui";
import { getJobReports } from "./actions";

const JobReportTypeAhead = ({ jobId, jobAssignmentId, ...props }) => (
  <Form.TypeAhead
    {...props}
    lookupAction={getJobReports}
    lookupParams={{ jobId, jobAssignmentId }}
  />
);

export default JobReportTypeAhead;
