import { Form } from "@redriver/cinnamon-mui";
import { getResourceRateSets } from "./actions";

const ResourceRateSetTypeAhead = ({ resourceId, ...props }) => (
  <Form.TypeAhead
    {...props}
    lookupAction={getResourceRateSets}
    lookupParams={{ resourceId }}
  />
);

export default ResourceRateSetTypeAhead;
