import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { getAuthState, getAuthClaims } from "@redriver/cinnamon-mui";
import { validateEmailToken } from "./actions";
import { AppRoutes, SystemRoutes } from "constants/routes";
import EmailVerificationResender from "./EmailVerificationResender";

const EmailVerification = ({
  token,
  loggedIn,
  emailVerified,
  validateEmailToken,
}) => {
  const [tokenValid, setTokenValid] = useState(null);

  useEffect(() => {
    const validate = async () => {
      const validationResult = await validateEmailToken(token);
      setTokenValid(validationResult);
    };
    validate();
  }, [token, validateEmailToken]);

  if (emailVerified) {
    return (
      <Typography variant="body2">
        Your email address has already been verified
      </Typography>
    );
  }

  if (!token) {
    return <Typography variant="body2">No email token supplied</Typography>;
  }

  switch (tokenValid) {
    case true:
      return (
        <div>
          <Typography variant="body2" marginBottom={3}>
            Your email address has been verified
          </Typography>
          <Button
            fullWidth
            component={RouterLink}
            to={loggedIn ? AppRoutes.Root : SystemRoutes.Login}
          >
            Continue
          </Button>
        </div>
      );

    case false:
      return (
        <div>
          <Typography variant="body2">
            The email token supplied is invalid or has expired
          </Typography>
          <EmailVerificationResender />
        </div>
      );

    default:
      return <Typography variant="body2">Validating email token...</Typography>;
  }
};

EmailVerification.propTypes = {
  token: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const { loggedIn } = getAuthState(state);
  const { emailVerified } = getAuthClaims(state);
  return {
    loggedIn,
    emailVerified,
  };
};

export default connect(mapStateToProps, { validateEmailToken })(
  EmailVerification
);
