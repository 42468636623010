import classNames from "classnames";
import { Box, Stack, useMediaQuery, useTheme } from "@mui/material";

const AppPageContent = ({ sideMenu, className, children }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("desktop"));

  return (
    <Box
      className={classNames("app-page-content", className)}
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        ...(!sideMenu &&
          isDesktop && {
            "> .header-container > .MuiContainer-root, > .CinLoadable-root > .header-container > .MuiContainer-root, > .CinLoadable-root > .content-container, > .content-container":
              {
                pl: "66px",
                pr: "66px",
              },
          }),
      }}
    >
      {sideMenu ? (
        <Stack
          direction="row"
          sx={(theme) => ({
            height: "100%",
            "> .side-column": {
              minHeight: "100%",
              width: "15em",
              backgroundColor: theme.palette.background.white,
              borderRight: `1px solid ${theme.palette.secondary.main}`,
            },
            "> .side-content": {
              flex: 1,
              display: "flex",
              flexDirection: "column",
              maxWidth: "calc(100% - 15em)",
            },
          })}
        >
          <div className="side-column">{sideMenu}</div>
          <div className="side-content">{children}</div>
        </Stack>
      ) : (
        children
      )}
    </Box>
  );
};

export default AppPageContent;
