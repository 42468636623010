import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 512 512"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M62.2354 0C61.1308 0 60.2354 0.89543 60.2354 2V480.148C60.2354 481.143 60.9672 481.987 61.9525 482.128L165.341 496.897C165.544 496.926 165.75 496.924 165.952 496.89L254.465 482.138C255.368 481.988 256.053 481.244 256.129 480.332L271.059 301.176L256.095 1.90011C256.042 0.835696 255.163 0 254.098 0H62.2354Z"
        fill="#BDE0F1"
      />
      <path
        d="M451.765 2C451.765 0.895431 450.869 0 449.765 0H258C256.895 0 256 0.89543 256 2V480.188C256 481.166 256.707 482 257.671 482.161L346.048 496.89C346.25 496.924 346.456 496.926 346.659 496.897L450.048 482.128C451.033 481.987 451.765 481.143 451.765 480.148V2Z"
        fill="#BDE0F1"
      />
      <path
        d="M120.471 303.177C120.471 302.072 121.366 301.177 122.471 301.177H208.824C209.928 301.177 210.824 302.072 210.824 303.177V389.529C210.824 390.634 209.928 391.529 208.824 391.529H122.471C121.366 391.529 120.471 390.634 120.471 389.529V303.177Z"
        fill="#2C8ED3"
      />
      <path
        d="M256 303.177C256 302.072 256.895 301.177 258 301.177H389.529C390.634 301.177 391.529 302.072 391.529 303.177V329.294C391.529 330.399 390.634 331.294 389.529 331.294H258C256.895 331.294 256 330.399 256 329.294V303.177Z"
        fill="#1B3D5F"
      />
      <path
        d="M256 363.412C256 362.307 256.895 361.412 258 361.412H389.529C390.634 361.412 391.529 362.307 391.529 363.412V389.53C391.529 390.634 390.634 391.53 389.529 391.53H258C256.895 391.53 256 390.634 256 389.53V363.412Z"
        fill="#1B3D5F"
      />
      <path
        d="M331.294 182.706C331.294 181.601 332.189 180.706 333.294 180.706H389.529C390.634 180.706 391.529 181.601 391.529 182.706V208.824C391.529 209.928 390.634 210.824 389.529 210.824H333.294C332.189 210.824 331.294 209.928 331.294 208.824V182.706Z"
        fill="#1B3D5F"
      />
      <path
        d="M331.294 242.941C331.294 241.837 332.189 240.941 333.294 240.941H389.529C390.634 240.941 391.529 241.837 391.529 242.941V269.059C391.529 270.163 390.634 271.059 389.529 271.059H333.294C332.189 271.059 331.294 270.163 331.294 269.059V242.941Z"
        fill="#1B3D5F"
      />
      <path
        d="M331.294 62.2353C331.294 61.1308 332.189 60.2354 333.294 60.2354H389.529C390.634 60.2354 391.529 61.1308 391.529 62.2354V88.353C391.529 89.4576 390.634 90.353 389.529 90.353H333.294C332.189 90.353 331.294 89.4576 331.294 88.353V62.2353Z"
        fill="#1B3D5F"
      />
      <path
        d="M331.294 122.471C331.294 121.366 332.189 120.471 333.294 120.471H389.529C390.634 120.471 391.529 121.366 391.529 122.471V148.588C391.529 149.693 390.634 150.588 389.529 150.588H333.294C332.189 150.588 331.294 149.693 331.294 148.588V122.471Z"
        fill="#1B3D5F"
      />
      <path
        d="M120.471 182.706C120.471 181.601 121.366 180.706 122.471 180.706H178.706C179.811 180.706 180.706 181.601 180.706 182.706V208.823C180.706 209.928 179.811 210.823 178.706 210.823H122.471C121.366 210.823 120.471 209.928 120.471 208.823V182.706Z"
        fill="#1B3D5F"
      />
      <path
        d="M120.471 242.941C120.471 241.837 121.366 240.941 122.471 240.941H178.706C179.811 240.941 180.706 241.837 180.706 242.941V269.059C180.706 270.163 179.811 271.059 178.706 271.059H122.471C121.366 271.059 120.471 270.163 120.471 269.059V242.941Z"
        fill="#1B3D5F"
      />
      <path
        d="M120.471 62.2353C120.471 61.1308 121.366 60.2354 122.471 60.2354H178.706C179.811 60.2354 180.706 61.1308 180.706 62.2354V88.353C180.706 89.4576 179.811 90.353 178.706 90.353H122.471C121.366 90.353 120.471 89.4576 120.471 88.353V62.2353Z"
        fill="#1B3D5F"
      />
      <path
        d="M120.471 122.471C120.471 121.366 121.366 120.471 122.471 120.471H178.706C179.811 120.471 180.706 121.366 180.706 122.471V148.588C180.706 149.693 179.811 150.588 178.706 150.588H122.471C121.366 150.588 120.471 149.693 120.471 148.588V122.471Z"
        fill="#1B3D5F"
      />
      <path
        d="M62.2354 481.882C61.1308 481.882 60.2354 482.778 60.2354 483.882V510C60.2354 511.105 61.1308 512 62.2354 512H255.172C255.702 512 256.211 511.789 256.586 511.414L269.645 498.355C270.426 497.574 270.426 496.308 269.645 495.527L256.586 482.468C256.211 482.093 255.702 481.882 255.172 481.882H62.2354Z"
        fill="#BDE0F1"
      />
      <path
        d="M256 483.882C256 482.778 256.895 481.882 258 481.882H449.765C450.869 481.882 451.765 482.778 451.765 483.882V510C451.765 511.105 450.869 512 449.765 512H258C256.895 512 256 511.105 256 510V483.882Z"
        fill="#BDE0F1"
      />
      <path
        d="M212.823 210.823C211.719 210.823 210.823 211.719 210.823 212.823V238.941C210.823 240.046 211.719 240.941 212.823 240.941H238.941C240.045 240.941 240.941 241.837 240.941 242.941V269.059C240.941 270.163 241.836 271.059 242.941 271.059H254.599C255.44 271.059 256.191 270.533 256.478 269.742L265.81 244.081C265.958 243.672 265.97 243.226 265.842 242.81L256.434 212.235C256.176 211.396 255.401 210.823 254.523 210.823H212.823Z"
        fill="#FFB400"
      />
      <path
        d="M240.941 135.529C240.941 127.712 246.93 121.266 254.56 120.539C255.417 120.457 256.183 119.92 256.456 119.103L265.828 90.9855C265.965 90.5749 265.965 90.1311 265.828 89.7205L256.456 61.6029C256.183 60.7862 255.419 60.2354 254.558 60.2354H242.941C241.836 60.2354 240.941 61.1308 240.941 62.2354V91.5304C240.941 92.3772 240.405 93.1284 239.617 93.4364C222.783 100.011 210.823 116.396 210.823 135.529C210.823 160.082 230.511 180.121 254.926 180.693C255.595 180.709 256.223 180.372 256.593 179.815L265.299 166.757C265.747 166.085 265.747 165.21 265.299 164.538L256.593 151.479C256.223 150.923 255.596 150.598 254.929 150.551C247.124 150 240.941 143.473 240.941 135.529Z"
        fill="#FFB400"
      />
      <path
        d="M122.471 421.647C121.366 421.647 120.471 422.542 120.471 423.647V449.765C120.471 450.869 121.366 451.765 122.471 451.765H255.172C255.702 451.765 256.211 451.554 256.586 451.179L269.645 438.12C270.426 437.339 270.426 436.073 269.645 435.292L256.586 422.233C256.211 421.858 255.702 421.647 255.172 421.647H122.471Z"
        fill="#1B3D5F"
      />
      <path
        d="M256 423.647C256 422.542 256.895 421.647 258 421.647H389.529C390.634 421.647 391.529 422.542 391.529 423.647V449.765C391.529 450.869 390.634 451.765 389.529 451.765H258C256.895 451.765 256 450.869 256 449.765V423.647Z"
        fill="#1B3D5F"
      />
      <path
        d="M271.059 239.764C271.059 238.917 271.594 238.166 272.383 237.858C289.217 231.284 301.176 214.898 301.176 195.765C301.176 171.526 281.988 151.682 258.001 150.632C256.898 150.584 256 151.484 256 152.588V178.706C256 179.81 256.9 180.692 257.995 180.837C265.36 181.816 271.059 188.138 271.059 195.765C271.059 203.392 265.36 209.713 257.995 210.692C256.9 210.837 256 211.719 256 212.823V269.059C256 270.163 256.895 271.059 258 271.059H269.059C270.163 271.059 271.059 270.163 271.059 269.059V239.764Z"
        fill="#FFB400"
      />
      <path
        d="M299.176 120.471C300.281 120.471 301.176 119.575 301.176 118.471V92.353C301.176 91.2484 300.281 90.353 299.176 90.353H273.059C271.954 90.353 271.059 89.4576 271.059 88.353V62.2353C271.059 61.1308 270.163 60.2354 269.059 60.2354H258C256.895 60.2354 256 61.1308 256 62.2354V118.471C256 119.575 256.895 120.471 258 120.471H299.176Z"
        fill="#FFB400"
      />
    </g>
    <defs>
      <clipPath id="clip0_1757_522">
        <rect width="512" height="512" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  "CreditNotes"
);
