import { styled } from "@mui/material";
import { NotificationStackItem } from "@redriver/cinnamon-mui";
import { Link as RouterLink } from "react-router-dom";
import { SystemRoutes } from "constants/routes";

const NotificationItem = ({ event, onDismiss, ...props }) => {
  const { customData } = event;
  const { actionId } = customData ?? {};
  return (
    <NotificationStackItem
      {...props}
      event={event}
      onDismiss={(e) => {
        e.preventDefault();
        onDismiss && onDismiss();
      }}
      component={actionId ? RouterLink : undefined}
      to={actionId ? SystemRoutes.Actions : undefined}
      state={actionId ? { actionId } : undefined}
    />
  );
};

export default styled(NotificationItem)({
  textDecoration: "none",
  ".MuiAlert-message": {
    whiteSpace: "pre-wrap",
    fontWeight: "400",
    opacity: 1,
  },
});
