import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 26 35"
  >
    <g id="doc" transform="translate(-99.759 0)">
      <g id="x0020_1_4_" transform="translate(99.759 0)">
        <path
          id="Path_4823"
          data-name="Path 4823"
          d="M104.281,0h11.632l9.846,10.342v20.1A4.538,4.538,0,0,1,121.237,35H104.281a4.538,4.538,0,0,1-4.522-4.553V4.553A4.538,4.538,0,0,1,104.281,0Z"
          transform="translate(-99.759 0)"
          fill="#0a67b2"
        />
        <g
          id="Group_7538"
          data-name="Group 7538"
          transform="translate(3.498 0)"
        >
          <path
            id="Path_4824"
            data-name="Path 4824"
            d="M467.212,0V10.516h10.177Z"
            transform="translate(-454.887)"
            fill="#fff"
            fillRule="evenodd"
            opacity="0.302"
          />
          <path
            id="Path_4825"
            data-name="Path 4825"
            d="M195.356,438.224v-5.94h2.1a3.836,3.836,0,0,1,1.175.179,3.233,3.233,0,0,1,.987.527,2.356,2.356,0,0,1,.7.929,3.6,3.6,0,0,1,0,2.67,2.356,2.356,0,0,1-.7.929,3.234,3.234,0,0,1-.987.527,3.835,3.835,0,0,1-1.175.179Zm1.485-1.291h.44a2.549,2.549,0,0,0,.658-.082,1.881,1.881,0,0,0,.561-.271,1.177,1.177,0,0,0,.406-.522,1.934,1.934,0,0,0,.15-.8,1.975,1.975,0,0,0-.15-.808,1.189,1.189,0,0,0-.406-.518,1.774,1.774,0,0,0-.561-.271,2.55,2.55,0,0,0-.658-.082h-.44Zm7.338,1.359a3.038,3.038,0,0,1-2.22-.866,3.131,3.131,0,0,1,0-4.344,3.038,3.038,0,0,1,2.22-.866,3.005,3.005,0,0,1,2.2.866,3.144,3.144,0,0,1,0,4.344A3.005,3.005,0,0,1,204.179,438.291Zm-1.142-1.785a1.539,1.539,0,0,0,2.259,0,2,2,0,0,0,0-2.506,1.539,1.539,0,0,0-2.259,0,1.986,1.986,0,0,0,0,2.506Zm7.739,1.785a3.046,3.046,0,0,1-2.157-.8,3.311,3.311,0,0,1,.01-4.465,3.054,3.054,0,0,1,2.148-.808,3.018,3.018,0,0,1,1.9.571,2.134,2.134,0,0,1,.851,1.509l-1.5.3a1.343,1.343,0,0,0-.445-.793,1.215,1.215,0,0,0-.813-.3,1.394,1.394,0,0,0-1.064.455,2.155,2.155,0,0,0,0,2.578,1.385,1.385,0,0,0,1.069.46,1.233,1.233,0,0,0,.793-.261,1.146,1.146,0,0,0,.411-.7l1.533.348a2.254,2.254,0,0,1-.934,1.4A3.068,3.068,0,0,1,210.776,438.291Z"
            transform="translate(-195.356 -412.621)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>,
  "Doc"
);
