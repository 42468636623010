import {
  apiPatch,
  apiPost,
  refreshJwt,
  getAuthState,
} from "@redriver/cinnamon-mui";

const NAMESPACE = "SYSTEM/EMAIL_VERIFICATION";
export const VALIDATE_TOKEN = `${NAMESPACE}/VALIDATE`;
export const RESEND_VERIFICATION = `${NAMESPACE}/RESEND`;

export const validateEmailToken =
  (token: string) => async (dispatch: any, getState: any) => {
    // make the validation api call
    const validateAction = apiPatch(
      VALIDATE_TOKEN,
      `auth/email-tokens/${token}`,
      { isUsed: true },
      { auth: false }
    );
    const { success } = await dispatch(validateAction);

    // user may be logged in already
    // refresh session jwt to update the email verified state
    const { loggedIn } = getAuthState(getState());
    if (success && loggedIn) {
      try {
        await dispatch(refreshJwt());
      } catch (error) {}
    }

    return success;
  };

export const resendVerification = ({ email }: { email: string }) =>
  apiPost(
    RESEND_VERIFICATION,
    `auth/email-tokens`,
    { email },
    { auth: false, preventErrorNotification: true }
  );
