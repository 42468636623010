import { Form } from "@redriver/cinnamon-mui";
import { getVendorSites } from "./actions";

const VendorSiteTypeAhead = ({ vendorId, ...props }) => (
  <Form.TypeAhead
    {...props}
    lookupAction={getVendorSites}
    lookupParams={{ vendorId }}
    lookupOptions={{
      cacheExpiry: 60 * 1000,
    }}
  />
);

export default VendorSiteTypeAhead;
