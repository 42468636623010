import { AppBar, Toolbar, Box, useMediaQuery } from "@mui/material";
import { useAuthClaims } from "@redriver/cinnamon-mui";
import { NavButton } from "components/navigation";
import { UserType } from "constants/enums";
import { AppRoutes } from "constants/routes";
import LogoMenu from "assets/logos/ee-logo.png";
import ClientsNavMenu from "./ClientsNavMenu";
import JobsNavMenu from "./JobsNavMenu";
import ResourcesNavMenu from "./ResourcesNavMenu";
import FinanceNavMenu from "./FinanceNavMenu";
import AdminNavMenu from "./AdminNavMenu";
import AccountNavMenu from "./AccountNavMenu";
import GlobalSearch from "./GlobalSearch";
import ActionsNavButton from "./ActionsNavButton";
import { PermissionCheck } from "components/auth";
import { Actions, Targets } from "constants/permissions";
import ReportNavMenu from "./ReportsNavMenu";

const EnergyEngineeringMenu = () => {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up("desktop"));
  const { userType } = useAuthClaims();

  return (
    <AppBar position="static" color="menu" sx={{ boxShadow: "none" }}>
      <Toolbar
        sx={{
          minHeight: "100px !important",
          pl: `${isDesktop ? "50px" : 0} !important`,
          pr: `${isDesktop ? "50px" : 0} !important`,
          "> .logo": {
            height: "70px",
            ml: 2,
            mr: 4,
            borderRadius: 2,
          },
        }}
      >
        <img src={LogoMenu} alt="EnergyEngineering" className="logo" />
        <Box
          sx={{
            flexGrow: 1,
            flexWrap: "wrap",
            display: "flex",
            alignSelf: "stretch",
            alignItems: "stretch",
            justifyContent: "center",
            "> .center": {
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              mx: "auto",
            },
          }}
        >
          {userType === UserType.Internal ? (
            <div className="center">
              <ClientsNavMenu>Clients</ClientsNavMenu>
              <PermissionCheck action={Actions.View} target={Targets.Enquiry}>
                <NavButton link={AppRoutes.Enquiries}>Enquiries</NavButton>
              </PermissionCheck>
              <JobsNavMenu>Jobs</JobsNavMenu>
              <ResourcesNavMenu>Resources</ResourcesNavMenu>
              <PermissionCheck action={Actions.View} target={Targets.Vendor}>
                <NavButton link={AppRoutes.Vendors}>Vendors</NavButton>
              </PermissionCheck>
              <FinanceNavMenu>Finance</FinanceNavMenu>
              <ReportNavMenu>Reports</ReportNavMenu>
              <AdminNavMenu>Admin</AdminNavMenu>
            </div>
          ) : userType === UserType.Resource ? (
            <div className="center">
              <NavButton link={AppRoutes.Dashboard}>Dashboard</NavButton>
              <NavButton link={AppRoutes.Profile}>My Profile</NavButton>
            </div>
          ) : userType === UserType.Client ? (
            <div className="center">
              <NavButton link={AppRoutes.Dashboard}>Dashboard</NavButton>
              {/* <NavButton link={AppRoutes.Enquiries}>Enquiries</NavButton>
              <NavButton link={AppRoutes.Jobs}>Jobs</NavButton> */}
            </div>
          ) : null}
        </Box>
        {userType === UserType.Internal && (
          <Box sx={{ display: "flex", alignItems: "center", mr: 1.5 }}>
            <GlobalSearch />
          </Box>
        )}
        {userType === UserType.Internal && <ActionsNavButton />}
        <AccountNavMenu />
      </Toolbar>
    </AppBar>
  );
};

export default EnergyEngineeringMenu;
