import { Avatar } from "@mui/material";
import { blueGrey } from "@mui/material/colors";

const UserIcon = ({ firstName, lastName, disabled }) => {
  const initials = firstName[0] + lastName[0];
  return (
    <Avatar
      sx={{
        bgcolor: disabled ? blueGrey[300] : blueGrey[800],
      }}
    >
      {initials}
    </Avatar>
  );
};

export default UserIcon;
