import classNames from "classnames";
import {
  Link as RouterLink,
  useMatch,
  useResolvedPath,
} from "react-router-dom";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import { RoutingUtils } from "@redriver/cinnamon-mui";

const SideMenuItem = ({ link, exact = true, icon, className, children }) => {
  const linkPath = exact ? link : RoutingUtils.subRoutesPath(link);
  const resolved = useResolvedPath(linkPath);
  const linkMatch = useMatch({ path: resolved.pathname });

  return (
    <ListItemButton
      component={RouterLink}
      to={link}
      selected={!!linkMatch}
      className={classNames(className, {
        active: link && linkMatch,
      })}
    >
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText>{children}</ListItemText>
    </ListItemButton>
  );
};

export default styled(SideMenuItem)(({ theme }) => ({
  padding: "0.6rem 1.5rem",
  transition: "none",
  ".MuiListItemText-primary": {
    fontSize: "1.15rem",
  },
  "&.active": {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    transition: "background-color linear 150ms",
  },
  "&:hover, &.active:hover": {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
  },
}));
