import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 32 22"
  >
    <g id="group" transform="translate(0.001)">
      <path
        id="Path_531"
        data-name="Path 531"
        d="M263.753,135.083a9.2,9.2,0,0,0-15.832,0,3.706,3.706,0,0,0-.028,3.766,3.748,3.748,0,0,0,3.292,1.916h9.3a3.749,3.749,0,0,0,3.292-1.916A3.707,3.707,0,0,0,263.753,135.083Zm0,0"
        transform="translate(-232.283 -122.657)"
        fill="#00a14b"
      />
      <path
        id="Path_532"
        data-name="Path 532"
        d="M384.317,131.8a9.191,9.191,0,0,0-4.622-1.244h0v10.194h4.652a3.748,3.748,0,0,0,3.292-1.916,3.706,3.706,0,0,0-.028-3.766,9.248,9.248,0,0,0-3.294-3.269Zm0,0"
        transform="translate(-356.14 -122.639)"
        fill="#00a14b"
        opacity="0.5"
        style={{ mixBlendMode: "multiply", isolation: "isolate" }}
      />
      <path
        id="Path_533"
        data-name="Path 533"
        d="M307.59,1.513a5.165,5.165,0,1,0-7.3,7.3,5.165,5.165,0,1,0,7.3-7.3Zm0,0"
        transform="translate(-280.383)"
        fill="#ffd2bf"
      />
      <path
        id="Path_534"
        data-name="Path 534"
        d="M383.348,1.53A5.149,5.149,0,0,0,379.7.019V10.344h0a5.162,5.162,0,0,0,3.652-8.814Zm0,0"
        transform="translate(-356.141 -0.018)"
        fill="#ffb89c"
      />
      <path
        id="Path_535"
        data-name="Path 535"
        d="M16.358,135.083a9.2,9.2,0,0,0-15.832,0A3.706,3.706,0,0,0,.5,138.848a3.749,3.749,0,0,0,3.292,1.916h9.3a3.748,3.748,0,0,0,3.292-1.916,3.707,3.707,0,0,0-.028-3.766Zm0,0"
        transform="translate(0 -122.657)"
        fill="#ec5705"
      />
      <path
        id="Path_536"
        data-name="Path 536"
        d="M136.923,131.8a9.192,9.192,0,0,0-4.622-1.244h0v10.194h4.652a3.748,3.748,0,0,0,3.292-1.916,3.706,3.706,0,0,0-.028-3.766,9.248,9.248,0,0,0-3.294-3.269Zm0,0"
        transform="translate(-124.094 -122.639)"
        fill="#ec5705"
        opacity="0.49"
        style={{ mixBlendMode: "multiply", isolation: "isolate" }}
      />
      <path
        id="Path_537"
        data-name="Path 537"
        d="M60.195,1.513a5.165,5.165,0,1,0-7.3,7.3,5.165,5.165,0,1,0,7.3-7.3Zm0,0"
        transform="translate(-48.101)"
        fill="#ffd2bf"
      />
      <path
        id="Path_538"
        data-name="Path 538"
        d="M135.953,1.53A5.149,5.149,0,0,0,132.3.019V10.344h0a5.162,5.162,0,0,0,3.652-8.814Zm0,0"
        transform="translate(-124.066 -0.018)"
        fill="#ffb89c"
      />
      <path
        id="Path_539"
        data-name="Path 539"
        d="M139.4,199.314a9.066,9.066,0,0,0-3.162-3.269,8.532,8.532,0,0,0-8.873,0,9.067,9.067,0,0,0-3.162,3.269,3.84,3.84,0,0,0-.027,3.766,3.576,3.576,0,0,0,3.16,1.916h8.93a3.576,3.576,0,0,0,3.16-1.916A3.84,3.84,0,0,0,139.4,199.314Zm0,0"
        transform="translate(-115.804 -182.995)"
        fill="#0a67b2"
      />
      <path
        id="Path_540"
        data-name="Path 540"
        d="M260.618,196.041A9.192,9.192,0,0,0,256,194.8h0v10.194h4.652a3.749,3.749,0,0,0,3.292-1.916,3.706,3.706,0,0,0-.028-3.766,9.247,9.247,0,0,0-3.294-3.269Zm0,0"
        transform="translate(-240.115 -182.991)"
        fill="#0a67b2"
        opacity="0.29"
        style={{ mixBlendMode: "multiply", isolation: "isolate" }}
      />
      <path
        id="Path_541"
        data-name="Path 541"
        d="M183.929,65.777a5.162,5.162,0,1,0,0,7.3A5.171,5.171,0,0,0,183.929,65.777Zm0,0"
        transform="translate(-164.278 -60.374)"
        fill="#ffd2bf"
      />
      <path
        id="Path_542"
        data-name="Path 542"
        d="M259.649,65.776A5.149,5.149,0,0,0,256,64.266V74.59h0a5.162,5.162,0,0,0,3.652-8.814Zm0,0"
        transform="translate(-240.061 -60.373)"
        fill="#ffb89c"
      />
    </g>
  </svg>,
  "Agencies"
);
