import { Form } from "@redriver/cinnamon-mui";

const CustomFieldForm = ({ formProps, canEdit = true }) => (
  <Form {...formProps}>
    <Form.Group columns={2}>
      <Form.Input
        label="Label"
        field="label"
        disabled={!canEdit}
        required
        fluid
      />
      <Form.Input
        label="Description"
        field="description"
        disabled={!canEdit}
        fluid
      />
    </Form.Group>
    <Form.Group columns={2}>
      <Form.Input label="Value" field="value" required fluid />
    </Form.Group>
  </Form>
);

export default CustomFieldForm;
