import { Format } from "@redriver/cinnamon-mui";
import IconLabel from "./IconLabel";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const HoursRemainingLabel = ({
  value,
  successThreshold = 0,
  warningThreshold = 0,
  ...props
}) => (
  <IconLabel
    icon={<AccessTimeIcon />}
    value={<Format.Number value={value} decimalPlaces={0} suffix=" hrs" />}
    color={
      value > successThreshold
        ? "success"
        : value > warningThreshold
        ? "warning"
        : "error"
    }
    {...props}
  />
);

export default HoursRemainingLabel;
