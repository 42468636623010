import { Form } from "@redriver/cinnamon-mui";
import { getJobAssignments } from "./actions";

const JobAssignmentTypeAhead = ({
  jobScopeId,
  excludeFutureVisits = false,
  ...props
}) => (
  <Form.TypeAhead
    {...props}
    lookupAction={getJobAssignments}
    lookupParams={{ jobScopeId, excludeFutureVisits }}
  />
);

export default JobAssignmentTypeAhead;
