import * as mortar from "mortar/endpoints/auth";

export const requestPasswordForgotten = ({ email }: { email: string }) =>
  mortar.sendResetLink({
    request: { email },
    options: {
      auth: false,
      preventErrorNotification: true,
    },
  });
