import React from "react";
import classNames from "classnames";
import {
  Link as RouterLink,
  useResolvedPath,
  useMatch,
} from "react-router-dom";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  styled,
} from "@mui/material";
import { RoutingUtils } from "@redriver/cinnamon-mui";

const BasicTile = ({
  active,
  link,
  exact = true,
  onClick,
  footer,
  children,
  className,
}) => {
  const linkPath = exact ? link : RoutingUtils.subRoutesPath(link);
  const resolved = useResolvedPath(linkPath);
  const linkMatch = useMatch({ path: resolved.pathname });

  const content = (
    <React.Fragment>
      <CardContent>{children}</CardContent>
      <CardActions>{footer}</CardActions>
    </React.Fragment>
  );

  return (
    <Card
      className={classNames(className, {
        active: active !== undefined ? active : link && linkMatch,
      })}
    >
      {onClick ? (
        <CardActionArea onClick={onClick}>{content}</CardActionArea>
      ) : link ? (
        <CardActionArea LinkComponent={RouterLink} to={link}>
          {content}
        </CardActionArea>
      ) : (
        content
      )}
    </Card>
  );
};

export default styled(BasicTile)(({ theme }) => ({
  ".MuiCardActions-root": {
    display: "grid",
    gridGap: "0.25em",
    padding: "0.75em 1rem",
    backgroundColor: theme.palette.background.info,
    "> :not(:first-of-type)": {
      marginLeft: 0,
    },
    ".icon-label": {
      "> .MuiSvgIcon-root": {
        color: theme.palette.secondary.main,
      },
      ".value": {
        fontWeight: "normal",
      },
    },
  },
  "&.active": {
    outline: `3px ${theme.palette.secondary.main} solid`,
  },
}));
