import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24.449"
    height="33"
    viewBox="0 0 24.449 33"
  >
    <g id="good-review" transform="translate(-0.996 -5)">
      <path
        id="Path_4830"
        data-name="Path 4830"
        d="M16.2,39.28,21.386,41a5.5,5.5,0,0,1,3.772,4.852l.285,4.18a1.229,1.229,0,0,1-1.233,1.3H2.232A1.229,1.229,0,0,1,1,50.029l.283-4.18A5.5,5.5,0,0,1,5.053,41l5.181-1.717Z"
        transform="translate(0 -13.331)"
        fill="#ffb400"
      />
      <path
        id="Path_4833"
        data-name="Path 4833"
        d="M24.111,17.222V13.556A8.556,8.556,0,1,0,7,13.556v3.667Z"
        transform="translate(-2.335)"
        fill="#66453a"
      />
      <path
        id="Path_4834"
        data-name="Path 4834"
        d="M21.916,37.56l.183,1.827c.006.1.012.189.012.287a3.056,3.056,0,1,1-6.111,0c0-.1.006-.189.012-.287L16.2,37.56Z"
        transform="translate(-5.835 -12.662)"
        fill="#8f5c4a"
      />
      <path
        id="Path_4835"
        data-name="Path 4835"
        d="M8.711,19.891a1.711,1.711,0,0,0,0,3.422l.3,1.808a4.273,4.273,0,0,0,1.822,2.844c1.158.781,2.853,2.091,4.72,2.091s3.562-1.31,4.72-2.091A4.273,4.273,0,0,0,22.1,25.121l.306-1.808a1.711,1.711,0,0,0,0-3.422A2.132,2.132,0,0,0,20.8,17.663a12.051,12.051,0,0,1-4.744-1.518.91.91,0,0,0-.985,0,12.051,12.051,0,0,1-4.744,1.518,2.132,2.132,0,0,0-1.611,2.228Z"
        transform="translate(-2.335 -4.278)"
        fill="#a6725c"
      />
    </g>
  </svg>,
  "ClientVisibility"
);
