import { DateTime } from "luxon";

const LocalDate = ({ value, format = "dd-MMM-yyyy" }) => {
  const dateTime = value
    ? DateTime.fromFormat(value, "yyyy-MM-dd", {
        zone: "local",
      }).startOf("day")
    : null;
  const formattedDate = dateTime?.toFormat(format);

  return <span>{formattedDate}</span>;
};

export default LocalDate;
