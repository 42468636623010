import { Form } from "@redriver/cinnamon-mui";
import { getJobAssignmentVisits } from "./actions";

const JobAssignmentVisitsDropdown = ({
  jobAssignmentId,
  maxVisitDate,
  jobReportId,
  documentCategoryId,
  ...props
}) => (
  <Form.Dropdown
    {...props}
    lookupAction={getJobAssignmentVisits}
    lookupParams={{
      jobAssignmentId,
      maxVisitDate,
      jobReportId,
      documentCategoryId,
    }}
  />
);

export default JobAssignmentVisitsDropdown;
