import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 18 18"
    fill="currentColor"
  >
    <g id="bin" transform="scale(0.85) translate(-29, 1)">
      <g
        id="Group_7155"
        data-name="Group 7155"
        transform="translate(32.221 5.871)"
      >
        <g id="Group_7154" data-name="Group 7154">
          <path
            id="Path_4713"
            data-name="Path 4713"
            d="M62.205,150l1.04,12.554A1.771,1.771,0,0,0,65,164.169h9.582a1.771,1.771,0,0,0,1.754-1.615L77.376,150Zm4.649,11.821a.587.587,0,0,1-.585-.55l-.587-9.472a.587.587,0,0,1,.549-.623.578.578,0,0,1,.623.549l.587,9.472A.587.587,0,0,1,66.854,161.821Zm3.523-.587a.587.587,0,0,1-1.174,0v-9.472a.587.587,0,0,1,1.174,0ZM73.9,151.8l-.587,9.472a.587.587,0,0,1-1.172-.073l.587-9.472a.6.6,0,0,1,.623-.549A.587.587,0,0,1,73.9,151.8Z"
            transform="translate(-62.205 -150)"
          />
        </g>
      </g>
      <g id="Group_7157" data-name="Group 7157" transform="translate(31)">
        <g id="Group_7156" data-name="Group 7156" transform="translate(0)">
          <path
            id="Path_4714"
            data-name="Path 4714"
            d="M47.439,2.348H43.917V1.761A1.763,1.763,0,0,0,42.155,0h-4.7A1.763,1.763,0,0,0,35.7,1.761v.587H32.174a1.174,1.174,0,1,0,0,2.348H47.439a1.174,1.174,0,0,0,0-2.348Zm-4.7,0H36.871V1.761a.588.588,0,0,1,.587-.587h4.7a.588.588,0,0,1,.587.587Z"
            transform="translate(-31)"
          />
        </g>
      </g>
    </g>
  </svg>,
  "Delete"
);
