import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24.363 24.515"
  >
    <g id="Commodities" transform="translate(-0.096)">
      <path
        id="settings"
        d="M12.357,7.991V0H9.719A.658.658,0,0,0,9.1.543L8.633,3.8A7.127,7.127,0,0,0,6.538,5.043L3.435,3.8a.7.7,0,0,0-.776.233L.177,8.223A.6.6,0,0,0,.332,9L2.97,11.016a5.872,5.872,0,0,0-.078,1.164,7.1,7.1,0,0,0,.078,1.241L.332,15.438a.6.6,0,0,0-.155.776l2.483,4.267a.6.6,0,0,0,.776.233l3.1-1.241a11.453,11.453,0,0,0,2.095,1.241L9.1,23.972a.59.59,0,0,0,.621.465h2.638V16.447H12.2a4.229,4.229,0,0,1,.155-8.456Z"
        transform="translate(0)"
        fill="#29abd4"
      />
      <path
        id="Path_4840"
        data-name="Path 4840"
        d="M27.947,16.214l-2.483,4.267a.6.6,0,0,1-.776.233l-3.1-1.241a11.453,11.453,0,0,1-2.095,1.241l-.465,3.258a.658.658,0,0,1-.621.543H16V16.447a4.357,4.357,0,0,0,4.267-4.267,4.276,4.276,0,0,0-4.112-4.267h-.078V0h2.25a.658.658,0,0,1,.621.543L19.413,3.8a7.127,7.127,0,0,1,2.095,1.241l3.1-1.241a.7.7,0,0,1,.776.233L27.87,8.3a.6.6,0,0,1-.155.776l-2.638,2.017a5.872,5.872,0,0,1,.078,1.164,5.872,5.872,0,0,1-.078,1.164l2.638,2.017A.569.569,0,0,1,27.947,16.214Z"
        transform="translate(-3.566)"
        fill="#227a96"
      />
    </g>
  </svg>,
  "Commodities"
);
