import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 27 27"
  >
    <g
      id="magnifying-glass_1_"
      data-name="magnifying-glass (1)"
      transform="translate(0 -0.011)"
    >
      <g id="Group_167" data-name="Group 167" transform="translate(0 4.78)">
        <path
          id="Path_527"
          data-name="Path 527"
          d="M.714,122.242a2.439,2.439,0,0,1,0-3.45l17.831-17.831a.811.811,0,0,1,1.147,0l2.3,2.3a.811.811,0,0,1,0,1.147L4.164,122.242a2.442,2.442,0,0,1-3.45,0Z"
          transform="translate(0 -100.724)"
          fill="#464b52"
        />
      </g>
      <path
        id="Path_528"
        data-name="Path 528"
        d="M34.5,127.57a.811.811,0,0,1,0,1.147L16.667,146.547a2.442,2.442,0,0,1-3.45,0l20.129-20.129Z"
        transform="translate(-12.503 -120.249)"
        fill="#292b2f"
      />
      <circle
        id="Ellipse_8"
        data-name="Ellipse 8"
        cx="10.581"
        cy="10.581"
        r="10.581"
        transform="translate(5.837 0.011)"
        fill="#76cefb"
      />
      <path
        id="Path_529"
        data-name="Path 529"
        d="M192.856,57.409A10.577,10.577,0,1,1,177.9,72.366Z"
        transform="translate(-168.959 -54.295)"
        fill="#6897f4"
      />
      <circle
        id="Ellipse_9"
        data-name="Ellipse 9"
        cx="7.325"
        cy="7.325"
        r="7.325"
        transform="translate(9.094 3.268)"
        fill="#e1faff"
      />
      <path
        id="Path_530"
        data-name="Path 530"
        d="M225.659,112.494a7.3,7.3,0,0,1-5.177-2.148l10.354-10.354a7.321,7.321,0,0,1-5.177,12.5Z"
        transform="translate(-209.24 -94.818)"
        fill="#c7f5ff"
      />
    </g>
  </svg>,
  "QuickSearch"
);
