import { useApiAction } from "@redriver/cinnamon-mui";
import { updateLastAccessed } from "./actions";

const UpdateUserLastAccessed = () => {
  useApiAction(updateLastAccessed, {
    lazy: false,
  });

  return null;
};

export default UpdateUserLastAccessed;
