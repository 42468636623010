import { ThemeOptions } from "@mui/material";
import "@fontsource/audiowide";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    title: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    title: React.CSSProperties;
  }
}

const typography: ThemeOptions["typography"] = (palette) => ({
  fontSize: 14,
  fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
  title: {
    fontFamily: `"Audiowide"`,
    fontSize: 66,
    color: palette.primary.main,
    display: "block",
  },
  subtitle1: {
    fontWeight: 500,
    marginBottom: "1em",
  },
  h6: {
    fontWeight: 400,
  },
});

export default typography;
