import classNames from "classnames";
import { Typography, styled } from "@mui/material";

const IconLabel = ({
  value,
  icon,
  endIcon,
  prefix,
  suffix,
  fallback = "",
  className,
}) => (
  <div className={classNames("icon-label", className)}>
    {prefix && <Typography className="prefix">{prefix}</Typography>}
    {icon}
    <Typography className="value">
      {value === null || value === undefined || value === "" ? fallback : value}
    </Typography>
    {endIcon}
    {suffix && <Typography className="suffix">{suffix}</Typography>}
  </div>
);

export default styled(IconLabel)(
  ({ prefix, size, color, iconColor, disabled, theme }) => ({
    display: "flex",
    alignItems: "center",
    color: disabled
      ? theme.palette.grey[600]
      : theme.palette[color]
      ? theme.palette[color].main
      : color,
    "> .MuiSvgIcon-root": {
      width: size === "small" ? "18px" : "20px",
      height: size === "small" ? "18px" : "20px",
      marginLeft: prefix ? "0.7em" : undefined,
      marginRight: "0.3em",
      color:
        iconColor && theme.palette[iconColor]
          ? theme.palette[iconColor].main
          : undefined,
    },
    "> .value": {
      fontWeight: "500",
      "& + .MuiSvgIcon-root": {
        marginLeft: "0.3em",
        marginRight: 0,
      },
    },
    ...(size === "small" && {
      ".MuiTypography-root": {
        fontSize: "0.9rem",
      },
    }),
    "> .suffix": {
      fontWeight: "500",
      marginLeft: "0.3em",
    },
  })
);
