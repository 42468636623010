// Auto Generated file, do not modify

export const ActionLinkType = {
    "Enquiry": "Enquiry",
    "Job": "Job",
    "Client": "Client",
    "Contract": "Contract",
    "Resource": "Resource",
    "EnquiryScope": "EnquiryScope",
    "JobScope": "JobScope",
    "DraftInvoice": "DraftInvoice",
} as const;

export type ActionLinkType = keyof typeof ActionLinkType;

export const ActionPriority = {
    "Low": "Low",
    "Medium": "Medium",
    "High": "High",
} as const;

export type ActionPriority = keyof typeof ActionPriority;

export const ActionStatus = {
    "New": "New",
    "InProgress": "InProgress",
    "Complete": "Complete",
} as const;

export type ActionStatus = keyof typeof ActionStatus;

export const AgencyStatus = {
    "Active": "Active",
    "Inactive": "Inactive",
} as const;

export type AgencyStatus = keyof typeof AgencyStatus;

export const AgreementType = {
    "BlanketAgreement": "BlanketAgreement",
    "ValueBasedAgreement": "ValueBasedAgreement",
    "RateSheet": "RateSheet",
} as const;

export type AgreementType = keyof typeof AgreementType;

export const ApprovalStatus = {
    "PendingVerification": "PendingVerification",
    "PendingApproval": "PendingApproval",
    "Approved": "Approved",
} as const;

export type ApprovalStatus = keyof typeof ApprovalStatus;

export const BankPaymentTerms = {
    "Daily": "Daily",
    "Weekly": "Weekly",
    "Monthly": "Monthly",
    "BiMonthly": "BiMonthly",
    "Annually": "Annually",
} as const;

export type BankPaymentTerms = keyof typeof BankPaymentTerms;

export const BillingFrequency = {
    "Monthly": "Monthly",
    "BiWeekly": "BiWeekly",
    "Weekly": "Weekly",
} as const;

export type BillingFrequency = keyof typeof BillingFrequency;

export const ClientStatus = {
    "Draft": "Draft",
    "Active": "Active",
    "Inactive": "Inactive",
} as const;

export type ClientStatus = keyof typeof ClientStatus;

export const ContactMethod = {
    "Email": "Email",
    "Phone": "Phone",
    "Mobile": "Mobile",
} as const;

export type ContactMethod = keyof typeof ContactMethod;

export const ContactStatus = {
    "Active": "Active",
    "Inactive": "Inactive",
} as const;

export type ContactStatus = keyof typeof ContactStatus;

export const ContractStatus = {
    "Draft": "Draft",
    "Active": "Active",
    "Inactive": "Inactive",
    "Expired": "Expired",
} as const;

export type ContractStatus = keyof typeof ContractStatus;

export const DaysOfWeek = {
    "None": "None",
    "Monday": "Monday",
    "Tuesday": "Tuesday",
    "Wednesday": "Wednesday",
    "Thursday": "Thursday",
    "Friday": "Friday",
    "Saturday": "Saturday",
    "Sunday": "Sunday",
} as const;

export type DaysOfWeek = keyof typeof DaysOfWeek;

export const DistanceUnit = {
    "Kilometers": "Kilometers",
    "Miles": "Miles",
} as const;

export type DistanceUnit = keyof typeof DistanceUnit;

export const DocumentViewType = {
    "General": "General",
    "Resource": "Resource",
    "Enquiry": "Enquiry",
    "Client": "Client",
    "Job": "Job",
    "Contract": "Contract",
    "Expense": "Expense",
    "ResourceUserAssignment": "ResourceUserAssignment",
    "Agency": "Agency",
    "Project": "Project",
    "ClientUserJob": "ClientUserJob",
    "DraftInvoice": "DraftInvoice",
} as const;

export type DocumentViewType = keyof typeof DocumentViewType;

export const EnquiryDeadline = {
    "DueOver24Hours": "DueOver24Hours",
    "DueUnder24Hours": "DueUnder24Hours",
    "DueUnder6Hours": "DueUnder6Hours",
    "DueUnder2Hours": "DueUnder2Hours",
    "Overdue": "Overdue",
} as const;

export type EnquiryDeadline = keyof typeof EnquiryDeadline;

export const EnquiryScopeStatus = {
    "New": "New",
    "AwaitingResource": "AwaitingResource",
    "ToBeProposed": "ToBeProposed",
    "Proposed": "Proposed",
    "OnHold": "OnHold",
    "Cancelled": "Cancelled",
    "Accepted": "Accepted",
    "Rejected": "Rejected",
    "Converted": "Converted",
    "DeclinedByEe": "DeclinedByEe",
} as const;

export type EnquiryScopeStatus = keyof typeof EnquiryScopeStatus;

export const EnquiryStatus = {
    "New": "New",
    "AwaitingResource": "AwaitingResource",
    "ToBeProposed": "ToBeProposed",
    "Proposed": "Proposed",
    "OnHold": "OnHold",
    "Cancelled": "Cancelled",
    "Accepted": "Accepted",
    "Rejected": "Rejected",
    "Converted": "Converted",
    "DeclinedByEe": "DeclinedByEe",
} as const;

export type EnquiryStatus = keyof typeof EnquiryStatus;

export const EnquiryVisibility = {
    "MyEnquiries": "MyEnquiries",
    "AllEnquiries": "AllEnquiries",
} as const;

export type EnquiryVisibility = keyof typeof EnquiryVisibility;

export const EnumDescriptionType = {
    "Full": "Full",
    "Short": "Short",
} as const;

export type EnumDescriptionType = keyof typeof EnumDescriptionType;

export const ExportType = {
    "Excel": "Excel",
    "Csv": "Csv",
} as const;

export type ExportType = keyof typeof ExportType;

export const GlobalSearchCategory = {
    "Resource": "Resource",
} as const;

export type GlobalSearchCategory = keyof typeof GlobalSearchCategory;

export const InstructionsOption = {
    "TechnicalAndQualityAssurance": "TechnicalAndQualityAssurance",
    "Compliance": "Compliance",
    "Financial": "Financial",
    "Commercials": "Commercials",
    "Resources": "Resources",
} as const;

export type InstructionsOption = keyof typeof InstructionsOption;

export const InvoiceGroupingLevel = {
    "ContractOrPo": "ContractOrPo",
    "Project": "Project",
    "Job": "Job",
    "Resource": "Resource",
} as const;

export type InvoiceGroupingLevel = keyof typeof InvoiceGroupingLevel;

export const InvoiceInstructionsOption = {
    "General": "General",
    "Rates": "Rates",
    "Invoicing": "Invoicing",
    "TimeAndExpenses": "TimeAndExpenses",
} as const;

export type InvoiceInstructionsOption = keyof typeof InvoiceInstructionsOption;

export const InvoiceStatus = {
    "Draft": "Draft",
    "Committed": "Committed",
    "Credited": "Credited",
} as const;

export type InvoiceStatus = keyof typeof InvoiceStatus;

export const InvoicingCustomFieldType = {
    "Contract": "Contract",
    "Project": "Project",
    "Job": "Job",
} as const;

export type InvoicingCustomFieldType = keyof typeof InvoicingCustomFieldType;

export const JobStatus = {
    "Active": "Active",
    "Completed": "Completed",
} as const;

export type JobStatus = keyof typeof JobStatus;

export const NonComplianceReportCategory = {
    "NonComplianceReport": "NonComplianceReport",
    "Observation": "Observation",
} as const;

export type NonComplianceReportCategory =
    keyof typeof NonComplianceReportCategory;

export const NonComplianceReportIdentification = {
    "Client": "Client",
    "EnergyEngineering": "EnergyEngineering",
    "EndUser": "EndUser",
    "Supplier": "Supplier",
} as const;

export type NonComplianceReportIdentification =
    keyof typeof NonComplianceReportIdentification;

export const OfficeBankAccountStatus = {
    "Active": "Active",
    "Inactive": "Inactive",
} as const;

export type OfficeBankAccountStatus = keyof typeof OfficeBankAccountStatus;

export const OfficeStatus = {
    "Active": "Active",
    "Inactive": "Inactive",
} as const;

export type OfficeStatus = keyof typeof OfficeStatus;

export const OfficeType = {
    "Office": "Office",
    "HeadOffice": "HeadOffice",
    "InvoicingOffice": "InvoicingOffice",
    "ProjectOffice": "ProjectOffice",
    "ContractOffice": "ContractOffice",
    "Subsidiary": "Subsidiary",
} as const;

export type OfficeType = keyof typeof OfficeType;

export const ReadyToInvoiceItemType = {
    "Visit": "Visit",
    "Expense": "Expense",
} as const;

export type ReadyToInvoiceItemType = keyof typeof ReadyToInvoiceItemType;

export const RecipientOption = {
    "To": "To",
    "Cc": "Cc",
} as const;

export type RecipientOption = keyof typeof RecipientOption;

export const ReorderDirection = {
    "Up": "Up",
    "Down": "Down",
} as const;

export type ReorderDirection = keyof typeof ReorderDirection;

export const ReportingSchedule = {
    "Daily": "Daily",
    "Weekly": "Weekly",
    "NoReport": "NoReport",
} as const;

export type ReportingSchedule = keyof typeof ReportingSchedule;

export const RequestedCorrections = {
    "None": "None",
    "Technical": "Technical",
    "Administrative": "Administrative",
    "TechAndAdmin": "TechAndAdmin",
} as const;

export type RequestedCorrections = keyof typeof RequestedCorrections;

export const ResourceBackupType = {
    "Main": "Main",
    "BackUp": "BackUp",
    "TBC": "TBC",
} as const;

export type ResourceBackupType = keyof typeof ResourceBackupType;

export const ResourceDivision = {
    "Fs": "Fs",
    "Is": "Is",
    "IsAndFs": "IsAndFs",
} as const;

export type ResourceDivision = keyof typeof ResourceDivision;

export const ResourceStatus = {
    "Pending": "Pending",
    "Approved": "Approved",
    "Retired": "Retired",
    "Unavailable": "Unavailable",
    "Suspended": "Suspended",
    "GreyListed": "GreyListed",
    "NotToUse": "NotToUse",
    "SemiRetired": "SemiRetired",
    "Deceased": "Deceased",
    "NoInfoAvailable": "NoInfoAvailable",
} as const;

export type ResourceStatus = keyof typeof ResourceStatus;

export const ResourceType = {
    "Direct": "Direct",
    "Agency": "Agency",
} as const;

export type ResourceType = keyof typeof ResourceType;

export const ResourceUserAvailabilityStatus = {
    "AwaitingYourResponse": "AwaitingYourResponse",
    "AvailabilityConfirmed": "AvailabilityConfirmed",
    "TentativeAcceptance": "TentativeAcceptance",
    "NotAvailable": "NotAvailable",
    "AcceptedByClient": "AcceptedByClient",
    "DeclinedOrCancelled": "DeclinedOrCancelled",
} as const;

export type ResourceUserAvailabilityStatus =
    keyof typeof ResourceUserAvailabilityStatus;

export const SalesMarginBand = {
    "Red": "Red",
    "Amber": "Amber",
    "Green": "Green",
} as const;

export type SalesMarginBand = keyof typeof SalesMarginBand;

export const ServiceContractType = {
    "LSC": "LSC",
    "GSC": "GSC",
} as const;

export type ServiceContractType = keyof typeof ServiceContractType;

export const ServiceType = {
    "None": "None",
    "Inspection": "Inspection",
    "Expediting": "Expediting",
    "Auditing": "Auditing",
    "RemoteServices": "RemoteServices",
    "RemoteInspectionEE": "RemoteInspectionEE",
    "RemoteExpeditingEE": "RemoteExpeditingEE",
    "Others": "Others",
} as const;

export type ServiceType = keyof typeof ServiceType;

export const ShortlistedResourceAvailability = {
    "NotContacted": "NotContacted",
    "AwaitingResponse": "AwaitingResponse",
    "Available": "Available",
    "NotAvailable": "NotAvailable",
    "NotResponded": "NotResponded",
    "Proposed": "Proposed",
    "AcceptedByClient": "AcceptedByClient",
    "RejectedByClient": "RejectedByClient",
    "Cancelled": "Cancelled",
    "TentativeAcceptance": "TentativeAcceptance",
} as const;

export type ShortlistedResourceAvailability =
    keyof typeof ShortlistedResourceAvailability;

export const ShortlistedResourceResponse = {
    "Accepted": "Accepted",
    "TentativelyAccepted": "TentativelyAccepted",
    "Rejected": "Rejected",
} as const;

export type ShortlistedResourceResponse =
    keyof typeof ShortlistedResourceResponse;

export const TaxRateStatus = {
    "Active": "Active",
    "Inactive": "Inactive",
} as const;

export type TaxRateStatus = keyof typeof TaxRateStatus;

export const UserStatus = {
    "Active": "Active",
    "Inactive": "Inactive",
    "Unverified": "Unverified",
} as const;

export type UserStatus = keyof typeof UserStatus;

export const UserType = {
    "Internal": "Internal",
    "Resource": "Resource",
    "Client": "Client",
} as const;

export type UserType = keyof typeof UserType;

export const VendorSiteStatus = {
    "Active": "Active",
    "Inactive": "Inactive",
} as const;

export type VendorSiteStatus = keyof typeof VendorSiteStatus;

export const VendorStatus = {
    "Active": "Active",
    "Inactive": "Inactive",
} as const;

export type VendorStatus = keyof typeof VendorStatus;

export const VisitTurnaroundTime = {
    "UpTo24Hours": "UpTo24Hours",
    "UpTo48Hours": "UpTo48Hours",
    "UpTo72Hours": "UpTo72Hours",
    "Over72Hours": "Over72Hours",
} as const;

export type VisitTurnaroundTime = keyof typeof VisitTurnaroundTime;
