import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 15 15"
    fill="currentColor"
  >
    <g
      id="Group_6599"
      data-name="Group 6599"
      transform="scale(0.85) translate(1.5, 1.5)"
    >
      <path
        id="Path_4618"
        data-name="Path 4618"
        d="M13.8,9.846a8.516,8.516,0,0,1-2.675-.426,1.222,1.222,0,0,0-1.189.251L8.246,10.944a9.321,9.321,0,0,1-4.191-4.19L5.291,5.111a1.212,1.212,0,0,0,.3-1.228A8.53,8.53,0,0,1,5.162,1.2,1.2,1.2,0,0,0,3.958,0H1.2A1.205,1.205,0,0,0,0,1.2,13.812,13.812,0,0,0,13.8,15,1.205,1.205,0,0,0,15,13.8V11.049A1.205,1.205,0,0,0,13.8,9.846Z"
      />
    </g>
  </svg>,
  "Phone"
);
