import { useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { Stack, Button, Link, Typography } from "@mui/material";
import { FormBuilder, Form, ErrorMessage } from "@redriver/cinnamon-mui";
import { SystemRoutes } from "constants/routes";
import { SubmitButton } from "components/buttons";
import { validateResetToken, requestPasswordReset } from "./actions";

const PasswordReset = ({ token, onResetSuccess }) => {
  const [tokenValid, setTokenValid] = useState(null);
  const [resetSuccess, setResetSuccess] = useState(false);

  if (!token) {
    return <Typography variant="body2">No reset token supplied.</Typography>;
  }

  if (tokenValid === false) {
    return (
      <div>
        <Typography variant="body2" marginBottom={3}>
          The reset token supplied is invalid, please request another.
        </Typography>
        <Button
          fullWidth
          component={RouterLink}
          to={SystemRoutes.ForgottenPassword}
        >
          Request Password Reset
        </Button>
        <Typography variant="caption" sx={{ mt: 1, mb: 4 }}>
          &lt;{" "}
          <Link component={RouterLink} to={SystemRoutes.Login}>
            Return to Log In
          </Link>
        </Typography>
      </div>
    );
  }

  if (resetSuccess) {
    return (
      <div>
        <Typography variant="body2" marginBottom={3}>
          Return to the Log In page and use your new password.
        </Typography>
        <Button fullWidth component={RouterLink} to={SystemRoutes.Login}>
          Return To Log In
        </Button>
      </div>
    );
  }

  return (
    <FormBuilder
      loadAction={validateResetToken}
      loadParams={token}
      loadSlowDelay={500}
      onLoaded={(response) => setTokenValid(!!response && response.ok)}
      submitAction={requestPasswordReset}
      submitParams={token}
      onSubmitted={() => {
        setResetSuccess(true);
        if (onResetSuccess) onResetSuccess();
      }}
      renderLoading={false}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { loading, slowLoading, submitting, slowSubmitting, error },
        events
      ) => {
        if (loading && slowLoading) {
          return (
            <Typography variant="body2">Validating reset token...</Typography>
          );
        }
        if (!tokenValid) return null;

        return (
          <Form {...formProps}>
            <Typography variant="body2" marginBottom={3}>
              Please enter a new password.
            </Typography>
            <Form.Password
              field="newPassword"
              label="New Password"
              placeholder="Enter new password"
              disabled={submitting && slowSubmitting}
              autoFocus
              required
              minLength={8}
              fluid
              inputProps={{ autoComplete: "new-password" }}
            />
            <Form.Password
              field="confirmPassword"
              label="Repeat New Password"
              placeholder="Repeat New password"
              disabled={submitting && slowSubmitting}
              required
              confirmField="newPassword"
              fluid
              inputProps={{ autoComplete: "new-password" }}
            />
            <Stack>
              <SubmitButton
                variant="contained"
                onClick={events.onSubmit}
                submitting={submitting}
                slowSubmitting={slowSubmitting}
              >
                Create New Password
              </SubmitButton>
              <Typography variant="caption" sx={{ mt: 1, mb: 4 }}>
                &lt;{" "}
                <Link component={RouterLink} to={SystemRoutes.Login}>
                  Return to Log In
                </Link>
              </Typography>
              <ErrorMessage error={error} />
            </Stack>
          </Form>
        );
      }}
    />
  );
};

PasswordReset.propTypes = {
  token: PropTypes.string.isRequired,
};

export default PasswordReset;
