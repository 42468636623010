import { Box, Tooltip, Typography } from "@mui/material";
import ResourceVisibility from "assets/icons/ResourceVisibility";
import ClientVisibility from "assets/icons/ClientVisibility";

const DocumentVisibility = ({ showClient, showResource }) =>
  showClient && showResource ? (
    <Box display="flex">
      <Tooltip title="Client Portal" placement="top">
        <ClientVisibility />
      </Tooltip>
      <Tooltip title="Resource Portal" placement="top">
        <ResourceVisibility />
      </Tooltip>
    </Box>
  ) : showClient ? (
    <Tooltip title="Client Portal" placement="top">
      <ClientVisibility />
    </Tooltip>
  ) : showResource ? (
    <Tooltip title="Resource Portal" placement="top">
      <ResourceVisibility />
    </Tooltip>
  ) : (
    <Typography>-</Typography>
  );

export default DocumentVisibility;
