import PropTypes from "prop-types";
import { LoadingButton } from "@mui/lab";

const SubmitButton = ({ submitting, slowSubmitting, onClick, ...props }) => (
  <LoadingButton
    {...props}
    type="submit"
    disabled={submitting}
    loading={submitting && (slowSubmitting || slowSubmitting === undefined)}
    onClick={(e) => {
      e.preventDefault();
      if (onClick) onClick(e);
    }}
  />
);

SubmitButton.propTypes = {
  submitting: PropTypes.bool,
  slowSubmitting: PropTypes.bool,
};

export default SubmitButton;
