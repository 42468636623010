import {
  AuthenticatedApp,
  NavigationLogger,
  Routing,
} from "@redriver/cinnamon-mui";
import { EnvironmentBanner } from "components/banners";
import { SystemRoutes } from "constants/routes";
import { PageTitles } from "constants/titles";
import LoginPage from "./system/LoginPage";
import LogoutPage from "./system/LogoutPage";
import AzureAdSignInPage from "./system/AzureSignInPage";
import UserSetupPage from "./system/UserSetupPage";
import EmailVerificationPage from "./system/EmailVerificationPage";
import PasswordForgottenPage from "./system/PasswordForgottenPage";
import PasswordResetPage from "./system/PasswordResetPage";
import EnergyEngineering from "./EnergyEngineering";

/**
 * Top-level app routes only
 */
const Shell = () => (
  <AuthenticatedApp>
    <NavigationLogger />
    <EnvironmentBanner />
    <Routing>
      {/* system routes */}
      <Routing.Route
        path={SystemRoutes.Login}
        title={PageTitles.Login}
        element={<LoginPage />}
      />
      <Routing.Route
        path={SystemRoutes.Logout}
        title={PageTitles.Logout}
        element={<LogoutPage />}
      />
      <Routing.SubRoutes
        path={SystemRoutes.AzureAdSignIn}
        element={<AzureAdSignInPage />}
      />
      <Routing.Route
        path={SystemRoutes.SetupUser}
        title={PageTitles.SetupUser}
        element={<UserSetupPage />}
      />
      <Routing.Route
        path={SystemRoutes.VerifyEmail}
        title={PageTitles.VerifyEmail}
        element={<EmailVerificationPage />}
      />
      <Routing.PublicRoute
        path={SystemRoutes.ForgottenPassword}
        title={PageTitles.ForgottenPassword}
        element={<PasswordForgottenPage />}
      />
      <Routing.PublicRoute
        path={SystemRoutes.ResetPassword}
        title={PageTitles.ResetPassword}
        element={<PasswordResetPage />}
      />

      {/* app routes */}
      <Routing.PrivateRoute
        path="*"
        redirect={SystemRoutes.Login}
        element={<EnergyEngineering />}
      />
    </Routing>
  </AuthenticatedApp>
);

export default Shell;
