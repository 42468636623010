import FileSaver from "file-saver";
import contentDisposition from "content-disposition";

const convertToBlob = async (data) => {
  if (data instanceof Blob) {
    return data;
  } else if (data instanceof Response) {
    return await data.blob();
  } else if (typeof data === "string") {
    return new Blob([data]);
  }
  return null;
};

export const saveFile = async (data, filename) => {
  const blob = await convertToBlob(data);
  if (!blob) {
    console.warn(
      `Unable to download ${filename} (file type not currently supported). Please contact a system administrator.`
    );
    return null;
  }

  const header = data.headers?.get("Content-Disposition");
  const parameters = header ? contentDisposition.parse(header).parameters : {};

  FileSaver.saveAs(blob, filename ?? parameters.filename);
};

export const formatFileSize = (sizeBytes) =>
  sizeBytes > 1024 * 1024
    ? Math.round(sizeBytes / (1024 * 1024)) + "MB"
    : sizeBytes > 1024
    ? Math.round(sizeBytes / 1024) + "KB"
    : (sizeBytes ?? 0) + "B";

export const getFileExtension = (fileName) => {
  const match = fileName.match(/(?:\.([^.]+))?$/);
  return match && match.length > 1 && match[1]
    ? match[1].toLowerCase()
    : undefined;
};

export const stripFileExtension = (fileName) => {
  return fileName.replace(/(?:\.([^.]+))?$/, "");
};

export const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "svg"];
