import PropTypes from "prop-types";
import { styled } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const LoadMoreButton = ({
  hasMore,
  loadingMore,
  onLoadMore,
  children,
  ...props
}) => {
  if (!hasMore) return null;
  return (
    <LoadingButton
      {...props}
      loading={loadingMore}
      disabled={loadingMore}
      onClick={onLoadMore}
    >
      {children ?? "Load More"}
    </LoadingButton>
  );
};

LoadMoreButton.propTypes = {
  hasMore: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
};

export default styled(LoadMoreButton)({ display: "flex" });
