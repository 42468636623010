import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@mui/material";
import Title from "./Title";

export const TooltipTitle = ({ as = "span", spacing, tooltip, ...props }) => (
  <Title
    {...props}
    as={as}
    spacing={spacing}
    endAdornment={
      <Tooltip title={tooltip} placement="top" justifyContent="center">
        <InfoIcon fontSize="small" />
      </Tooltip>
    }
  />
);

export default TooltipTitle;
