import classNames from "classnames";
import { Paper, Typography, styled } from "@mui/material";

const ContentPanel = ({
  tabs,
  tabContainer: TabContainer = "div",
  tabContainerProps = {},
  title,
  className,
  children,
}) => {
  const panel = (
    <Paper className={classNames("content-panel", className)}>
      {title && <Typography variant="h5">{title}</Typography>}
      {children}
    </Paper>
  );

  if (!tabs) return panel;

  return (
    <TabContainer
      {...tabContainerProps}
      className={classNames(
        "tabbed-content-panel",
        tabContainerProps?.className
      )}
    >
      {tabs}
      {panel}
    </TabContainer>
  );
};

export default styled(ContentPanel)(({ theme, container }) => ({
  padding: container ? 0 : theme.spacing(1.5, 2),
  "> .MuiTypography-h5": {
    marginBottom: theme.spacing(2),
  },
  "> .MuiTabPanel-root": {
    padding: 0,
  },
  ".content-container": {
    marginTop: 0,
    marginBottom: 0,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
}));
