import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 22.355 24.036"
  >
    <g
      id="Group_8028"
      data-name="Group 8028"
      transform="translate(-1460.07 -450)"
    >
      <g id="file_1_" data-name="file (1)" transform="translate(1459.621 450)">
        <path
          id="Path_4791"
          data-name="Path 4791"
          d="M133.072,0h-10.06A2.008,2.008,0,0,0,121,2V20.8a2.008,2.008,0,0,0,2.012,2h13.413a2.008,2.008,0,0,0,2.012-2V5.344Z"
          transform="translate(-115.634)"
          fill="#bde0f1"
        />
        <path
          id="Path_4793"
          data-name="Path 4793"
          d="M391,0V4.676a.669.669,0,0,0,.671.668h4.695Z"
          transform="translate(-373.562)"
          fill="#77bee2"
        />
        <g
          id="Group_7467"
          data-name="Group 7467"
          transform="translate(8.496 8.106)"
        >
          <path
            id="Path_4794"
            data-name="Path 4794"
            d="M201.507,273.336h-9.836a.668.668,0,1,1,0-1.336h9.836a.668.668,0,1,1,0,1.336Z"
            transform="translate(-191 -267.992)"
            fill="#082a4e"
          />
          <path
            id="Path_4795"
            data-name="Path 4795"
            d="M201.507,183.336h-9.836a.668.668,0,1,1,0-1.336h9.836a.668.668,0,1,1,0,1.336Z"
            transform="translate(-191 -182)"
            fill="#082a4e"
          />
          <path
            id="Path_4796"
            data-name="Path 4796"
            d="M201.507,363.336h-9.836a.668.668,0,1,1,0-1.336h9.836a.668.668,0,1,1,0,1.336Z"
            transform="translate(-191 -353.983)"
            fill="#082a4e"
          />
        </g>
      </g>
      <g id="worldwide" transform="translate(1460.07 459.402)">
        <g
          id="Group_8027"
          data-name="Group 8027"
          transform="translate(0.569 0.529)"
        >
          <path
            id="Path_5944"
            data-name="Path 5944"
            d="M287.008,28.407l-.757.5-1.262-.252-.757-.252-1.01.252-.757-.252.5-1.01h1.01l.989-.495A7.23,7.23,0,0,1,287.008,28.407Z"
            transform="translate(-274.959 -26.666)"
            fill="#05c031"
          />
          <path
            id="Path_5945"
            data-name="Path 5945"
            d="M26.16,19.336l-.252.757-.757.252-.757,1.262-1.262.757-1.767.252v.757l.5.5v1.01l-.757-.5-.757-.5-.439-1.32A7.315,7.315,0,0,1,23.929,18.6l.717.48Z"
            transform="translate(-19.916 -18.604)"
            fill="#05c031"
          />
          <path
            id="Path_5946"
            data-name="Path 5946"
            d="M75.89,214.213l-.252,1.262-1.01,1.01v.757l-1.01.757v1.262l-.757-.252-.5-1.262v-2.524l-1.262-.252-.5-1.01V213.2l.5-.5.757-.757.5,1.01h1.767l.757,1.262Z"
            transform="translate(-69.141 -206.413)"
            fill="#05c031"
          />
          <path
            id="Path_5947"
            data-name="Path 5947"
            d="M279.52,115.292a7.313,7.313,0,0,1-.4,7.792l-.681-.681v-1.01l-.5-1.01-.5-1.01v-1.01l-.757-.5-1.01.252-1.767-.757-.252-1.767.757-.757h1.514l.5.757,1.514.252,1.514-.5Z"
            transform="translate(-266.385 -112.079)"
            fill="#05c031"
          />
        </g>
        <path
          id="Path_5948"
          data-name="Path 5948"
          d="M8.833,2.591l1.01-.252.757.252,1.262.252.757-.5A7.208,7.208,0,0,1,13.7,3.81l-.076.043-1.514.5L10.6,4.1l-.5-.757H8.581L7.823,4.1l.252,1.767,1.767.757,1.01-.252.757.5v1.01l.5,1.01.5,1.01v1.01l.681.681A7.32,7.32,0,0,1,.569,4.552l.439,1.32.757.5.757.5-.5.5v.757l.5,1.01,1.262.252v2.524l.5,1.262.757.252V12.182l1.01-.757v-.757l1.01-1.01L7.319,8.4H6.309L5.552,7.134H3.785l-.5-1.01-.757.757V5.872l-.5-.5V4.61l1.767-.252L5.047,3.6,5.8,2.338l.757-.252.252-.757L5.3,1.076,4.583.6a7.32,7.32,0,0,1,6,.237l-.989.495H8.581l-.5,1.01Z"
          transform="translate(-0.001 -0.067)"
          fill="#207d9a"
        />
      </g>
    </g>
  </svg>,
  "GlobalInvoicing"
);
