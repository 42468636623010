import { FormUtils } from "@redriver/cinnamon-mui";

const useFilterTotals = (formValue, excludeFields = []) => {
  const keys = Object.keys(formValue);
  return keys.filter((k) => {
    const value = formValue[k];
    return (
      !excludeFields.includes(k) &&
      (typeof value !== "object" ||
        (Array.isArray(value) && value.every((x) => typeof x !== "object"))) &&
      FormUtils.isValueSet(value) &&
      value !== false
    );
  }).length;
};

export default useFilterTotals;
