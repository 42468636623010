import { authenticate, ApiDispatch } from "@redriver/cinnamon-mui";
import * as mortar from "mortar/endpoints/auth";

export const requestLogin =
  ({ email, password }: { email: string; password: string }) =>
  async (dispatch: ApiDispatch) => {
    // make the login api call
    const loginAction = mortar.authWithCreds({
      request: { key: email, secret: password },
      options: { auth: false, preventErrorNotification: true },
    });
    const result = await dispatch(loginAction);

    const { success, response } = result;
    if (success) {
      // authenticate the user session
      const { access, refresh } = response.tokens;
      dispatch(authenticate(access, refresh));
    }

    return result;
  };

export const onRefreshJwt = (token: string) =>
  mortar.authWithRefreshToken({
    request: { token },
    options: { auth: false, preventErrorNotification: true },
  });
