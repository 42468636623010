import React from "react";
import { Typography, styled } from "@mui/material";

const EllipsisTypography = React.forwardRef(({ children, ...props }, ref) => (
  <Typography {...props} ref={ref}>
    {children}
  </Typography>
));

export default styled(EllipsisTypography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));
