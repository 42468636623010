import React from "react";
import { Checkbox, Button } from "@mui/material";
import { useFormField, useLookupAction } from "@redriver/cinnamon-mui";
import JobAssignmentVisitsDropdown from "./JobAssignmentVisitsDropdown";
import { getJobAssignmentVisits } from "./actions";

const JobAssignmentVisitsListbox = React.forwardRef(
  (
    {
      field,
      children,
      jobAssignmentId,
      jobReportId,
      documentCategoryId,
      maxVisitDate,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useFormField(field);
    const [{ response: visitValues }] = useLookupAction(
      getJobAssignmentVisits,
      {
        jobAssignmentId,
        maxVisitDate,
        jobReportId,
        documentCategoryId,
      }
    );

    const noneSelected = !value || value.length === 0;
    const allSelected =
      value &&
      visitValues?.every((v) => value.some((x) => x.value === v.value));
    return (
      <ul {...props} ref={ref}>
        <li style={{ marginLeft: 8 }}>
          <Button
            variant="text"
            disabled={allSelected}
            onClick={() =>
              setValue(visitValues?.map((v) => ({ value: v.value })))
            }
          >
            Select All
          </Button>
          <Button
            variant="text"
            disabled={noneSelected}
            onClick={() => setValue([])}
          >
            Deselect All
          </Button>
        </li>
        {children}
      </ul>
    );
  }
);

const JobAssignmentVisitsList = ({
  field,
  jobAssignmentId,
  maxVisitDate,
  jobReportId,
  documentCategoryId,
  ...props
}) => (
  <JobAssignmentVisitsDropdown
    {...props}
    multiple
    disableClearable
    disableCloseOnSelect
    filterSelectedOptions={false}
    field={field}
    jobAssignmentId={jobAssignmentId}
    jobReportId={jobReportId}
    documentCategoryId={documentCategoryId}
    maxVisitDate={maxVisitDate}
    ListboxComponent={JobAssignmentVisitsListbox}
    ListboxProps={{
      field,
      jobAssignmentId,
      maxVisitDate,
      jobReportId,
      documentCategoryId,
    }}
    renderOption={(props, option, { selected }) => (
      <li {...props}>
        <Checkbox style={{ padding: 0, marginRight: 8 }} checked={selected} />
        {option.label}
      </li>
    )}
  />
);

export default JobAssignmentVisitsList;
