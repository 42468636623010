import { Loadable } from "@redriver/cinnamon-mui";

/**
 * Loadable area for features that need to fill the entire window
 */
const LoadableFitPage = ({ error, ...props }) => (
  <Loadable
    {...props}
    sx={{
      display: "flex",
      flexDirection: "column",
      flex: 1,
      maxHeight: "calc(100vh - 100px)", // height of app menu bar
    }}
  />
);

export default LoadableFitPage;
