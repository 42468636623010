import { Box, Typography, styled } from "@mui/material";
import DataUsageIcon from "@mui/icons-material/DataUsage";

const StatusHeader = ({ className, status }) => (
  <Box className={className}>
    <DataUsageIcon />
    <Typography marginLeft="0.5em" fontWeight="bold" whiteSpace="nowrap">
      {status}
    </Typography>
  </Box>
);

export default styled(StatusHeader)(({ theme }) => ({
  display: "flex",
  backgroundColor: theme.palette.primary.dark,
  color: theme.palette.background.white,
  alignSelf: "stretch",
  alignItems: "center",
  margin: theme.spacing(-1.5, 0),
  padding: theme.spacing(0.5, 1.5),
}));
