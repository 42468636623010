import { Form } from "@redriver/cinnamon-mui";
import { officesLookup } from "modules/lookups";

const OfficeDropdown = ({ userId, overrideAccess = false, ...props }) => (
  <Form.Dropdown
    {...props}
    lookup={officesLookup}
    lookupParams={{ userId, overrideAccess }}
    search
  />
);

export default OfficeDropdown;
