import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 26 26"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_4279"
          data-name="Rectangle 4279"
          width="26"
          height="26"
          fill="none"
        />
      </clipPath>
    </defs>
    <g id="Group_7033" data-name="Group 7033" clipPath="url(#clip-path)">
      <path
        id="Path_4696"
        data-name="Path 4696"
        d="M87.524,69.72v7.908a3.954,3.954,0,1,1,0-7.908"
        transform="translate(-74.524 -62.173)"
        fill="#ffdba9"
      />
      <path
        id="Path_4697"
        data-name="Path 4697"
        d="M13,4.621V0H11.477A.76.76,0,0,0,10.8.421l-.749,1.55a11.339,11.339,0,0,0-3,1.277L5.8,2.624a.757.757,0,0,0-.879.143L2.767,4.921a.762.762,0,0,0-.143.879l.626,1.249a11.307,11.307,0,0,0-1.278,3l-1.55.75A.765.765,0,0,0,0,11.477v3.047a.762.762,0,0,0,.421.681l1.55.749a11.371,11.371,0,0,0,1.143,2.753L2.582,20.3a.765.765,0,0,0,.184.78L4.92,23.234a.765.765,0,0,0,.78.184l1.593-.531a11.259,11.259,0,0,0,3.02,1.218l.441,1.375a.761.761,0,0,0,.722.521H13V21.379A8.379,8.379,0,0,1,13,4.621"
        fill="#207d9a"
      />
      <path
        id="Path_4698"
        data-name="Path 4698"
        d="M133.1,11.477v3.046a.761.761,0,0,1-.522.723l-1.375.441a11.273,11.273,0,0,1-1.218,3.02l.531,1.593a.765.765,0,0,1-.184.78l-2.154,2.154a.765.765,0,0,1-.78.184l-1.593-.531a11.259,11.259,0,0,1-3.02,1.218l-.441,1.375a.76.76,0,0,1-.722.521H120.1V21.379a8.379,8.379,0,0,0,0-16.758V0h1.523a.76.76,0,0,1,.677.421l.751,1.55a11.339,11.339,0,0,1,3,1.277l1.249-.625a.762.762,0,0,1,.879.143l2.154,2.154a.762.762,0,0,1,.143.879l-.625,1.248a11.291,11.291,0,0,1,1.354,3.266l1.374.441a.763.763,0,0,1,.521.723"
        transform="translate(-107.1)"
        fill="#227a96"
      />
      <path
        id="Path_4699"
        data-name="Path 4699"
        d="M124.054,73.674a3.955,3.955,0,0,1-3.954,3.954V69.72a3.962,3.962,0,0,1,3.954,3.954"
        transform="translate(-107.1 -62.173)"
        fill="#ffcb8c"
      />
      <path
        id="Path_4700"
        data-name="Path 4700"
        d="M125.374,142.529a6.862,6.862,0,0,1-.627.628,7,7,0,0,1-4.647,1.755v-3.163a5.482,5.482,0,0,0,3.846-1.568,5.585,5.585,0,0,1,1.428,2.349"
        transform="translate(-107.1 -125.006)"
        fill="#2595b8"
      />
      <path
        id="Path_4701"
        data-name="Path 4701"
        d="M76.654,141.748v3.163a7.013,7.013,0,0,1-5.274-2.382,5.592,5.592,0,0,1,1.428-2.349,5.482,5.482,0,0,0,3.846,1.568"
        transform="translate(-63.654 -125.006)"
        fill="#29abd4"
      />
    </g>
  </svg>,
  "Roles"
);
