import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 21.84 21"
  >
    <path
      id="_Color"
      data-name=" ↳Color"
      d="M10.92,16.877,17.669,21l-1.791-7.77L21.84,8l-7.851-.674L10.92,0,7.851,7.328,0,8,5.962,13.23,4.171,21Z"
      fill="#ffb400"
    />
  </svg>,
  "StarFilled"
);
