import React from "react";
import { ThemeOptions } from "@mui/material";
import {
  ActionBarVerticalAlign,
  ModalDraggableHeader,
} from "@redriver/cinnamon-mui";
import { TableMenuButton, VisibilityToggle } from "components/buttons";
import { FileDropzone } from "components/forms";
import CloseIcon from "@mui/icons-material/Close";
import type {} from "@mui/x-data-grid/themeAugmentation";
import type {} from "@mui/x-date-pickers/themeAugmentation";
import type {} from "@mui/lab/themeAugmentation";

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    roundIcon: true;
  }
  interface ButtonClasses {
    roundIcon: string;
  }
}

declare module "@mui/material/ButtonGroup" {
  interface ButtonGroupPropsVariantOverrides {
    toggleIcon: true;
  }
  interface ButtonGroupClasses {
    toggleIcon: string;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    standard: true;
    squareOutline: true;
  }

  interface ChipClasses {
    standard: string;
    squareOutline: string;
  }
}

declare module "@mui/material/Rating" {
  interface RatingPropsSizeOverrides {
    xl: true;
  }
}

declare module "@redriver/cinnamon-mui" {
  interface TableVariantOverrides {
    nested: true;
    compact: true;
  }
}

const components: ThemeOptions["components"] = {
  /**
   * MUI components
   */
  MuiCssBaseline: {
    styleOverrides: `
      html, body, #root {
        height: 100%;
        width: 100%;
      }
    `,
  },
  MuiUseMediaQuery: {
    defaultProps: { noSsr: true },
  },
  MuiInputBase: {
    styleOverrides: {
      root: ({ theme }) => ({
        backgroundColor: theme.palette.background.white,
        "&.MuiInputBase-readOnly, > input[readonly]": {
          backgroundColor: theme.palette.grey[100],
        },
      }),
    },
  },
  MuiButton: {
    defaultProps: {
      variant: "contained",
    },
    styleOverrides: {
      root: {
        textTransform: "unset",
        fontWeight: 600,
        letterSpacing: 0.5,
        paddingTop: 8,
        paddingBottom: 8,
      },
      roundIcon: ({ theme, ownerState }) => {
        const { color, size } = ownerState;
        return {
          borderRadius: "50%",
          minWidth: "unset",
          padding: size === "small" || size === "medium" ? 5 : 8,
          backgroundColor:
            color === "inherit" ? "inherit" : theme.palette[color].main,
          color:
            color === "inherit" ? "inherit" : theme.palette[color].contrastText,
          ".MuiSvgIcon-root": {
            fontSize: size === "small" ? "1rem" : undefined,
          },
          "&:hover": {
            backgroundColor:
              color === "inherit" ? "inherit" : theme.palette[color].dark,
          },
          "&.Mui-disabled": {
            color: theme.palette.action.disabled,
            backgroundColor: theme.palette.action.disabledBackground,
          },
        };
      },
    },
  },
  MuiButtonGroup: {
    styleOverrides: {
      toggleIcon: ({ theme }) => ({
        ".MuiButton-root": {
          padding: "4px 0",
        },
        ".MuiButton-colorInherit": {
          backgroundColor: theme.palette.background.white,
          color: theme.palette.grey[500],
        },
        ".MuiSvgIcon-root": { fontSize: "2rem" },
      }),
    },
  },
  MuiLoadingButton: {
    defaultProps: {
      variant: "contained",
    },
  },
  MuiChip: {
    styleOverrides: {
      standard: ({ theme, ownerState }) => {
        const { color } = ownerState;
        return {
          color: color !== "default" ? theme.palette[color].main : undefined,
          backgroundColor:
            color === "default" || color === "primary" || color === "secondary"
              ? theme.palette.background.info
              : theme.palette.background[color],
        };
      },
      squareOutline: ({ theme, ownerState }) => {
        const { color } = ownerState;
        const primary =
          color !== "default"
            ? theme.palette[color].main
            : theme.palette.text.primary;
        return {
          color: primary,
          backgroundColor: theme.palette.background.info,
          border: `1px solid ${primary}`,
          borderRadius: 3,
        };
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: ({ theme }) => ({
        borderColor: theme.palette.grey[300],
      }),
    },
  },
  MuiAlert: {
    styleOverrides: {
      message: {
        fontWeight: "500",
        opacity: 0.9,
      },
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      ChipProps: {
        color: "primary",
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: ({ theme }) => ({
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        height: "49px",
        fontSize: "1em",
      }),
    },
  },
  MuiTabs: {
    defaultProps: { TabIndicatorProps: { style: { display: "none" } } },
  },
  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({
        textTransform: "unset",
        fontSize: "1rem",
        fontWeight: "400",
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.background.white,
        borderLeft: `1px solid ${theme.palette.grey[200]}`,
        borderRight: `1px solid ${theme.palette.grey[300]}`,
        borderTop: `1px solid ${theme.palette.grey[100]}`,
        borderBottom: `1px solid ${theme.palette.grey[100]}`,
        minWidth: "auto",
        "&.Mui-selected": {
          fontWeight: "500",
          color: theme.palette.background.white,
          backgroundColor: theme.palette.secondary.main,
        },
        "+ .MuiTab-root": {
          borderLeft: "none",
        },
      }),
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "hover",
      children: "-",
    },
  },
  MuiBreadcrumbs: {
    defaultProps: { separator: ">" },
    styleOverrides: {
      root: {
        ".MuiTypography-root": {
          textDecoration: "none",
          color: "initial",
          fontWeight: "500",
        },
        "a.MuiTypography-root:hover": {
          textDecoration: "underline",
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: "0.8rem",
        fontWeight: "400",
      },
    },
  },
  MuiRating: {
    styleOverrides: {
      root: ({ ownerState }) => ({
        fontSize: ownerState.size === "xl" ? "2.5em" : undefined,
      }),
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        whiteSpace: "pre-line",
      },
    },
  },
  /**
   * Cinnamon components
   */
  CinActionBar: {
    defaultProps: { spacing: 2, verticalAlign: ActionBarVerticalAlign.Middle },
  },
  CinDataListItem: {
    defaultProps: {
      fallback: "-",
      title: "",
    },
    styleOverrides: {
      root: {
        dt: {
          fontWeight: "initial",
          fontSize: 13,
          marginBottom: 3,
        },
        dd: {
          fontWeight: "500",
          whiteSpace: "pre-line",
          ".MuiTypography-root": {
            fontWeight: "500",
          },
        },
      },
    },
  },
  CinFormDate: {
    defaultProps: {
      format: "dd-MMM-yyyy",
    },
  },
  CinFormDateTime: {
    defaultProps: {
      format: "HH:mm dd-MMM-yyyy",
    },
  },
  CinFormFile: {
    defaultProps: {
      dropzoneComponent: FileDropzone,
    },
  },
  CinFormPassword: {
    defaultProps: { visibilityToggle: VisibilityToggle },
  },
  CinFormatDateTime: {
    defaultProps: { format: "HH:mm dd-MMM-yyyy" },
  },
  CinFormatDate: {
    defaultProps: { format: "dd-MMM-yyyy" },
  },
  CinModal: {
    defaultProps: {
      draggableComponent: ModalDraggableHeader,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        ".MuiDialog-paper": {
          borderRadius: 20,
          ".MuiDialogActions-root": {
            backgroundColor: theme.palette.grey[200],
            ".MuiButton-text": {
              color: theme.palette.text.primary,
            },
          },
        },
      }),
    },
  },
  CinModalAdd: {
    defaultProps: { closeIcon: <CloseIcon /> },
  },
  CinModalEdit: {
    defaultProps: { submitLabel: "Save", closeIcon: <CloseIcon /> },
  },
  CinModalUpload: {
    defaultProps: { closeIcon: <CloseIcon /> },
  },
  CinTable: {
    styleOverrides: {
      nested: ({ theme }) => ({
        ".MuiTableHead-root, .MuiTableFooter-root": {
          backgroundColor: theme.palette.background.info,
          "th, td": {
            height: 36,
            padding: "4px 16px",
            fontWeight: "500",
          },
        },
      }),
      compact: ({ theme }) => ({
        ".MuiTableCell-root": {
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          paddingTop: theme.spacing(0.75),
          paddingBottom: theme.spacing(0.75),
        },
      }),
    },
  },
  CinTableColumn: {
    defaultProps: {
      fallback: "-",
    },
  },
  CinTableMenu: {
    defaultProps: {
      triggerComponent: TableMenuButton,
      MenuProps: {
        transformOrigin: {
          horizontal: "right",
          vertical: "top",
        },
        anchorOrigin: {
          horizontal: 10,
          vertical: 20,
        },
      },
    },
  },
  CinFormTypeAhead: {
    defaultProps: {
      placeholder: "Start typing to search...",
    },
  },
};

export default components;
