import {
  deauthenticate,
  getAuthRefreshToken,
  removeAllNotifications,
  lookupReset,
} from "@redriver/cinnamon-mui";

import * as mortar from "mortar/endpoints/auth";

export const requestLogout = () => async (dispatch: any, getState: any) => {
  // make the logout api call
  const token = getAuthRefreshToken(getState());

  const logoutAction = mortar.revokeRefreshToken({
    request: { token },
    options: { auth: false, preventErrorNotification: true },
  });

  const result = await dispatch(logoutAction);

  const { success } = result;
  if (success) {
    // deauthenticate the user session
    dispatch(deauthenticate());
  }

  return result;
};

export const onDeauthenticated = () => (dispatch: any) => {
  // clear toast notifications
  dispatch(removeAllNotifications());
  // reset all cached lookup data
  dispatch(lookupReset());
};
