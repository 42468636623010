import { useAuthClaims } from "@redriver/cinnamon-mui";
import { claimExists } from "./utils";

/**
 * Determines if the current user has the specified permission
 * @param {string|string[]} target
 * @param {string|string[]} action
 * @param {boolean} any
 * @returns {boolean}
 */
const usePermission = (target, action, any = false) => {
  const { permissions } = useAuthClaims();
  return claimExists(permissions, target, action, any);
};

export default usePermission;
