import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 28.029 24.516"
  >
    <g id="suitcase" transform="translate(0 -32.088)">
      <g id="Group_7924" data-name="Group 7924" transform="translate(0 32.088)">
        <path
          id="Path_5869"
          data-name="Path 5869"
          d="M177.746,33.332a5.515,5.515,0,0,0-1.217-.65,8.961,8.961,0,0,0-6.416,0,5.514,5.514,0,0,0-1.217.65.921.921,0,0,0-.363.733V35.51l.921.493.921-.493v-.924a6.946,6.946,0,0,1,5.891,0v.924l.921.493.921-.493V34.064A.921.921,0,0,0,177.746,33.332Z"
          transform="translate(-159.306 -32.088)"
          fill="#ee722f"
        />
        <g
          id="Group_7923"
          data-name="Group 7923"
          transform="translate(9.226 3.422)"
        >
          <path
            id="Path_5870"
            data-name="Path 5870"
            d="M168.533,94.589v1.017l.921.493.921-.493V94.589Z"
            transform="translate(-168.533 -94.589)"
            fill="#d66e41"
          />
          <path
            id="Path_5871"
            data-name="Path 5871"
            d="M311.647,94.589H309.8v1.017l.921.493.921-.493Z"
            transform="translate(-302.071 -94.589)"
            fill="#d66e41"
          />
        </g>
        <path
          id="Path_5872"
          data-name="Path 5872"
          d="M13.247,298.286v8.184a1.759,1.759,0,0,0,1.759,1.759h23.06a1.759,1.759,0,0,0,1.759-1.759v-8.184Z"
          transform="translate(-12.522 -283.713)"
          fill="#0a67b2"
        />
        <path
          id="Path_5873"
          data-name="Path 5873"
          d="M13.247,279.705v1.017c1.461,1.16,5.324,3.413,13.289,3.413s11.828-2.253,13.289-3.413V279.7H13.247Z"
          transform="translate(-12.522 -266.149)"
          fill="#0855a3"
        />
        <path
          id="Path_5874"
          data-name="Path 5874"
          d="M.649,121.7c1.4,1.14,5.258,3.474,13.366,3.474S25.98,122.84,27.38,121.7a2.938,2.938,0,0,0,.649-1.857v-4.915a1.759,1.759,0,0,0-1.759-1.759H1.759A1.759,1.759,0,0,0,0,114.928v4.915A2.938,2.938,0,0,0,.649,121.7Z"
          transform="translate(0 -108.73)"
          fill="#0a67b2"
        />
        <path
          id="Path_5875"
          data-name="Path 5875"
          d="M27.38,236.443c-1.4,1.14-5.258,3.474-13.366,3.474S2.05,237.583.649,236.443A1.759,1.759,0,0,1,0,235.078V236.1a1.759,1.759,0,0,0,.649,1.365c1.4,1.14,5.258,3.474,13.366,3.474S25.98,238.6,27.38,237.46a1.759,1.759,0,0,0,.649-1.365v-1.017A1.759,1.759,0,0,1,27.38,236.443Z"
          transform="translate(0 -223.965)"
          fill="#0855a3"
        />
        <path
          id="Path_5876"
          data-name="Path 5876"
          d="M227.545,308.639c.91,0,1.648-.676,1.648-2.14v-1a.586.586,0,0,0-.586-.586h-2.123a.586.586,0,0,0-.586.586v1C225.9,307.963,226.635,308.639,227.545,308.639Z"
          transform="translate(-213.53 -289.977)"
          fill="#fec53d"
        />
        <path
          id="Path_5877"
          data-name="Path 5877"
          d="M227.545,335.522a1.648,1.648,0,0,1-1.648-1.648v1.017a1.648,1.648,0,1,0,3.3,0v-1.017A1.648,1.648,0,0,1,227.545,335.522Z"
          transform="translate(-213.53 -317.353)"
          fill="#f9cf58"
        />
      </g>
    </g>
  </svg>,
  "Jobs"
);
