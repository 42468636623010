import { Loadable } from "@redriver/cinnamon-mui";

/**
 * Loadable area for 'Header' features
 * Errors are not displayed (only in toast notifications)
 */
const LoadableHeader = ({ error, ...props }) => (
  <Loadable
    {...props}
    sx={{
      display: "flex",
      flexDirection: "column",
      flex: 1,
    }}
  />
);

export default LoadableHeader;
