import { EnumDescriptionType, DocumentViewType } from "constants/enums";
import * as mortar from "mortar/endpoints/lookups";

export const getResourceRoles = ({
  contractId,
  jobId,
}: {
  contractId?: string;
  jobId?: string;
} = {}) =>
  mortar.resourceRolesLookup({
    queryParams: { contractId, jobId, withValidRates: false },
  });

export const getIndustries = (params: any) =>
  mortar.industriesLookup({
    queryParams: { ...params },
  });

export const getDisciplines = ({
  contractId,
  jobId,
}: {
  contractId?: string;
  jobId?: string;
} = {}) =>
  mortar.disciplinesLookup({
    queryParams: { contractId, jobId, withValidRates: false },
  });

export const getDisciplineTypes = () => mortar.disciplineTypesLookup();

export const getBillingAreas = (params: any) =>
  mortar.billingAreasLookup({
    queryParams: { ...params },
  });

export const getDistanceUnits = () => mortar.distanceUnitsLookup();

export const getResourceStatus = () => mortar.resourceStatusLookup();

export const getCountries = (params: any) =>
  mortar.countriesLookup({
    queryParams: { ...params },
  });

export const getResourceTypes = () => mortar.resourceTypesLookup();

export const getOfficeTypes = () => mortar.officeTypesLookup();

export const getEpcContractors = (params: any) =>
  mortar.epcContractorsLookup({
    queryParams: { ...params },
  });

export const getOffices = ({
  search,
  userId,
  overrideAccess,
}: {
  search: string;
  userId: string;
  overrideAccess: boolean;
}) =>
  mortar.officesLookup({
    queryParams: {
      search,
      userId,
      overrideAccess,
    },
  });

export const getCurrencies = () => mortar.currenciesLookup();

export const getEnquiryStatus = () => mortar.enquiryStatusLookup();

export const getEnquiryScopeStatus = () => mortar.enquiryScopeStatusLookup();

export const getServiceTypes = ({ contractId }: { contractId: string }) =>
  mortar.serviceTypesLookup({
    queryParams: {
      contractId,
    },
  });

export const getEnquiryDeadlines = () => mortar.enquiryDeadlinesLookup();
``;
export const getEnquiryVisibility = () => mortar.enquiryVisibilityLookup();

export const getDaysOfWeek = () =>
  mortar.daysOfWeekLookup({
    queryParams: {
      descriptionType: EnumDescriptionType.Full,
    },
  });

export const getRegions = ({ overrideAccess }: { overrideAccess: boolean }) =>
  mortar.regionsLookup({
    queryParams: {
      overrideAccess,
    },
  });

export const getDocumentCategories = ({
  viewType,
  categoryIds,
  excludeCategoryIds,
}: {
  viewType: DocumentViewType;
  categoryIds: number[];
  excludeCategoryIds: number[];
}) =>
  mortar.documentCategoriesLookup({
    queryParams: {
      viewType,
      categoryIds,
      excludeCategoryIds,
    },
  });

export const getProjectTypes = () => mortar.projectTypesLookup();

export const getClientTypes = () => mortar.clientTypesLookup();

export const getClientStatus = () => mortar.clientStatusLookup();

export const getAgreementTypes = () => mortar.agreementTypeLookup();

export const getServiceContractTypes = (descriptionType: EnumDescriptionType) =>
  mortar.serviceContractTypeLookup({
    queryParams: {
      descriptionType: descriptionType,
    },
  });

export const getJobStatus = () => mortar.jobStatusLookup();

export const getShortlistedResourceAvailability = () =>
  mortar.shortlistedResourceAvailabilityLookup();

export const getContactMethods = () => mortar.contactMethodLookup();

export const getCoordinatorUsers = ({
  search,
  officeId,
}: {
  search: string;
  officeId: string;
}) =>
  mortar.coordinatorUsersLookup({
    queryParams: {
      search,
      maxResults: 10,
      officeId,
    },
  });

export const getQualityUsers = ({ search }: { search: string }) =>
  mortar.qualityUsersLookup({
    queryParams: {
      search,
      maxResults: 10,
    },
  });

export const getInternalUsers = ({ search }: { search: string }) =>
  mortar.internalUsersLookup({
    queryParams: {
      search,
      maxResults: 10,
    },
  });

export const getReportingSchedules = () => mortar.reportingScheduleLookup();

export const getInvoiceGroupingLevels = () =>
  mortar.invoiceGroupingLevelsLookup();

export const getBillingFrequencies = () => mortar.billingFrequenciesLookup();

export const getActionStatus = () => mortar.actionStatusLookup();

export const getActionPriorities = () => mortar.actionPrioritiesLookup();

export const getActionLinkTypes = () => mortar.actionLinkTypesLookup();

export const getEndUsers = (params: any) =>
  mortar.endUsersLookup({
    queryParams: { ...params },
  });

export const getRequestedCorrectionTypes = () =>
  mortar.requestedCorrectionsLookup();

export const getResourceBackupTypes = () => mortar.resourceBackupTypesLookup();

export const getNonComplianceReportTypes = (params: any) =>
  mortar.nonComplianceReportTypesLookup({
    queryParams: { ...params },
  });

export const getNonComplianceReportCategories = () =>
  mortar.nonComplianceReportCategories();

export const getNonComplianceReportIdentifications = () =>
  mortar.nonComplianceReportIdentifications();

export const getScopeRejectionReasons = (params: any) =>
  mortar.scopeRejectionReasonsLookup({
    queryParams: { ...params },
  });

export const getExpenseTypes = () => mortar.expenseTypesLookup();

export const getContractStatus = () => mortar.contractStatusLookup();

export const getContractApproverUsers = ({ search }: { search: string }) =>
  mortar.contractApproverUsersLookup({
    queryParams: {
      search,
      maxResults: 10,
    },
  });

export const getResourceDivisions = () => mortar.resourceDivisionLookup();

export const getBankPaymentTerms = () => mortar.bankPaymentTermsLookup();

export const getTaxRates = ({ search }: { search: string }) =>
  mortar.taxRatesLookup({
    queryParams: {
      search,
      maxResults: 10,
    },
  });

export const getOfficeBankAccounts = ({
  search,
  officeId,
}: {
  search: string;
  officeId: string;
}) =>
  mortar.bankAccountsLookup({
    queryParams: {
      search,
      maxResults: 10,
      officeId,
    },
  });

export const getApprovalStatus = () => mortar.approvalStatusLookup();

export const getBillingAssignmentOffices = ({
  search,
  contractId,
  selectedOfficeIds,
}: {
  search: string;
  contractId: string;
  selectedOfficeIds: [];
}) =>
  mortar.contractInvoicingBillingAssignmentOfficesLookup({
    queryParams: {
      search,
      contractId,
      selectedOfficeIds,
      maxResults: 10,
    },
  });
