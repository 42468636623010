import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 20 14"
    fill="currentColor"
  >
    <g transform="scale(0.85) translate(1.5, 1.5)">
      <g
        id="Group_6601"
        data-name="Group 6601"
        transform="translate(1.06 7.583)"
      >
        <g id="Group_6600" data-name="Group 6600">
          <path
            id="Path_4619"
            data-name="Path 4619"
            d="M38.113,277.251l-1.668,1.739a1.8,1.8,0,0,1-2.558,0l-1.668-1.739L26.226,283.5a1.68,1.68,0,0,0,.73.171H43.375a1.678,1.678,0,0,0,.73-.171Z"
            transform="translate(-26.226 -277.251)"
          />
        </g>
      </g>
      <g id="Group_6603" data-name="Group 6603" transform="translate(1.06)">
        <g id="Group_6602" data-name="Group 6602">
          <path
            id="Path_4620"
            data-name="Path 4620"
            d="M43.377,76H26.958a1.679,1.679,0,0,0-.73.171l6.4,6.675h0l2.082,2.17a.681.681,0,0,0,.905,0l2.082-2.17h0l6.4-6.675A1.678,1.678,0,0,0,43.377,76Z"
            transform="translate(-26.228 -76)"
          />
        </g>
      </g>
      <g id="Group_6605" data-name="Group 6605" transform="translate(0 1.019)">
        <g id="Group_6604" data-name="Group 6604">
          <path
            id="Path_4621"
            data-name="Path 4621"
            d="M.193,101.212a1.656,1.656,0,0,0-.193.765v10.432a1.656,1.656,0,0,0,.193.765l6.237-5.981Z"
            transform="translate(0 -101.212)"
          />
        </g>
      </g>
      <g
        id="Group_6607"
        data-name="Group 6607"
        transform="translate(13.57 1.019)"
      >
        <g id="Group_6606" data-name="Group 6606">
          <path
            id="Path_4622"
            data-name="Path 4622"
            d="M359.17,101.21l-6.237,5.981,6.237,5.981a1.656,1.656,0,0,0,.193-.765V101.975A1.656,1.656,0,0,0,359.17,101.21Z"
            transform="translate(-352.933 -101.21)"
          />
        </g>
      </g>
    </g>
  </svg>,
  "Email"
);
