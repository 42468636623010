import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 32.631 32.634"
  >
    <g id="Group_7940" data-name="Group 7940" transform="translate(0)">
      <path
        id="Path_5879"
        data-name="Path 5879"
        d="M51.035,14.367c0,6.207-3.039,11.494-8.551,13.5a14.395,14.395,0,0,1-12.638-1.383c-4-2.551-5.269-7.026-5.269-12.12,0-4.042.291-7.7,2.978-10.306a14.367,14.367,0,0,1,20.02,0C50.262,6.672,51.035,10.325,51.035,14.367Z"
        transform="translate(-23.198)"
        fill="#7edaf7"
      />
      <path
        id="Path_5880"
        data-name="Path 5880"
        d="M34.521,26.487a14.287,14.287,0,0,0,5.38,2.058,14.4,14.4,0,0,1-10.055-2.058c-4-2.551-5.269-7.026-5.269-12.12,0-4.042.291-7.7,2.978-10.306A14.39,14.39,0,0,1,39.9.19a14.37,14.37,0,0,0-5.382,26.3Z"
        transform="translate(-23.198)"
        fill="#207d9a"
        opacity="0.3"
      />
      <path
        id="Path_5881"
        data-name="Path 5881"
        d="M11.651,88.429A2.071,2.071,0,0,1,9.579,90.5H8.628A1.568,1.568,0,0,0,7.06,92.068v1.14a3.21,3.21,0,0,1-.411,1.578,14.442,14.442,0,0,1-4.563-4.661c-.22-.361.04-1.2-.148-1.578s-.823-.308-.978-.707a14.385,14.385,0,0,1,3.4-15.48H8.684a1.959,1.959,0,0,1,0,3.919H6.052a1.568,1.568,0,1,0,0,3.136h.616a1.848,1.848,0,1,1,0,3.7H5.324a1.624,1.624,0,1,0,0,3.247H9.579A2.072,2.072,0,0,1,11.651,88.429Z"
        transform="translate(0 -68.299)"
        fill="#05c031"
      />
      <path
        id="Path_5882"
        data-name="Path 5882"
        d="M296.248,82.666a14.372,14.372,0,0,1-9.448,13.5,1.96,1.96,0,0,1,.987-3.653h1.232a1.567,1.567,0,1,0,0-3.135H288.4a1.848,1.848,0,1,1,0-3.7h4.423a1.624,1.624,0,1,0,0-3.247h-7.335a2.071,2.071,0,1,1,0-4.143h.952a1.568,1.568,0,0,0,1.568-1.568v-1.14a3.227,3.227,0,0,1,3.227-3.226h.653A14.327,14.327,0,0,1,296.248,82.666Z"
        transform="translate(-267.514 -68.299)"
        fill="#05c031"
      />
      <path
        id="Path_5883"
        data-name="Path 5883"
        d="M4.484,77.847a1.566,1.566,0,0,0,.636,1.26,14.388,14.388,0,0,0-.444,3.559q0,.288.012.573a1.623,1.623,0,0,0,.464,3.108q.13.49.292.965A14.3,14.3,0,0,0,7.4,91.089a1.558,1.558,0,0,0-.343.979v1.14a3.21,3.21,0,0,1-.411,1.578,14.428,14.428,0,0,1-4.562-4.661c-.22-.361.039-1.2-.149-1.578s-.823-.308-.978-.707a14.384,14.384,0,0,1,3.4-15.48H8.684a1.97,1.97,0,0,1,.322.026,14.392,14.392,0,0,0-2.835,3.893H6.052a1.568,1.568,0,0,0-1.568,1.568Z"
        transform="translate(0 -68.299)"
        fill="#05c031"
        opacity="0.18"
      />
    </g>
    <path
      id="Path_5885"
      data-name="Path 5885"
      d="M44.848,60.452l-1.127,4.26L43.643,65a.778.778,0,0,1-1.3.352l-.68-.675-3.78,3.76a1.237,1.237,0,0,1-1.647.1l-3.462-2.753-11.191,9.37-3.358,2.812A14.207,14.207,0,0,1,17.1,75.678l2.893-2.422L31.958,63.237a1.237,1.237,0,0,1,1.566-.022l3.386,2.694,3-2.977-.9-.9a.778.778,0,0,1,.345-1.3l1.482-.4,3.06-.828a.777.777,0,0,1,.955.949Z"
      transform="translate(-16.14 -56.138)"
      fill="#cc0808"
    />
  </svg>,
  "GlobalRates"
);
