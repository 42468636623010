import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 23.822"
  >
    <g
      id="business-and-trade_2_"
      data-name="business-and-trade (2)"
      transform="translate(0 -0.178)"
    >
      <path
        id="Path_4730"
        data-name="Path 4730"
        d="M13.03,1.87,2.04.2A1.772,1.772,0,0,0,.62.59,1.788,1.788,0,0,0,0,1.93V23a1,1,0,0,0,1,1H4.25V18.75A1.746,1.746,0,0,1,6,17H8.5a1.746,1.746,0,0,1,1.75,1.75V24H14.5V3.6a1.751,1.751,0,0,0-1.47-1.73ZM5.5,14.75H4a.75.75,0,0,1,0-1.5H5.5a.75.75,0,0,1,0,1.5Zm0-3H4a.75.75,0,0,1,0-1.5H5.5a.75.75,0,0,1,0,1.5Zm0-3H4a.75.75,0,0,1,0-1.5H5.5a.75.75,0,0,1,0,1.5Zm0-3H4a.75.75,0,0,1,0-1.5H5.5a.75.75,0,0,1,0,1.5Zm5,9H9a.75.75,0,0,1,0-1.5h1.5a.75.75,0,0,1,0,1.5Zm0-3H9a.75.75,0,0,1,0-1.5h1.5a.75.75,0,0,1,0,1.5Zm0-3H9a.75.75,0,0,1,0-1.5h1.5a.75.75,0,0,1,0,1.5Zm0-3H9a.75.75,0,0,1,0-1.5h1.5a.75.75,0,0,1,0,1.5Z"
        fill="#29abd4"
      />
      <path
        id="Path_4731"
        data-name="Path 4731"
        d="M22.62,10.842,15.5,9.351V24h6.75A1.752,1.752,0,0,0,24,22.25v-9.7A1.741,1.741,0,0,0,22.62,10.842ZM20.25,21h-1.5a.75.75,0,0,1,0-1.5h1.5a.75.75,0,0,1,0,1.5Zm0-3h-1.5a.75.75,0,0,1,0-1.5h1.5a.75.75,0,0,1,0,1.5Zm0-3h-1.5a.75.75,0,0,1,0-1.5h1.5a.75.75,0,0,1,0,1.5Z"
        fill="#227a96"
      />
    </g>
  </svg>,
  "Offices"
);
