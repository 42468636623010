import { ThemeOptions, PaletteColor, PaletteColorOptions } from "@mui/material";

declare module "@mui/material/styles" {
  interface TypeBackground {
    white: string;
    info: string;
    success: string;
    warning: string;
    error: string;
    dropzone: string;
  }

  interface Palette {
    menu: PaletteColor & {
      active: string;
      hover: string;
      item: string;
    };
    neutral: PaletteColor;
  }

  interface PaletteOptions {
    menu: PaletteColorOptions & {
      active: string;
      hover: string;
      item: string;
    };
    neutral: PaletteColorOptions;
  }
}

const palette: ThemeOptions["palette"] = {
  primary: {
    light: "#3c96de",
    main: "#0a67b2",
    dark: "#082a4e",
    contrastText: "#fff",
  },
  secondary: {
    main: "#29abd4",
    contrastText: "#fff",
  },
  menu: {
    main: "#e8eff5",
    active: "#d1dde7",
    hover: "#fff",
    item: "#d1dde7",
    contrastText: "#082a4e",
  },
  background: {
    default: "#faf9fc",
    white: "#fff",
    info: "#e8eff5",
    success: "#F0F8F2",
    warning: "#FFF5E6",
    error: "#FFECEC",
    dropzone: "#F8FBFD",
  },
  success: {
    main: "#21BA45",
    contrastText: "#fff",
  },
  warning: {
    main: "#FFB400",
  },
  neutral: {
    main: "#414141",
    contrastText: "#fff",
  },
};

export default palette;
