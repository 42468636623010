import { Form } from "@redriver/cinnamon-mui";

// temporary component until file size validation is supported on Cinnamon FormFile

const FileSizeError = ({ field, maxSizeMB }) => (
  <Form.CustomErrors
    field={`${field}_size`}
    triggerFields={[field]}
    validator={({ fields }) => {
      const maxSizeBytes = maxSizeMB * 1024 * 1024;
      const value = fields[field];
      const files = value ? (Array.isArray(value) ? value : [value]) : [];
      const hasSizeError =
        files.map((x) => x.size ?? 0).reduce((a, b) => a + b, 0) > maxSizeBytes;
      return hasSizeError
        ? [`Uploaded files must be smaller than ${maxSizeMB}MB`]
        : [];
    }}
  />
);

export default FileSizeError;
