import { useState } from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
import { Stack, Button, Typography } from "@mui/material";
import { FormBuilder, Form, ErrorMessage } from "@redriver/cinnamon-mui";
import { SystemRoutes } from "constants/routes";
import { SubmitButton } from "components/buttons";
import { validateSetupToken, requestUserSetup } from "./actions";

const UserSetup = ({ token, onSetupSuccess }) => {
  const [tokenValid, setTokenValid] = useState(null);
  const [setupSuccess, setSetupSuccess] = useState(false);

  if (!token) {
    return <Typography variant="body2">No setup token supplied.</Typography>;
  }

  if (tokenValid === false) {
    return (
      <div>
        <Typography variant="body2" marginBottom={3}>
          The account verification link is invalid or has expired, if you have
          already setup your account please sign in.
        </Typography>
        <Button fullWidth component={RouterLink} to={SystemRoutes.Login}>
          Return To Log In
        </Button>
      </div>
    );
  }

  if (setupSuccess) {
    return (
      <div>
        <Typography variant="body2" marginBottom={3}>
          Your account has been setup successfully.
        </Typography>
        <Button fullWidth component={RouterLink} to={SystemRoutes.Login}>
          Return To Log In
        </Button>
      </div>
    );
  }

  return (
    <FormBuilder
      loadAction={validateSetupToken}
      loadParams={token}
      onLoaded={(response) => setTokenValid(!!response && response.ok)}
      submitAction={requestUserSetup}
      submitParams={token}
      onSubmitted={() => {
        setSetupSuccess(true);
        if (onSetupSuccess) onSetupSuccess();
      }}
      renderLoading={false}
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { loading, slowLoading, submitting, slowSubmitting, error },
        events
      ) => {
        if (loading && slowLoading) {
          return (
            <Typography variant="body2">Validating setup token...</Typography>
          );
        }
        if (!tokenValid) return null;

        return (
          <Form {...formProps}>
            <Typography variant="body2" marginBottom={3}>
              Please enter a new password.
            </Typography>
            <Form.Password
              field="newPassword"
              label="New Password"
              placeholder="Enter new password"
              disabled={submitting && slowSubmitting}
              autoFocus
              required
              minLength={8}
              fluid
              inputProps={{ autoComplete: "new-password" }}
            />
            <Form.Password
              field="confirmPassword"
              label="Confirm Password"
              placeholder="Confirm new password"
              disabled={submitting && slowSubmitting}
              required
              confirmField="newPassword"
              fluid
              inputProps={{ autoComplete: "new-password" }}
            />
            <Stack spacing={4}>
              <SubmitButton
                variant="contained"
                onClick={events.onSubmit}
                submitting={submitting}
                slowSubmitting={slowSubmitting}
              >
                Create New Account
              </SubmitButton>
              <ErrorMessage error={error} />
            </Stack>
          </Form>
        );
      }}
    />
  );
};

UserSetup.propTypes = {
  token: PropTypes.string.isRequired,
};

export default UserSetup;
