import { Paper, styled } from "@mui/material";
import classNames from "classnames";

const TablePanel = ({ tabs, className, children }) => {
  const panel = (
    <Paper className={classNames("table-panel", className)}>{children}</Paper>
  );

  if (!tabs) return panel;

  return (
    <div className="tabbed-table-panel">
      {tabs}
      {panel}
    </div>
  );
};

export default styled(TablePanel)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(1.5, 2),
  "> .MuiTabPanel-root": {
    padding: 0,
  },
  "> .MuiTable-root, > .MuiTabPanel-root > .MuiTable-root": {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    width: `calc(100% + ${theme.spacing(4)})`,
  },
  ".CinActionBar-root + .MuiTable-root": {
    marginTop: theme.spacing(1.5),
  },
  ".MuiTable-root + .table-pagination, .MuiTable-root + .table-results": {
    marginTop: theme.spacing(1.5),
  },
}));
