import { Chip, styled } from "@mui/material";
import { ResourceUserAvailabilityStatus } from "constants/enums";

const ResourceUserAvailabilityLabel = ({
  status,
  label,
  className,
  ...props
}) => (
  <Chip
    variant="standard"
    label={label}
    color={
      status === ResourceUserAvailabilityStatus.AcceptedByClient ||
      status === ResourceUserAvailabilityStatus.AvailabilityConfirmed
        ? "success"
        : status === ResourceUserAvailabilityStatus.TentativeAcceptance
        ? "warning"
        : status === ResourceUserAvailabilityStatus.AwaitingYourResponse
        ? "error"
        : undefined
    }
    className={className}
    {...props}
  />
);

export default styled(ResourceUserAvailabilityLabel)({
  fontSize: "1rem",
  fontWeight: 500,
  height: 38,
  padding: "0.2em",
});
