import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Box, Container, styled } from "@mui/material";
import { grey } from "@mui/material/colors";
import StatusHeader from "./StatusHeader";

const HeaderContainer = React.forwardRef(
  ({ className, children, status }, ref) => (
    <Box className={classNames("header-container", className)} ref={ref}>
      <Container maxWidth={false} disableGutters>
        {children}
      </Container>
      {status && <StatusHeader status={status} />}
    </Box>
  )
);

HeaderContainer.propTypes = {
  variant: PropTypes.oneOf(["plain", "border", "shadow"]),
  information: PropTypes.bool,
  shrinkFields: PropTypes.bool,
};

HeaderContainer.defaultProps = {
  variant: "plain",
  information: false,
  gutterBottom: false,
  shrinkFields: true,
};

export default styled(HeaderContainer)(
  ({ theme, variant, information, gutterBottom, shrinkFields = true }) => ({
    minHeight: 54,
    padding: theme.spacing(1.5, 0),
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    alignItems: "center",
    "> .MuiContainer-root": {
      padding: theme.spacing(0, 2),
    },
    ...(variant === "border" && {
      borderBottom: `1px solid ${grey[300]}`,
    }),
    ...(variant === "shadow" && {
      boxShadow: `0px 2px 2px 0px ${grey[400]}`,
    }),
    ...(shrinkFields && {
      ".MuiInputBase-root": {
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
        input: {
          paddingTop: `${theme.spacing(1.2)} !important`,
          paddingBottom: `${theme.spacing(1.2)} !important`,
        },
      },
    }),
    ".MuiDivider-root": {
      marginLeft: theme.spacing(-2),
      marginRight: theme.spacing(-2),
      borderColor: grey[200],
    },
    ".CinDataList-root > .MuiDivider-root": {
      margin: theme.spacing(0.5, -1),
    },
    ".CinActionBar-root": {
      flexWrap: "wrap",
    },
    ...(information && {
      backgroundColor: theme.palette.background.info,
      ".icon-label": {
        "> .MuiSvgIcon-root": {
          color: theme.palette.secondary.main,
        },
        ".value": {
          fontWeight: "normal",
        },
      },
    }),
    ...(gutterBottom && {
      marginBottom: theme.spacing(1),
    }),
  })
);
