import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 591.909 791.454"
  >
    <g id="ppt" transform="translate(-99.773)">
      <g id="x0020_1_18_">
        <path
          id="Path_4841"
          data-name="Path 4841"
          d="M202.808,0H467.417L691.682,233.758V688.419A102.927,102.927,0,0,1,588.844,791.454H202.808A102.969,102.969,0,0,1,99.773,688.419V103.035A102.968,102.968,0,0,1,202.808,0Z"
          fill="#ec5705"
          fillRule="evenodd"
        />
        <g id="Group_7613" data-name="Group 7613">
          <path
            id="Path_4842"
            data-name="Path 4842"
            d="M467.219,0V231.978H691.682Z"
            fill="#fff"
            fillRule="evenodd"
            opacity="0.302"
          />
          <path
            id="Path_4843"
            data-name="Path 4843"
            d="M224.166,566.2V422.425h61.109c15.03,0,27.094,4.153,36.191,12.657,8.9,8.306,13.448,19.381,13.448,33.422,0,13.844-4.549,25.116-13.448,33.422q-13.645,12.459-36.191,12.459H260.752V566.2Zm36.587-83.061h20.37c5.537,0,9.69-1.187,12.855-3.758,2.966-2.571,4.351-6.328,4.351-10.877,0-4.746-1.384-8.306-4.351-10.877-3.164-2.571-7.317-3.955-12.855-3.955h-20.37ZM350.34,566.2V422.425h61.109c15.03,0,27.094,4.153,36.191,12.657,9.1,8.306,13.448,19.381,13.448,33.422,0,13.844-4.351,25.116-13.448,33.422s-21.161,12.459-36.191,12.459H387.124V566.2Zm36.784-83.061H407.3c5.537,0,9.888-1.187,12.855-3.758A13.874,13.874,0,0,0,424.7,468.5c0-4.746-1.582-8.306-4.549-10.877s-7.317-3.955-12.855-3.955H387.124ZM513.891,566.2V453.672H472.954V422.425H591.811v31.247H550.676V566.2Z"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>,
  "Ppt"
);
