import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 17 17"
  >
    <g id="wall-clock" transform="translate(0)">
      <path
        id="Path_4594"
        data-name="Path 4594"
        d="M14.51,2.49A8.5,8.5,0,0,0,2.489,14.51,8.5,8.5,0,0,0,14.51,2.49Zm0,0"
        fill="#1d7894"
      />
      <path
        id="Path_4595"
        data-name="Path 4595"
        d="M262.01,2.49A8.445,8.445,0,0,0,256,0V17a8.5,8.5,0,0,0,6.01-14.51Zm0,0"
        transform="translate(-247.5)"
        fill="#1d7894"
      />
      <path
        id="Path_4596"
        data-name="Path 4596"
        d="M99.952,94.4a5.55,5.55,0,1,1-5.55-5.55A5.55,5.55,0,0,1,99.952,94.4Zm0,0"
        transform="translate(-85.902 -85.902)"
        fill="#ebf7ff"
      />
      <path
        id="Path_4597"
        data-name="Path 4597"
        d="M261.55,94.4a5.55,5.55,0,0,1-5.55,5.55v-11.1a5.55,5.55,0,0,1,5.55,5.55Zm0,0"
        transform="translate(-247.501 -85.901)"
        fill="#ebf7ff"
      />
      <path
        id="Path_4598"
        data-name="Path 4598"
        d="M243.385,161.046,242,159.472v-2.622a.5.5,0,0,0-1,0v2.811a.5.5,0,0,0,.125.33l1.514,1.715a.5.5,0,1,0,.747-.659Zm0,0"
        transform="translate(-232.999 -151.16)"
        fill="#31394a"
      />
    </g>
  </svg>,
  "Clock"
);
