import { ActionBar, ContentBuilder } from "@redriver/cinnamon-mui";
import { getUserSignature } from "./actions";
import { ContentPanel } from "components/containers";
import { Alert, Typography } from "@mui/material";
import { ImageLightbox } from "components/display";
import UploadUserSignature from "./UploadUserSignature";
import UploadIcon from "@mui/icons-material/Upload";
import React, { useState } from "react";

const UserSignature = () => {
  const [lightboxTarget, setLightboxTarget] = useState(null);
  return (
    <ContentBuilder
      loadAction={getUserSignature}
      renderContent={(data, state, events) => (
        <ContentPanel
          title={
            <ActionBar>
              <ActionBar.Item>
                <Typography variant="h5">Account Signature</Typography>
              </ActionBar.Item>
              <ActionBar.Item fluid align="right">
                <UploadUserSignature
                  color="primary"
                  startIcon={<UploadIcon />}
                  onUploaded={events.onRefresh}
                >
                  Upload Signature
                </UploadUserSignature>
              </ActionBar.Item>
            </ActionBar>
          }
        >
          {!data.fileName && (
            <Alert severity="warning">
              No signature image has been uploaded
            </Alert>
          )}
          {data?.fileName && (
            <React.Fragment>
              {data.fileName && (
                <img
                  src={data.url}
                  style={{ maxWidth: "20em", maxHeight: "20em" }}
                  onClick={() => {
                    setLightboxTarget(data);
                  }}
                />
              )}
              <ImageLightbox
                files={lightboxTarget ? [lightboxTarget] : []}
                open={!!lightboxTarget}
                onClose={() => {
                  setLightboxTarget(null);
                }}
                targetSrc={lightboxTarget?.url}
              />
            </React.Fragment>
          )}
        </ContentPanel>
      )}
    />
  );
};

export default UserSignature;
