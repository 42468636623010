import { useLoggedIn, useApiAction } from "@redriver/cinnamon-mui";
import { Button, Typography } from "@mui/material";
import { requestLogout } from "./actions";

const Logout = () => {
  const loggedIn = useLoggedIn();
  const [{ success }, logout] = useApiAction(requestLogout, { lazy: false });

  if (!loggedIn) {
    return (
      <Typography variant="body2">
        You have been successfully logged out.
      </Typography>
    );
  }

  switch (success) {
    case false:
      return (
        <div>
          <Typography variant="body2" marginBottom={3}>
            Unable to log out at this time
          </Typography>
          <Button onClick={() => logout()}>Try Again</Button>
        </div>
      );

    default:
      return <Typography variant="body2">Logging out...</Typography>;
  }
};

export default Logout;
