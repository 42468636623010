import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { setupApp } from "./setup";
import theme from "./theme";
import Shell from "./pages/Shell";

// lightbox css
import "yet-another-react-lightbox/styles.css";

const { store } = setupApp();

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Shell />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
