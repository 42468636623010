import React from "react";
import { Typography, styled } from "@mui/material";
import { FormatHtml } from "@redriver/cinnamon-mui";
import { BasicTile } from "components/containers";
import { EllipsisTypography } from "components/data";
import { ContactPointLabel } from "components/labels";
import { ContactMethod, GlobalSearchCategory } from "constants/enums";
import { AppRoutes, RelativeResourceRoutes } from "constants/routes";
import ResourceIcon from "assets/icons/Resource";

const GlobalSearchRow = ({ result, className }) => {
  const { id, title, description, category, meta } = result;

  return (
    <BasicTile
      link={
        category === GlobalSearchCategory.Resource
          ? `${AppRoutes.Resources}/${RelativeResourceRoutes.Resource(id)}`
          : undefined
      }
      className={className}
      footer={
        category === GlobalSearchCategory.Resource && (
          <React.Fragment>
            {meta?.emailAddress && (
              <ContactPointLabel
                type={ContactMethod.Email}
                value={meta.emailAddress}
                iconColor="secondary"
              />
            )}
            {meta?.phoneNumber && (
              <ContactPointLabel
                type={ContactMethod.Phone}
                value={meta.phoneNumber}
                iconColor="secondary"
              />
            )}
            {meta?.mobileNumber && (
              <ContactPointLabel
                type={ContactMethod.Mobile}
                value={meta.mobileNumber}
                iconColor="secondary"
              />
            )}
          </React.Fragment>
        )
      }
    >
      <div className="icon-column">
        {category === GlobalSearchCategory.Resource && (
          <ResourceIcon fontSize="large" color="primary" />
        )}
      </div>
      <div className="content-column">
        <Typography variant="h6" fontWeight={500} gutterBottom>
          <FormatHtml value={title} />
        </Typography>
        <EllipsisTypography>
          <FormatHtml value={description} />
        </EllipsisTypography>
      </div>
    </BasicTile>
  );
};

export default styled(GlobalSearchRow)(({ theme }) => ({
  ".MuiCardContent-root": {
    display: "flex",
    flexDirection: "row",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  ".MuiCardActions-root": {
    display: "flex",
    flexDirection: "row",
    paddingTop: theme.spacing(0.75),
    paddingBottom: theme.spacing(0.75),
    "> .icon-label": {
      marginLeft: "3em",
    },
  },
  ".icon-column": {
    minWidth: "3em",
  },
  ".content-column": {
    maxWidth: "calc(100% - 3em)",
  },
  ".highlight": {
    backgroundColor: theme.palette.warning.main,
  },
}));
