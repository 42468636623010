import classNames from "classnames";
import { Container, styled } from "@mui/material";

const SwimlaneContainer = ({ className, children }) => (
  <Container
    maxWidth={false}
    disableGutters
    className={classNames("swimlane-container", className)}
  >
    {children}
  </Container>
);

export default styled(SwimlaneContainer)(({ theme, columns }) => ({
  width: "100%",
  flexGrow: 1,
  display: "flex",
  flexDirection: "row",
  overflowX: "auto",
  "> .swimlane": {
    flexBasis: `${100 / columns}%`,
  },
  "> .swimlane + .swimlane": {
    borderLeft: `solid 1px ${theme.palette.grey[300]}`,
  },
}));
