import { Form, useFormState } from "@redriver/cinnamon-mui";
import {
  Drawer,
  Box,
  Stack,
  Typography,
  IconButton,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const FilterDrawer = ({
  title,
  width = 500,
  open,
  onClose,
  children,
  excludeFields = [],
  ...props
}) => {
  const { fields } = useFormState();
  const allFields = Object.keys(fields ?? {}).filter(
    (f) => !excludeFields.includes(f)
  );

  return (
    <Drawer anchor="right" {...props} open={open} onClose={onClose}>
      <Box sx={{ width, padding: 2 }} role="presentation">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
        >
          <Stack direction="row" alignItems="baseline" spacing={3}>
            <Typography variant="h6">{title}</Typography>
            <Form.Clearer
              field={allFields}
              as={Link}
              variant="caption"
              href="#"
            >
              Clear All
            </Form.Clearer>
          </Stack>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        {children}
      </Box>
    </Drawer>
  );
};

export default FilterDrawer;
