import { Form } from "@redriver/cinnamon-mui";
import { coordinatorUsersLookup } from "modules/lookups";

const CoordinatorUserTypeAhead = ({ officeId, ...props }) => (
  <Form.TypeAhead
    {...props}
    lookup={coordinatorUsersLookup}
    lookupParams={{ officeId }}
  />
);

export default CoordinatorUserTypeAhead;
