import { Stack, Typography } from "@mui/material";
import { FilePickerDropzone } from "@redriver/cinnamon-mui";
import { formatFileSize } from "modules/helpers";
import DownloadIcon from "@mui/icons-material/Download";

const FileDropzone = (props) => (
  <FilePickerDropzone
    {...props}
    sx={(theme) => ({
      bgcolor: theme.palette.background.dropzone,
    })}
  >
    {(value, state) => (
      <Stack alignItems="center" spacing={0.5}>
        <DownloadIcon color="primary" sx={{ fontSize: "4em" }} />
        {value ? (
          (state.multiple ? value : [value]).map((v, i) => (
            <Typography key={`${v.name}_${i}`}>
              {v.name} ({formatFileSize(v.size)})
            </Typography>
          ))
        ) : (
          <Typography>
            {state.multiple
              ? "Drag and drop your files or click to upload"
              : "Drag and drop a file or click to upload"}
          </Typography>
        )}
      </Stack>
    )}
  </FilePickerDropzone>
);

export default FileDropzone;
