import { useLocation } from "react-router-dom";
import { parse } from "query-string";

const useGlobalSearchQuery = () => {
  const location = useLocation();
  const params = parse(location.search); // also uri decodes the params
  return params?.q ?? "";
};

export default useGlobalSearchQuery;
