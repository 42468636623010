import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 26 35"
  >
    <g id="pdf_1_" data-name="pdf (1)" transform="translate(-99.773)">
      <g id="x0020_1_15_" transform="translate(99.773 0)">
        <path
          id="Path_4826"
          data-name="Path 4826"
          d="M104.3,0h11.623l9.851,10.337V30.444A4.537,4.537,0,0,1,121.256,35H104.3a4.538,4.538,0,0,1-4.526-4.556V4.556A4.538,4.538,0,0,1,104.3,0Z"
          transform="translate(-99.773 0)"
          fill="#e8eff5"
          fillRule="evenodd"
        />
        <g id="Group_7539" data-name="Group 7539" transform="translate(5.03 0)">
          <path
            id="Path_4827"
            data-name="Path 4827"
            d="M467.219,0V10.19h9.86Z"
            transform="translate(-456.108)"
            fill="#7edaf7"
            fillRule="evenodd"
            opacity="0.302"
          />
        </g>
      </g>
    </g>
  </svg>,
  "File"
);
