import { Form } from "@redriver/cinnamon-mui";
import { getClientContacts } from "./actions";

const ClientContactTypeAhead = ({
  clientId,
  clientOfficeIds = [],
  jobId,
  projectId,
  ...props
}) => (
  <Form.TypeAhead
    {...props}
    lookupAction={getClientContacts}
    lookupParams={{ clientId, clientOfficeIds, jobId, projectId }}
  />
);

export default ClientContactTypeAhead;
