import { Form } from "@redriver/cinnamon-mui";
import { getRates } from "./actions";

const RateTypeAhead = ({
  contractId,
  enquiryScopeId,
  includeExpired = false,
  ...props
}) => (
  <Form.TypeAhead
    {...props}
    lookupAction={getRates}
    lookupParams={{
      contractId,
      enquiryScopeId,
      includeExpired,
    }}
    groupBy={(o) => o.label}
    getOptionLabel={(o) => {
      const roles = o.resourceRole?.label ?? "All Resource Roles";
      const disciplines = o.discipline?.label ?? "All Disciplines";
      return `${o.billingArea?.label} - ${roles} - ${disciplines} - ${o.currency?.code}`;
    }}
  />
);

export default RateTypeAhead;
