import { Link as RouterLink, useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import { useLoggedIn } from "@redriver/cinnamon-mui";
import { Logout } from "features/System";
import { AppRoutes } from "constants/routes";
import { SystemPageContent } from "components/pages";

const LogoutPage = () => {
  const location = useLocation();
  const loggedIn = useLoggedIn();

  const nextRoute = location.state?.nextLocation ?? AppRoutes.Root;

  return (
    <SystemPageContent title="Log Out">
      <Logout />
      {!loggedIn && (
        <div style={{ marginTop: 20 }}>
          <Button fullWidth component={RouterLink} to={nextRoute}>
            Return To Log In
          </Button>
        </div>
      )}
    </SystemPageContent>
  );
};

export default LogoutPage;
