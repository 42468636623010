import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 23.543 23.543"
  >
    <path
      id="Path_4734"
      data-name="Path 4734"
      d="M7.357,0a7.357,7.357,0,0,0,0,14.714.49.49,0,0,0,.347-.143L14.571,7.7a.491.491,0,0,0,.143-.347A7.365,7.365,0,0,0,7.357,0Z"
      fill="#eea801"
    />
    <path
      id="Path_4735"
      data-name="Path 4735"
      d="M157.672,149.333a8.338,8.338,0,1,0,8.338,8.338A8.338,8.338,0,0,0,157.672,149.333Z"
      transform="translate(-142.467 -142.466)"
      fill="#ffb400"
    />
    <g
      id="Group_7161"
      data-name="Group 7161"
      transform="translate(0.974 0.981)"
    >
      <path
        id="Path_4736"
        data-name="Path 4736"
        d="M27.57,367.7a4.56,4.56,0,0,1-4.2-3.514l1.038.519a.491.491,0,0,0,.438-.878l-1.962-.981c-.014-.007-.029-.006-.044-.012a.5.5,0,0,0-.2-.032H22.6l-.045.016a.49.49,0,0,0-.3.217c-.008.013-.021.022-.027.035l-.981,1.962a.491.491,0,0,0,.219.658h0a.485.485,0,0,0,.226.048.49.49,0,0,0,.439-.271l.381-.761a5.477,5.477,0,0,0,5.066,3.975h0a.491.491,0,0,0,0-.981Z"
        transform="translate(-21.187 -347.101)"
        fill="#082a4e"
      />
      <path
        id="Path_4737"
        data-name="Path 4737"
        d="M347.929,24.326a.491.491,0,0,0-.658.219h0l-.381.761a5.477,5.477,0,0,0-5.067-3.974h0a.49.49,0,0,0,0,.981,4.56,4.56,0,0,1,4.2,3.512l-1.038-.519a.491.491,0,1,0-.438.878l1.962.981h.021a.48.48,0,0,0,.2.053.473.473,0,0,0,.253-.071l.009-.008a.483.483,0,0,0,.156-.169c.006-.01.017-.014.022-.024l.981-1.962a.491.491,0,0,0-.217-.659Z"
        transform="translate(-326.612 -21.333)"
        fill="#082a4e"
      />
    </g>
    <path
      id="Path_4738"
      data-name="Path 4738"
      d="M259.923,218.272v-2.943c1.1.14,1.962.743,1.962,1.44a.49.49,0,0,0,.981,0c0-1.256-1.26-2.256-2.943-2.418v-.528a.49.49,0,0,0-.981,0v.525c-1.682.167-2.943,1.162-2.943,2.418s1.261,2.256,2.943,2.418v2.943c-1.1-.14-1.962-.743-1.962-1.44a.49.49,0,1,0-.981,0c0,1.256,1.26,2.256,2.943,2.418v.528a.49.49,0,1,0,.981,0v-.525c1.682-.167,2.943-1.162,2.943-2.418S261.605,218.434,259.923,218.272Zm-2.943-1.506c0-.7.858-1.3,1.962-1.44v2.881C257.838,218.066,256.98,217.464,256.98,216.766Zm2.943,5.364v-2.88c1.1.14,1.962.743,1.962,1.44S261.026,221.99,259.923,222.13Z"
      transform="translate(-244.228 -203.523)"
      fill="#fafafa"
    />
    <g
      id="Group_7162"
      data-name="Group 7162"
      transform="translate(2.943 2.943)"
    >
      <path
        id="Path_4739"
        data-name="Path 4739"
        d="M88.766,72.829c-1.925,0-3.433-1.079-3.433-2.452V66.452c0-1.373,1.509-2.452,3.433-2.452S92.2,65.079,92.2,66.452a.49.49,0,1,1-.981,0c0-.8-1.123-1.471-2.452-1.471s-2.452.674-2.452,1.471v3.924c0,.8,1.123,1.471,2.452,1.471a.49.49,0,0,1,0,.981Z"
        transform="translate(-84.352 -64)"
        fill="#eceff1"
      />
      <path
        id="Path_4740"
        data-name="Path 4740"
        d="M69.4,128.981h-4.9a.49.49,0,1,1,0-.981h4.9a.49.49,0,1,1,0,.981Z"
        transform="translate(-64 -125.057)"
        fill="#eceff1"
      />
      <path
        id="Path_4741"
        data-name="Path 4741"
        d="M69.4,171.647h-4.9a.49.49,0,1,1,0-.981h4.9a.49.49,0,1,1,0,.981Z"
        transform="translate(-64 -165.761)"
        fill="#eceff1"
      />
    </g>
  </svg>,
  "Currencies"
);
