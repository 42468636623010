import { Form } from "@redriver/cinnamon-mui";
import { getProjects } from "./actions";

const ProjectTypeAhead = ({
  clientId,
  clientOfficeId,
  contractId,
  ...props
}) => (
  <Form.TypeAhead
    {...props}
    lookupAction={getProjects}
    lookupParams={{ clientId, clientOfficeId, contractId }}
  />
);

export default ProjectTypeAhead;
