export * from "mortar/EnergyEngineering/Enums";

export const enumOptionsArray = (enumObj: Record<string, string>) => {
  return Object.keys(enumObj).map((k) => enumOption(enumObj, k));
};

export const enumOption = (enumObj: Record<string, string>, value: string) => {
  if (!value) return undefined;
  return {
    value,
    label: enumObj[value] ?? value,
  };
};
