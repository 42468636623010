import { Form } from "@redriver/cinnamon-mui";
import { getRateSets } from "./actions";

const RateSetTypeAhead = ({
  contractId,
  jobId,
  includeExpired = false,
  includeExpiredRateSetId = null,
  includeGlobalRates = false,
  ...props
}) => (
  <Form.TypeAhead
    {...props}
    lookupAction={getRateSets}
    lookupParams={{
      contractId,
      jobId,
      includeExpired,
      includeExpiredRateSetId,
      includeGlobalRates,
    }}
  />
);

export default RateSetTypeAhead;
