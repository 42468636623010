import AddIcon from "@mui/icons-material/Add";
import { IconButton, styled, Typography } from "@mui/material";
import { Form, useFormField, useFormState } from "@redriver/cinnamon-mui";
import DeleteIcon from "assets/icons/Delete";
import StarUnselectedIcon from "assets/icons/StarEmpty";
import StarSelectedIcon from "assets/icons/StarFilled";
import { ContactMethod } from "constants/enums";
import { produce } from "immer";
import ContactMethodDropdown from "./fields/ContactMethodDropdown";

const ContactPointsForm = ({ className }) => {
  const [contactPoints, setContactPoints] = useFormField("contactPoints");
  const { errors, fields: data } = useFormState();
  const isAdding = !!data.newContactValue || !!data.newType;

  return (
    <Form.Field className={className} fluid>
      <Form.Array className="contact-points" field="contactPoints">
        {({ fields, arrayIndex }) => (
          <div className="row">
            <Typography className="type">{fields.type}</Typography>
            <div className="email">
              <Typography>{fields.value}</Typography>
              <IconButton
                className="primary-btn"
                onClick={() => {
                  setContactPoints(
                    produce((draft) => {
                      if (!fields.isPrimary) {
                        draft.forEach((p) => {
                          if (p.type === fields.type && p.isPrimary) {
                            p.isPrimary = false;
                          }
                        });
                      }

                      draft[arrayIndex].isPrimary = !fields.isPrimary;
                    })
                  );
                }}
              >
                {fields.isPrimary ? (
                  <StarSelectedIcon />
                ) : (
                  <StarUnselectedIcon />
                )}
              </IconButton>
            </div>
            <Form.ArrayRemover className="delete-btn" as={IconButton}>
              <DeleteIcon color="primary" />
            </Form.ArrayRemover>
          </div>
        )}
      </Form.Array>
      <Form.Group className="new-contact-point">
        <ContactMethodDropdown
          label="Contact Type"
          field="newContactType"
          valueField="newType"
          required={!!data.newContactValue}
          width="25%"
        />
        {data.newType === ContactMethod.Email ? (
          <Form.Email
            label="Email Address"
            field="newContactValue"
            required={isAdding}
            width="55%"
          />
        ) : (
          <Form.Input
            label={
              data.newType === ContactMethod.Phone
                ? "Phone Number"
                : data.newType === ContactMethod.Mobile
                ? "Mobile Number"
                : "Value"
            }
            field="newContactValue"
            required={isAdding}
            width="55%"
          />
        )}
        <Form.ArrayAdder
          className="add-btn"
          variant="roundIcon"
          field="contactPoints"
          disabled={
            !isAdding ||
            (Object.keys(errors).length > 0 &&
              (errors.newContactType?.length > 0 ||
                errors.newContactValue?.length > 0))
          }
          populate={(arrayKey) => ({
            [arrayKey]: Date.now(),
            type: data.newType,
            value: data.newContactValue,
            isPrimary: !(contactPoints || []).some(
              (p) => p.isPrimary && p.type === data.newType
            ),
          })}
        >
          <AddIcon />
        </Form.ArrayAdder>
      </Form.Group>
      <Form.Trigger
        field="contactPoints"
        target="newContactValue"
        action="clear"
      />
      <Form.Trigger
        field="contactPoints"
        target="newContactType"
        action="clear"
      />
    </Form.Field>
  );
};

export default styled(ContactPointsForm)(({ theme }) => ({
  ".contact-points": {
    ".row": {
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(-1),
      marginRight: theme.spacing(-1),
      ".type": {
        width: "25%",
        fontWeight: "bold",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      ".email": {
        display: "flex",
        width: "55%",
        wordBreak: "break-all",
        alignItems: "center",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        ".primary-btn": {
          marginLeft: "auto",
          marginRight: "1.75em",
        },
      },
      ".delete-btn": {
        marginLeft: "0.2em",
      },
    },
  },
  ".new-contact-point": {
    marginBottom: 0,
    ".add-btn": {
      alignSelf: "center",
      marginLeft: "0.5em",
    },
  },
}));
