import classNames from "classnames";
import { RoutingUtils } from "@redriver/cinnamon-mui";
import { Link as RouterLink, useMatch } from "react-router-dom";
import { Button, styled } from "@mui/material";

const NavButton = ({
  active,
  link = "",
  exact,
  className,
  children,
  ...props
}) => {
  const linkPath = exact ? link : RoutingUtils.subRoutesPath(link);
  const linkMatch = useMatch(linkPath);
  return (
    <Button
      variant="text"
      color="inherit"
      size="large"
      disableRipple
      {...props}
      component={link ? RouterLink : undefined}
      to={link}
      className={classNames(className, {
        active: active !== undefined ? active : link && linkMatch,
      })}
    >
      <span className="label">{children}</span>
    </Button>
  );
};

export default styled(NavButton)(({ theme }) => ({
  alignSelf: "stretch",
  minHeight: "3rem",
  padding: "0.2em 1.4em",
  borderRadius: 0,
  fontWeight: 400,
  fontSize: "1.15rem",
  transition: "background-color linear 150ms",
  "> .label": {
    transition: "border-bottom-color linear 150ms",
  },
  "&.active": {
    backgroundColor: theme.palette.menu.active,
  },
  "&:hover": {
    backgroundColor: theme.palette.menu.hover,
  },
}));
