// Auto Generated file, do not modify
import {
    apiDelete,
    apiGet,
    apiPost,
    apiPut,
    ApiRequestOptions,
} from "@redriver/cinnamon-mui";
import {
    CreateUserRequest,
    UpdateUserRequest,
} from "mortar/EnergyEngineering/Endpoints/Admin/Users";
import { UserStatus, UserType } from "mortar/EnergyEngineering/Enums";
import {
    SignatureImageView,
    UserDetailsView,
    UserListView,
} from "mortar/EnergyEngineering/Logic/Users/Views";
import { LookupView, PagedView } from "mortar/EnergyEngineering/Logic/Views";
import { makeAction, makeFormData } from "../lib";

export interface ListUsersActionRequest {
    queryParams: {
        search: string;
        type: UserType;
        status: UserStatus;
        pageSize: number;
        pageNumber: number;
    };
    options?: Partial<
        ApiRequestOptions<PagedView<UserListView>, "users/listUsers">
    >;
}

export const listUsers = makeAction((
    { queryParams, options }: ListUsersActionRequest,
) => apiGet<PagedView<UserListView>, "users/listUsers">(
    "users/listUsers",
    `users`,
    queryParams,
    options,
), "users/listUsers");

export interface CreateUserActionRequest {
    request: CreateUserRequest;
    options?: Partial<ApiRequestOptions<string, "users/createUser">>;
}

export const createUser = makeAction((
    { request, options }: CreateUserActionRequest,
) => apiPost<string, "users/createUser">(
    "users/createUser",
    `users`,
    request,
    options,
), "users/createUser");

export interface AllowedInternalUserDomainsActionRequest {
    options?: Partial<
        ApiRequestOptions<string[], "users/allowedInternalUserDomains">
    >;
}

export const allowedInternalUserDomains = makeAction((
    { options }: AllowedInternalUserDomainsActionRequest = {},
) => apiGet<string[], "users/allowedInternalUserDomains">(
    "users/allowedInternalUserDomains",
    `users/allowed-internal-domains`,
    undefined,
    options,
), "users/allowedInternalUserDomains");

export interface GetUserDefaultOfficeActionRequest {
    options?: Partial<
        ApiRequestOptions<LookupView, "users/getUserDefaultOffice">
    >;
}

export const getUserDefaultOffice = makeAction((
    { options }: GetUserDefaultOfficeActionRequest = {},
) => apiGet<LookupView, "users/getUserDefaultOffice">(
    "users/getUserDefaultOffice",
    `users/default-office`,
    undefined,
    options,
), "users/getUserDefaultOffice");

export interface UpdateUserLastAccessedActionRequest {
    options?: Partial<
        ApiRequestOptions<string, "users/updateUserLastAccessed">
    >;
}

export const updateUserLastAccessed = makeAction((
    { options }: UpdateUserLastAccessedActionRequest = {},
) => apiPut<string, "users/updateUserLastAccessed">(
    "users/updateUserLastAccessed",
    `users/last-accessed`,
    undefined,
    options,
), "users/updateUserLastAccessed");

export interface GetUserSignatureSelfActionRequest {
    options?: Partial<
        ApiRequestOptions<SignatureImageView, "users/getUserSignatureSelf">
    >;
}

export const getUserSignatureSelf = makeAction((
    { options }: GetUserSignatureSelfActionRequest = {},
) => apiGet<SignatureImageView, "users/getUserSignatureSelf">(
    "users/getUserSignatureSelf",
    `users/signature-image`,
    undefined,
    options,
), "users/getUserSignatureSelf");

export interface UploadUserSignatureImageSelfActionFormData {
    file: File;
}

export interface UploadUserSignatureImageSelfActionRequest {
    formParams: UploadUserSignatureImageSelfActionFormData;
    formTransform?: (
        request: UploadUserSignatureImageSelfActionFormData,
    ) => FormData;
    options?: Partial<
        ApiRequestOptions<void, "users/uploadUserSignatureImageSelf">
    >;
}

export const uploadUserSignatureImageSelf = makeAction((
    { formParams, formTransform, options }:
        UploadUserSignatureImageSelfActionRequest,
) => apiPost<void, "users/uploadUserSignatureImageSelf">(
    "users/uploadUserSignatureImageSelf",
    `users/signature-image`,
    (formTransform || makeFormData)(formParams, {
        file: "Append",
    }),
    { contentType: null, ...options },
), "users/uploadUserSignatureImageSelf");

export interface UserDetailsActionRequest {
    routeParams: {
        userId: string;
    };
    options?: Partial<ApiRequestOptions<UserDetailsView, "users/userDetails">>;
}

export const userDetails = makeAction((
    { routeParams, options }: UserDetailsActionRequest,
) => apiGet<UserDetailsView, "users/userDetails">(
    "users/userDetails",
    `users/${routeParams.userId}`,
    undefined,
    options,
), "users/userDetails");

export interface UpdateUserActionRequest {
    routeParams: {
        userId: string;
    };
    request: UpdateUserRequest;
    options?: Partial<ApiRequestOptions<string, "users/updateUser">>;
}

export const updateUser = makeAction((
    { routeParams, request, options }: UpdateUserActionRequest,
) => apiPut<string, "users/updateUser">(
    "users/updateUser",
    `users/${routeParams.userId}`,
    request,
    options,
), "users/updateUser");

export interface DeleteUserActionRequest {
    routeParams: {
        userId: string;
    };
    options?: Partial<ApiRequestOptions<string, "users/deleteUser">>;
}

export const deleteUser = makeAction((
    { routeParams, options }: DeleteUserActionRequest,
) => apiDelete<string, "users/deleteUser">(
    "users/deleteUser",
    `users/${routeParams.userId}`,
    undefined,
    options,
), "users/deleteUser");

export interface ActivateUserActionRequest {
    routeParams: {
        userId: string;
    };
    options?: Partial<ApiRequestOptions<void, "users/activateUser">>;
}

export const activateUser = makeAction((
    { routeParams, options }: ActivateUserActionRequest,
) => apiPut<void, "users/activateUser">(
    "users/activateUser",
    `users/${routeParams.userId}/activate`,
    undefined,
    options,
), "users/activateUser");

export interface DeactivateUserActionRequest {
    routeParams: {
        userId: string;
    };
    options?: Partial<ApiRequestOptions<void, "users/deactivateUser">>;
}

export const deactivateUser = makeAction((
    { routeParams, options }: DeactivateUserActionRequest,
) => apiPut<void, "users/deactivateUser">(
    "users/deactivateUser",
    `users/${routeParams.userId}/disable`,
    undefined,
    options,
), "users/deactivateUser");

export interface ResendUserVerificationEmailActionRequest {
    routeParams: {
        userId: string;
    };
    options?: Partial<
        ApiRequestOptions<void, "users/resendUserVerificationEmail">
    >;
}

export const resendUserVerificationEmail = makeAction((
    { routeParams, options }: ResendUserVerificationEmailActionRequest,
) => apiPost<void, "users/resendUserVerificationEmail">(
    "users/resendUserVerificationEmail",
    `users/${routeParams.userId}/resend-verification-email`,
    undefined,
    options,
), "users/resendUserVerificationEmail");

export interface SendPasswordResetEmailActionRequest {
    routeParams: {
        userId: string;
    };
    options?: Partial<ApiRequestOptions<void, "users/sendPasswordResetEmail">>;
}

export const sendPasswordResetEmail = makeAction((
    { routeParams, options }: SendPasswordResetEmailActionRequest,
) => apiPost<void, "users/sendPasswordResetEmail">(
    "users/sendPasswordResetEmail",
    `users/${routeParams.userId}/send-password-reset-email`,
    undefined,
    options,
), "users/sendPasswordResetEmail");

export interface GetUserSignatureActionRequest {
    routeParams: {
        userId: string;
    };
    options?: Partial<
        ApiRequestOptions<SignatureImageView, "users/getUserSignature">
    >;
}

export const getUserSignature = makeAction((
    { routeParams, options }: GetUserSignatureActionRequest,
) => apiGet<SignatureImageView, "users/getUserSignature">(
    "users/getUserSignature",
    `users/${routeParams.userId}/signature-image`,
    undefined,
    options,
), "users/getUserSignature");

export interface UploadUserSignatureImageActionFormData {
    file: File;
}

export interface UploadUserSignatureImageActionRequest {
    routeParams: {
        userId: string;
    };
    formParams: UploadUserSignatureImageActionFormData;
    formTransform?: (
        request: UploadUserSignatureImageActionFormData,
    ) => FormData;
    options?: Partial<
        ApiRequestOptions<void, "users/uploadUserSignatureImage">
    >;
}

export const uploadUserSignatureImage = makeAction((
    { routeParams, formParams, formTransform, options }:
        UploadUserSignatureImageActionRequest,
) => apiPost<void, "users/uploadUserSignatureImage">(
    "users/uploadUserSignatureImage",
    `users/${routeParams.userId}/signature-image`,
    (formTransform || makeFormData)(formParams, {
        file: "Append",
    }),
    { contentType: null, ...options },
), "users/uploadUserSignatureImage");
