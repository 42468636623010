import { Form } from "@redriver/cinnamon-mui";
import { getContracts } from "./actions";

const ContractTypeAhead = ({
  clientId,
  includeExpired = false,
  contractId,
  enquiryId,
  status = [],
  ...props
}) => (
  <Form.TypeAhead
    {...props}
    lookupAction={getContracts}
    lookupParams={{ clientId, includeExpired, contractId, status, enquiryId }}
  />
);

export default ContractTypeAhead;
