import { styled } from "@mui/material";

// manipulation function to prevent build minification obscuring
// raw config value when using deployment string substitution
const getEnvironmentBanner = (value) => {
  if (typeof value === "string") {
    return value.trim();
  }
  return null;
};

const BannerLabel = styled("div")`
  background-color: red;
  border: 1px solid white;
  color: white;
  display: block;
  font-size: 20px;
  font-weight: 700;
  height: 36px;
  line-height: 30px;
  opacity: 0.6;
  position: fixed;
  left: -130px;
  text-align: center;
  bottom: 50px;
  transform: rotate(45deg);
  width: 400px;
  z-index: 10000;
  pointer-events: none;
`;

const EnvironmentBanner = () => {
  const banner = getEnvironmentBanner(process.env.ENVIRONMENT_BANNER);
  if (!banner) return null;
  return <BannerLabel>{banner}</BannerLabel>;
};

export default EnvironmentBanner;
