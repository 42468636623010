import { useDispatch } from "react-redux";
import { Stack } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {
  FormBuilder,
  Form,
  ErrorMessage,
  useAuthClaims,
  notifySuccess,
} from "@redriver/cinnamon-mui";
import { SubmitButton } from "components/buttons";
import { requestPasswordChange } from "./actions";

const passwordErrors = {
  911003: "Incorrect old password",
};

const PasswordChange = () => {
  const { userEmail } = useAuthClaims();
  const dispatch = useDispatch();

  return (
    <FormBuilder
      submitAction={requestPasswordChange}
      submitParams={userEmail}
      onSubmitted={() =>
        dispatch(notifySuccess("Your password has been updated"))
      }
      renderSubmitting={false}
      renderError={false}
      renderForm={(
        formProps,
        { submitting, slowSubmitting, error },
        events
      ) => (
        <Form {...formProps}>
          <Form.Password
            field="oldPassword"
            label="Old Password"
            disabled={submitting && slowSubmitting}
            fluid
            required
            inputProps={{ autoComplete: "current-password" }}
          />
          <Form.Password
            field="newPassword"
            label="New Password"
            disabled={submitting && slowSubmitting}
            fluid
            required
            minLength={8}
            inputProps={{ autoComplete: "new-password" }}
          />
          <Form.Password
            field="confirmPassword"
            label="Confirm New Password"
            disabled={submitting && slowSubmitting}
            fluid
            required
            confirmField="newPassword"
            inputProps={{ autoComplete: "new-password" }}
          />
          <Stack spacing={4}>
            <SubmitButton
              startIcon={<CheckIcon />}
              variant="contained"
              onClick={() => events.onSubmit({ reset: ["data"] })}
              submitting={submitting}
              slowSubmitting={slowSubmitting}
            >
              Update Password
            </SubmitButton>
            <ErrorMessage error={error} overrideMessages={passwordErrors} />
          </Stack>
        </Form>
      )}
    />
  );
};

export default PasswordChange;
