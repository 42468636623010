import { Form } from "@redriver/cinnamon-mui";
import { getEnquiryScopes } from "./actions";

const EnquiryScopeTypeAhead = ({ enquiryId, ...props }) => (
  <Form.TypeAhead
    {...props}
    lookupAction={getEnquiryScopes}
    lookupParams={{ enquiryId }}
  />
);

export default EnquiryScopeTypeAhead;
