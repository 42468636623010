import { Form } from "@redriver/cinnamon-mui";
import { RecipientOption, enumOptionsArray } from "constants/enums";
const SendInvoiceToForm = ({ invoiceToField, invoiceRecipientField }) => (
  <Form.Group columns={2}>
    <Form.RadioGroup
      label="Send Invoices to?"
      field={invoiceToField}
      options={[
        { value: true, label: "Yes" },
        { value: false, label: "No" },
      ]}
      inline
      fluid
    />
    <Form.RadioGroup
      label="Include Recipient As"
      field={invoiceRecipientField}
      options={enumOptionsArray(RecipientOption)}
      inline
      fluid
    />
  </Form.Group>
);

export default SendInvoiceToForm;
