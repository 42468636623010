import React from "react";
import DocIcon from "assets/icons/files/Doc";
import PdfIcon from "assets/icons/files/Pdf";
import PptIcon from "assets/icons/files/Ppt";
import XlsIcon from "assets/icons/files/Xls";
import ImageIcon from "assets/icons/files/Image";
import GenericFileIcon from "assets/icons/files/File";
import { getFileExtension } from "modules/helpers";

const getIconFromExtension = (extension) => {
  const ext = extension?.toLowerCase().replace(".", "");
  switch (ext) {
    case "doc":
    case "docx":
      return DocIcon;

    case "pdf":
      return PdfIcon;

    case "ppt":
    case "pptx":
      return PptIcon;

    case "xls":
    case "xlsx":
      return XlsIcon;

    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "bmp":
      return ImageIcon;

    default:
      return GenericFileIcon;
  }
};

const FileIcon = React.forwardRef(({ fileName, extension, ...props }, ref) => {
  if (fileName && !extension) extension = getFileExtension(fileName);
  const Icon = getIconFromExtension(extension);
  return <Icon {...props} ref={ref} />;
});

export default FileIcon;
