import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  styled,
} from "@mui/material";
import { Form, FormFile, useFormField } from "@redriver/cinnamon-mui";
import { FileSizeError } from "components/forms";
import { FileIcon } from "components/icons";
import CharacterLimitTrigger from "components/triggers/CharacterLimitTrigger";
import { stripFileExtension } from "modules/helpers";
import { Fragment, useState } from "react";
import classNames from "classnames";
import DeleteIcon from "assets/icons/Delete";

const BulkFileUploader = ({
  className,
  isMultiple = true,
  fileRequired = false,
}) => {
  const [activeComments, setActiveComments] = useState({});
  const [allCommentsActive, setAllCommentsActive] = useState(false);
  const [useBulkComments] = useFormField("useBulkComments");
  return (
    <Fragment>
      <Form.Checkbox label="Use Bulk Comments" field="useBulkComments" />
      <Form.Trigger
        field="useBulkComments"
        event="onChange"
        target="comments"
        action="clear"
      />
      <Form.TextArea
        label="Bulk Comments"
        field="comments"
        fluid
        disabled={!useBulkComments}
        rows={4}
      />
      <FormFile
        field="upload"
        multiple={isMultiple}
        dropzone
        required={fileRequired}
        fluid
      />
      <FileSizeError field="upload" maxSizeMB={100} />
      <Form.Trigger
        field="upload"
        event="onChange"
        target="files"
        action="change"
        value={({ fields }) => {
          if (!fields["upload"] || fields["upload"].length === 0) {
            return fields["files"];
          }
          return [
            ...(fields["files"] || []),
            ...fields["upload"].map((f, i) => ({
              key: Date.now() + i,
              name: stripFileExtension(f.name),
              file: f,
            })),
          ];
        }}
      />
      <Form.Trigger
        field={"files"}
        event="onChange"
        action={({ fields }) => {
          if (allCommentsActive) return;
          const allActive = fields["upload"].every(
            (x) => activeComments[x.key]
          );
          setAllCommentsActive(allActive);
        }}
      />
      <Accordion
        sx={{ boxShadow: "none", position: "static" }}
        disableGutters
        className={classNames(className, { expandable: true })}
      >
        <Form.Array
          field="files"
          arrayKey="key"
          propagateUpdates="always"
          maxLength={20}
          maxLengthError="A maximum of 20 files can be uploaded at a time"
          label={
            <Form.If
              condition={({ fields }) =>
                fields["files"]?.length && !useBulkComments
              }
            >
              <a
                style={{
                  fontSize: "1.4em",
                  fontWeight: "bold",
                  color: "black",
                }}
                onClick={() => {
                  setActiveComments({});
                  setAllCommentsActive(!allCommentsActive);
                }}
              >
                {allCommentsActive ? "Hide Comments" : "Show All Comments"}
              </a>
            </Form.If>
          }
        >
          {({ fields, parentFields }) => (
            <Fragment>
              <AccordionSummary
                className="file-row"
                onClick={() => {
                  if (allCommentsActive) return;
                  setActiveComments({ [fields?.key]: true });
                  setAllCommentsActive(parentFields["files"].length === 1);
                }}
              >
                <FileIcon className="file-icon" fileName={fields?.file?.path} />
                <Form.Input
                  className="name-field"
                  field="name"
                  placeholder="Name"
                  fluid
                  required
                />
                <Form.ArrayRemover as={IconButton} className="icon">
                  <DeleteIcon color="primary" />
                </Form.ArrayRemover>
                <CharacterLimitTrigger field="name" limit={200} />
              </AccordionSummary>

              {(activeComments[fields?.key] || allCommentsActive) &&
                !useBulkComments && (
                  <AccordionDetails
                    active={activeComments[fields?.key] || allCommentsActive}
                    sx={{ paddingTop: 0 }}
                  >
                    <Form.TextArea
                      className="file-comments"
                      field="comments"
                      placeholder="Comments"
                      fluid
                      rows={4}
                    />
                  </AccordionDetails>
                )}
            </Fragment>
          )}
        </Form.Array>
      </Accordion>
    </Fragment>
  );
};

export default styled(BulkFileUploader)({
  ".MuiAccordionSummary-content": {
    ".file-row": {
      display: "flex",
      alignItems: "center",
      marginBottom: 0,
    },
    "> .name-field": {
      flexGrow: 1,
      marginBottom: 0,
      padding: "0 1em 0",
    },
    "> .file-icon": {
      marginTop: "0.75em",
      marginLeft: "-0.75em",
    },
    "> .icon:last-child": {
      margin: "0 -1em 0 -0.5em",
    },
  },
  ".MuiAccordionDetails-root": {
    ".file-comments": {
      padding: 0,
    },
  },
});
