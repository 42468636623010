import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 22.928 30.57"
  >
    <g id="shopping-list" transform="translate(0 0)">
      <g id="Group_8056" data-name="Group 8056" transform="translate(5.732 0)">
        <g id="Group_8055" data-name="Group 8055">
          <path
            id="Path_5991"
            data-name="Path 5991"
            d="M170.509,1.911h-2.075a2.867,2.867,0,0,0-5.405,0h-2.073a.956.956,0,0,0-.955.955V6.687a.956.956,0,0,0,.955.955h9.553a.956.956,0,0,0,.955-.955V2.866A.956.956,0,0,0,170.509,1.911Z"
            transform="translate(-160)"
            fill="#1d7894"
          />
        </g>
      </g>
      <g id="Group_8058" data-name="Group 8058" transform="translate(0 3.822)">
        <g id="Group_8057" data-name="Group 8057">
          <path
            id="Path_5992"
            data-name="Path 5992"
            d="M85.017,64H83.106v2.866a2.869,2.869,0,0,1-2.866,2.866H70.687a2.869,2.869,0,0,1-2.866-2.866V64H65.911A1.914,1.914,0,0,0,64,65.911V88.838a1.89,1.89,0,0,0,1.911,1.911H85.017a1.89,1.89,0,0,0,1.911-1.911V65.911A1.89,1.89,0,0,0,85.017,64ZM75.185,80.917l-3.821,3.821a.959.959,0,0,1-1.353,0L68.1,82.827a.955.955,0,0,1,1.351-1.351l1.236,1.234,3.145-3.145a.956.956,0,0,1,1.353,1.351Zm0-7.643L71.364,77.1a.959.959,0,0,1-1.353,0L68.1,75.185a.955.955,0,0,1,1.351-1.351l1.236,1.234,3.145-3.145a.956.956,0,0,1,1.353,1.351Zm6.966,9.832H78.33a.955.955,0,0,1,0-1.911h3.821a.955.955,0,0,1,0,1.911Zm0-7.643H78.33a.955.955,0,1,1,0-1.911h3.821a.955.955,0,1,1,0,1.911Z"
            transform="translate(-64 -64)"
            fill="#bde0f1"
          />
        </g>
      </g>
    </g>
  </svg>,
  "WorkOrderReport"
);
