import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="file_1_" data-name="file (1)" transform="translate(-1)">
      <path
        id="Path_4791"
        data-name="Path 4791"
        d="M133.706,0H123.118A2.114,2.114,0,0,0,121,2.109V21.891A2.114,2.114,0,0,0,123.118,24h14.118a2.114,2.114,0,0,0,2.118-2.109V5.625Z"
        transform="translate(-114.353)"
        fill="#bde0f1"
      />
      <path
        id="Path_4793"
        data-name="Path 4793"
        d="M391,0V4.922a.7.7,0,0,0,.706.7h4.941Z"
        transform="translate(-371.647)"
        fill="#77bee2"
      />
      <g
        id="Group_7467"
        data-name="Group 7467"
        transform="translate(9.941 8.531)"
      >
        <path
          id="Path_4794"
          data-name="Path 4794"
          d="M202.059,273.406H191.706a.7.7,0,1,1,0-1.406h10.353a.7.7,0,1,1,0,1.406Z"
          transform="translate(-191 -267.781)"
          fill="#082a4e"
        />
        <path
          id="Path_4795"
          data-name="Path 4795"
          d="M202.059,183.406H191.706a.7.7,0,1,1,0-1.406h10.353a.7.7,0,1,1,0,1.406Z"
          transform="translate(-191 -182)"
          fill="#082a4e"
        />
        <path
          id="Path_4796"
          data-name="Path 4796"
          d="M202.059,363.406H191.706a.7.7,0,1,1,0-1.406h10.353a.7.7,0,1,1,0,1.406Z"
          transform="translate(-191 -353.563)"
          fill="#082a4e"
        />
      </g>
      <path
        id="Path_4800"
        data-name="Path 4800"
        d="M7.353,254.656a6.328,6.328,0,1,1,6.353-6.328A6.348,6.348,0,0,1,7.353,254.656Z"
        transform="translate(0 -230.656)"
        fill="#05c031"
      />
      <path
        id="Path_4802"
        data-name="Path 4802"
        d="M75.91,335.207a.708.708,0,0,0-1,0l-2.795,2.784-.913-.909a.7.7,0,1,0-1,.994l1.412,1.406a.708.708,0,0,0,1,0L75.91,336.2A.7.7,0,0,0,75.91,335.207Z"
        transform="translate(-65.752 -319.298)"
        fill="#fff"
      />
    </g>
  </svg>,
  "ReadyToInvoice"
);
