import { Link } from "react-router-dom";

const TableRowLink = (props) => (
  <Link
    {...props}
    style={{
      textDecoration: "none",
      color: "initial",
      height: "100%",
    }}
  />
);

export default TableRowLink;
