import { IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const VisibilityToggle = ({ visible = undefined, ...props }) => (
  <IconButton sx={{ mx: -1, opacity: 0.7 }} {...props}>
    {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
  </IconButton>
);

export default VisibilityToggle;
