import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button } from "@mui/material";
import { useGlobalSearchQuery } from "features/GlobalSearch";
import SearchIcon from "@mui/icons-material/Search";
import { AppRoutes } from "constants/routes";

const GlobalSearch = () => {
  const navigate = useNavigate();
  const query = useGlobalSearchQuery();
  const [search, setSearch] = useState(query);

  useEffect(() => {
    setSearch(query);
  }, [query]);

  const onFindResults = () =>
    search && navigate(AppRoutes.GlobalSearch(search));

  return (
    <TextField
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      onKeyDown={(e) => e.key === "Enter" && onFindResults()}
      placeholder="Search..."
      InputProps={{
        endAdornment: (
          <Button
            variant="roundIcon"
            size="large"
            sx={{ ml: 1 }}
            onClick={onFindResults}
          >
            <SearchIcon />
          </Button>
        ),
      }}
      sx={(theme) => ({
        width: "16rem",
        ".MuiInputBase-root": {
          borderRadius: "48px",
          pr: "4px",
        },
        ".MuiInputBase-input": { py: "12px", pl: "16px" },
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.primary.main,
        },
      })}
    />
  );
};

export default GlobalSearch;
