import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 20.926 26.389"
  >
    <g id="report" transform="translate(-53)">
      <g id="Group_7979" data-name="Group 7979" transform="translate(53 1.446)">
        <path
          id="Path_5892"
          data-name="Path 5892"
          d="M71.606,53H55.319A2.324,2.324,0,0,1,53,50.674v-20.3a2.324,2.324,0,0,1,2.319-2.324H71.606a2.324,2.324,0,0,1,2.319,2.324v20.3A2.324,2.324,0,0,1,71.606,53Z"
          transform="translate(-53 -28.055)"
          fill="#bde0f1"
        />
      </g>
      <path
        id="Path_5893"
        data-name="Path 5893"
        d="M263.2,28.055H255V53h8.2a2.324,2.324,0,0,0,2.319-2.324v-20.3A2.324,2.324,0,0,0,263.2,28.055Z"
        transform="translate(-191.589 -26.609)"
        fill="#bde0f1"
      />
      <g
        id="Group_7980"
        data-name="Group 7980"
        transform="translate(56.917 15.166)"
      >
        <path
          id="Path_5894"
          data-name="Path 5894"
          d="M130.052,297.252l-.825-.826a.774.774,0,1,1,1.093-1.1l.278.279,1.137-1.139a.774.774,0,0,1,1.093,1.1l-1.684,1.687A.772.772,0,0,1,130.052,297.252Z"
          transform="translate(-129 -294.242)"
          fill="#05c031"
        />
      </g>
      <g
        id="Group_7981"
        data-name="Group 7981"
        transform="translate(62.174 16.009)"
      >
        <path
          id="Path_5895"
          data-name="Path 5895"
          d="M238.01,312.156h-6.236a.775.775,0,0,1,0-1.549h6.236a.775.775,0,0,1,0,1.549Z"
          transform="translate(-231 -310.607)"
          fill="#fff"
        />
      </g>
      <g id="Group_7982" data-name="Group 7982" transform="translate(57.201)">
        <path
          id="Path_5896"
          data-name="Path 5896"
          d="M146.251,4.958H135.273a.774.774,0,0,1-.773-.775A4.184,4.184,0,0,1,138.675,0h4.175a4.184,4.184,0,0,1,4.175,4.183A.774.774,0,0,1,146.251,4.958Z"
          transform="translate(-134.5)"
          fill="#207d9a"
        />
      </g>
      <g
        id="Group_7983"
        data-name="Group 7983"
        transform="translate(56.917 19.607)"
      >
        <path
          id="Path_5897"
          data-name="Path 5897"
          d="M130.052,383.42l-.825-.826a.774.774,0,1,1,1.093-1.1l.278.279,1.137-1.139a.774.774,0,0,1,1.093,1.1l-1.684,1.687A.772.772,0,0,1,130.052,383.42Z"
          transform="translate(-129 -380.411)"
          fill="#05c031"
        />
      </g>
      <g
        id="Group_7984"
        data-name="Group 7984"
        transform="translate(62.174 20.45)"
      >
        <path
          id="Path_5898"
          data-name="Path 5898"
          d="M238.01,398.325h-6.236a.775.775,0,0,1,0-1.549h6.236a.775.775,0,0,1,0,1.549Z"
          transform="translate(-231 -396.776)"
          fill="#fff"
        />
      </g>
      <path
        id="Path_5899"
        data-name="Path 5899"
        d="M159.112,143.279c-.064,0,.225,0-2.2,0a.854.854,0,0,0,0,1.709h.214l-1.685,1.689-2.34-2.344a.772.772,0,0,0-1.093,0l-2.783,2.789a.774.774,0,1,0,1.093,1.1l2.237-2.241,2.34,2.344a.772.772,0,0,0,1.093,0l2.232-2.236v.215a.853.853,0,1,0,1.705,0v-2.164A.778.778,0,0,0,159.112,143.279Z"
        transform="translate(-91.052 -135.893)"
        fill="#05c031"
      />
      <path
        id="Path_5900"
        data-name="Path 5900"
        d="M261.546,311.382a.774.774,0,0,0-.773-.775H255v1.549h5.773A.774.774,0,0,0,261.546,311.382Z"
        transform="translate(-191.589 -294.598)"
        fill="#fff"
      />
      <path
        id="Path_5901"
        data-name="Path 5901"
        d="M260.773,396.775H255v1.549h5.773a.775.775,0,0,0,0-1.549Z"
        transform="translate(-191.589 -376.325)"
        fill="#fff"
      />
      <path
        id="Path_5902"
        data-name="Path 5902"
        d="M259.649,143.279c-.064,0,.225,0-2.2,0a.854.854,0,0,0,0,1.709h.214l-1.685,1.689-.979-.981v2.191l.433.433a.772.772,0,0,0,1.093,0l2.232-2.236v.215a.853.853,0,1,0,1.705,0v-2.164A.778.778,0,0,0,259.649,143.279Z"
        transform="translate(-191.589 -135.893)"
        fill="#05c031"
      />
      <path
        id="Path_5903"
        data-name="Path 5903"
        d="M257.139,0H255V4.958h5.541a.774.774,0,0,0,.773-.775A4.184,4.184,0,0,0,257.139,0Z"
        transform="translate(-191.589)"
        fill="#207d9a"
      />
    </g>
  </svg>,
  "EnquiryReport"
);
