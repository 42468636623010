import { Box, styled } from "@mui/material";

const ScrollableContent = (props) => <Box {...props} />;

export default styled(ScrollableContent)(({ theme }) => ({
  overflowY: "auto",
  maxHeight: "100%",
  "::-webkit-scrollbar": {
    width: "10px",
  },
  "::-webkit-scrollbar-track": {
    backgroundColor: theme.palette.background.info,
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
    border: "2px solid transparent",
    backgroundClip: "content-box",
  },
  "::-webkit-scrollbar-thumb:hover": {
    backgroundColor: theme.palette.primary.main,
  },
}));
