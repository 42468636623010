// Auto Generated file, do not modify
import {
    apiDelete,
    apiGet,
    apiPost,
    apiPut,
    ApiRequestOptions,
} from "@redriver/cinnamon-mui";
import { GlobalSearchView } from "mortar/EnergyEngineering/Logic/GlobalSearch/Views";
import { PagedView } from "mortar/EnergyEngineering/Logic/Views";
import { makeAction, makeFormData } from "../lib";

export interface GlobalSearchActionRequest {
    queryParams: {
        search: string;
        pageSize: number;
        pageNumber: number;
    };
    options?: Partial<
        ApiRequestOptions<
            PagedView<GlobalSearchView>,
            "globalSearch/globalSearch"
        >
    >;
}

export const globalSearch = makeAction((
    { queryParams, options }: GlobalSearchActionRequest,
) => apiGet<PagedView<GlobalSearchView>, "globalSearch/globalSearch">(
    "globalSearch/globalSearch",
    `global-search`,
    queryParams,
    options,
), "globalSearch/globalSearch");
