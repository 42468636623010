import { Link as RouterLink, useMatch } from "react-router-dom";
import { RoutingUtils } from "@redriver/cinnamon-mui";
import { MenuItem, ListItemIcon, ListItemText, styled } from "@mui/material";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";

const NavMenuItem = ({
  active,
  link = "",
  exact,
  icon,
  className,
  children,
}) => {
  const linkPath = exact ? link : RoutingUtils.subRoutesPath(link);
  const linkMatch = useMatch(linkPath);
  return (
    <MenuItem
      component={RouterLink}
      to={link}
      selected={active !== undefined ? active : !!(link && linkMatch)}
      disableRipple
      className={className}
    >
      {icon && <ListItemIcon className="icon">{icon}</ListItemIcon>}
      <ListItemText>{children}</ListItemText>
      <ListItemIcon className="arrow">
        <ArrowForwardRoundedIcon />
      </ListItemIcon>
    </MenuItem>
  );
};

export default styled(NavMenuItem)(({ theme }) => ({
  borderRadius: 12,
  padding: "0.8em 1em",
  marginBottom: "0.25em",
  ".MuiTypography-root": {
    fontSize: "1.15rem",
  },
  "> .icon": {
    paddingRight: "0.8em",
    ".MuiSvgIcon-root": {
      fontSize: "1.8rem",
    },
  },
  "> .arrow": {
    color: theme.palette.primary.dark,
    marginLeft: "auto",
    minWidth: "unset",
    paddingLeft: "1em",
    opacity: 0,
  },
  "&:last-of-type": {
    marginBottom: 0,
  },
  "&.MuiSelected": {
    backgroundColor: theme.palette.menu.main,
  },
  "&:hover": {
    backgroundColor: `${theme.palette.menu.item} !important`,
    "> .arrow": {
      opacity: 1,
    },
  },
}));
