import { Form } from "@redriver/cinnamon-mui";
import { getJobs } from "./actions";

const JobTypeAhead = ({ contractId, ...props }) => (
  <Form.TypeAhead
    {...props}
    lookupAction={getJobs}
    lookupParams={{ contractId }}
  />
);

export default JobTypeAhead;
