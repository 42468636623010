import { useLocation } from "react-router-dom";
import { Routing, useLoggedIn } from "@redriver/cinnamon-mui";
import { AzureAdSignIn } from "features/System";
import { SystemPageContent } from "components/pages";
import { AppRoutes } from "constants/routes";

const AzureAdSignInPage = () => {
  const loggedIn = useLoggedIn();
  const location = useLocation();

  const query = new URLSearchParams(location.search);
  const code = query.get("code");
  const state = query.get("state");

  if (loggedIn) {
    const nextRoute = state ? decodeURIComponent(state) : AppRoutes.Root;
    return (
      <Routing>
        <Routing.Redirect
          path="*"
          to={{
            pathname: nextRoute,
            state: { fromLogin: true },
          }}
        />
      </Routing>
    );
  }
  return (
    <SystemPageContent title="Logging you in...">
      <AzureAdSignIn code={code} />
    </SystemPageContent>
  );
};

export default AzureAdSignInPage;
