import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 13 17"
  >
    <g transform="translate(0)">
      <path
        id="Path_4599"
        data-name="Path 4599"
        d="M94.041,4.095a4.323,4.323,0,0,1-1.026,2.9,3.164,3.164,0,0,1-2.477,1.2h0a3.165,3.165,0,0,1-2.475-1.2,4.322,4.322,0,0,1-1.026-2.9A4.322,4.322,0,0,1,88.061,1.2,3.163,3.163,0,0,1,90.537,0h0a3.165,3.165,0,0,1,2.477,1.2,4.322,4.322,0,0,1,1.026,2.895Zm0,0"
        transform="translate(-84.145 0)"
        fill="#ffbb85"
      />
      <path
        id="Path_4600"
        data-name="Path 4600"
        d="M13,253.184a2.962,2.962,0,0,1-.769,2.136,2.637,2.637,0,0,1-1.98.788h-7.5a2.635,2.635,0,0,1-1.98-.788A2.962,2.962,0,0,1,0,253.184c0-.341.01-.679.031-1s.064-.693.126-1.075a9.124,9.124,0,0,1,.242-1.08,5.615,5.615,0,0,1,.407-1.007,3.793,3.793,0,0,1,.613-.872,2.686,2.686,0,0,1,.882-.6,2.824,2.824,0,0,1,1.125-.222,1.089,1.089,0,0,1,.61.282c.186.132.4.283.636.447a3.487,3.487,0,0,0,.822.4,3.032,3.032,0,0,0,.906.175l.1,0a3.021,3.021,0,0,0,1.006-.177,3.487,3.487,0,0,0,.822-.4c.239-.166.453-.317.636-.447a1.088,1.088,0,0,1,.61-.282,2.825,2.825,0,0,1,1.125.222,2.689,2.689,0,0,1,.881.6,3.78,3.78,0,0,1,.614.872,5.6,5.6,0,0,1,.407,1.007,9.117,9.117,0,0,1,.242,1.08c.062.382.105.743.126,1.074s.031.661.032,1Zm0,0"
        transform="translate(0 -239.108)"
        fill="#207d9a"
      />
      <path
        id="Path_4601"
        data-name="Path 4601"
        d="M210.3,8.189h0V0h0a3.963,3.963,0,0,1,2.9,1.2,3.962,3.962,0,0,1,1.2,2.895,3.962,3.962,0,0,1-1.2,2.9,3.962,3.962,0,0,1-2.9,1.2Zm0,0"
        transform="translate(-204.13)"
        fill="#ffbb85"
      />
      <path
        id="Path_4602"
        data-name="Path 4602"
        d="M217.494,253.185a2.822,2.822,0,0,1-.839,2.136,3.018,3.018,0,0,1-2.16.788h-4.2V248.62l.109,0a3.566,3.566,0,0,0,1.1-.177,3.958,3.958,0,0,0,.9-.4c.26-.166.494-.317.694-.447a1.243,1.243,0,0,1,.665-.282,3.327,3.327,0,0,1,1.228.222,2.955,2.955,0,0,1,.962.6,3.8,3.8,0,0,1,.67.872,5.337,5.337,0,0,1,.444,1.007,8.472,8.472,0,0,1,.264,1.08,10.147,10.147,0,0,1,.137,1.074c.023.324.034.661.035,1Zm0,0"
        transform="translate(-204.493 -239.109)"
        fill="#207d9a"
      />
    </g>
  </svg>,
  "User"
);
