import { Form, useLookup } from "@redriver/cinnamon-mui";
import {
  DisciplineCheckboxGroup,
  DisciplineTypeCheckboxGroup,
  IndustryCheckboxGroup,
  ResourceRoleCheckboxGroup,
} from "components/forms";
import {
  resourceRolesLookup,
  industriesLookup,
  disciplinesLookup,
  disciplineTypesLookup,
} from "modules/lookups";

export const ExperienceForm = ({
  formProps,
  allowOtherDescriptions = false,
}) => {
  const [{ response: roleOptions }] = useLookup(resourceRolesLookup);
  const [{ response: industryOptions }] = useLookup(industriesLookup);
  const [{ response: disciplineOptions }] = useLookup(disciplinesLookup);
  const [{ response: disciplineTypeOptions }] = useLookup(
    disciplineTypesLookup
  );

  const otherRoleId =
    allowOtherDescriptions && roleOptions?.find((x) => x.isOther)?.value;
  const otherIndustryId =
    allowOtherDescriptions && industryOptions?.find((x) => x.isOther)?.value;
  const otherDisciplineId =
    allowOtherDescriptions && disciplineOptions?.find((x) => x.isOther)?.value;
  const otherDisciplineTypeId =
    allowOtherDescriptions &&
    disciplineTypeOptions?.find((x) => x.isOther)?.value;

  return (
    <Form {...formProps}>
      <Form.Numeric
        label="Total Years Experience"
        field="totalYearsExperience"
        minValue={0}
        maxValue={99}
        fluid
      />
      <Form.Group columns={2}>
        <Form.Field>
          <ResourceRoleCheckboxGroup label="Roles" field="roleIds" fluid />
          <Form.If
            condition={({ fields }) =>
              otherRoleId && fields.roleIds?.includes(otherRoleId)
            }
          >
            <Form.Input
              field="otherRolesDescription"
              label="Other Roles Description"
              fluid
            />
          </Form.If>
        </Form.Field>
        <Form.Field>
          <IndustryCheckboxGroup label="Industries" field="industryIds" fluid />
          <Form.If
            condition={({ fields }) =>
              otherIndustryId && fields.industryIds?.includes(otherIndustryId)
            }
          >
            <Form.Input
              field="otherIndustriesDescription"
              label="Other Industries Description"
              fluid
            />
          </Form.If>
        </Form.Field>
      </Form.Group>
      <Form.Group columns={2}>
        <Form.Field>
          <DisciplineCheckboxGroup
            label="Disciplines"
            field="disciplineIds"
            fluid
          />
          <Form.If
            condition={({ fields }) =>
              otherDisciplineId &&
              fields.disciplineIds?.includes(otherDisciplineId)
            }
          >
            <Form.Input
              field="otherDisciplinesDescription"
              label="Other Disciplines Description"
              fluid
            />
          </Form.If>
        </Form.Field>
        <Form.Field>
          <DisciplineTypeCheckboxGroup label="Types" field="typeIds" fluid />
          <Form.If
            condition={({ fields }) =>
              otherDisciplineTypeId &&
              fields.typeIds?.includes(otherDisciplineTypeId)
            }
          >
            <Form.Input
              field="otherTypesDescription"
              label="Other Types Description"
              fluid
            />
          </Form.If>
        </Form.Field>
      </Form.Group>
    </Form>
  );
};

export default ExperienceForm;
