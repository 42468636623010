import { registerLookup } from "@redriver/cinnamon-mui";
import * as actions from "./actions";

export const resourceRolesLookup = registerLookup(
  "resourceRolesLookup",
  actions.getResourceRoles,
  {
    cacheExpiry: 600 * 1000,
  }
);

export const industriesLookup = registerLookup(
  "industriesLookup",
  actions.getIndustries,
  {
    cacheExpiry: 600 * 1000,
  }
);

export const disciplinesLookup = registerLookup(
  "disciplinesLookup",
  actions.getDisciplines,
  {
    cacheExpiry: 600 * 1000,
  }
);

export const disciplineTypesLookup = registerLookup(
  "disciplineTypesLookup",
  actions.getDisciplineTypes,
  {
    cacheExpiry: 600 * 1000,
  }
);

export const billingAreasLookup = registerLookup(
  "billingAreasLookup",
  actions.getBillingAreas
);

export const distanceUnitsLookups = registerLookup(
  "distanceUnitsLookups",
  actions.getDistanceUnits
);

export const resourceStatusLookup = registerLookup(
  "resourceStatusLookup",
  actions.getResourceStatus
);

export const countriesLookup = registerLookup(
  "countriesLookup",
  actions.getCountries,
  {
    cacheExpiry: 60 * 1000,
  }
);

export const resourceTypesLookup = registerLookup(
  "resourceTypesLookup",
  actions.getResourceTypes
);

export const officeTypesLookup = registerLookup(
  "officeTypesLookup",
  actions.getOfficeTypes
);

export const epcContractorsLookup = registerLookup(
  "epcContractorsLookup",
  actions.getEpcContractors
);

export const officesLookup = registerLookup(
  "officesLookup",
  actions.getOffices,
  {
    cacheExpiry: 60 * 1000,
  }
);

export const currenciesLookup = registerLookup(
  "currenciesLookup",
  actions.getCurrencies,
  {
    cacheExpiry: 60 * 1000,
  }
);

export const enquiryStatusLookup = registerLookup(
  "enquiryStatusLookup",
  actions.getEnquiryStatus
);

export const enquiryScopeStatusLookup = registerLookup(
  "enquiryScopeStatusLookup",
  actions.getEnquiryScopeStatus
);

export const serviceTypesLookup = registerLookup(
  "serviceTypesLookup",
  actions.getServiceTypes
);

export const enquiryDeadlinesLookup = registerLookup(
  "enquiryDeadlinesLookup",
  actions.getEnquiryDeadlines
);

export const enquiryVisibilityLookup = registerLookup(
  "enquiryVisibilityLookup",
  actions.getEnquiryVisibility
);

export const daysOfWeekLookup = registerLookup(
  "daysOfWeekLookup",
  actions.getDaysOfWeek
);

export const regionsLookup = registerLookup(
  "regionsLookup",
  actions.getRegions
);

export const documentCategoriesLookup = registerLookup(
  "documentCategoriesLookup",
  ({ viewType, categoryIds, excludeCategoryIds }) =>
    actions.getDocumentCategories({ viewType, categoryIds, excludeCategoryIds })
);

export const projectTypesLookup = registerLookup(
  "projectTypesLookup",
  actions.getProjectTypes
);

export const clientTypesLookup = registerLookup(
  "clientTypesLookup",
  actions.getClientTypes
);

export const clientStatusLookup = registerLookup(
  "clientStatusLookup",
  actions.getClientStatus
);

export const agreementTypesLookup = registerLookup(
  "agreementTypesLookup",
  actions.getAgreementTypes
);

export const serviceContractTypesLookup = registerLookup(
  "serviceContractTypesLookup",
  ({ descriptionType }) => actions.getServiceContractTypes(descriptionType)
);

export const jobStatusLookup = registerLookup(
  "jobStatusLookup",
  actions.getJobStatus
);

export const shortlistedResourceAvailabilityLookup = registerLookup(
  "shortlistedResourceAvailabilityLookup",
  actions.getShortlistedResourceAvailability
);

export const contactMethodLookup = registerLookup(
  "contactMethodLookup",
  actions.getContactMethods
);

export const coordinatorUsersLookup = registerLookup(
  "coordinatorUsersLookup",
  actions.getCoordinatorUsers,
  {
    cacheExpiry: 60 * 1000,
  }
);

export const qualityUsersLookup = registerLookup(
  "qualityUsersLookup",
  actions.getQualityUsers,
  {
    cacheExpiry: 60 * 1000,
  }
);

export const internalUsersLookup = registerLookup(
  "internalUsersLookup",
  actions.getInternalUsers,
  {
    cacheExpiry: 60 * 1000,
  }
);

export const reportingScheduleLookup = registerLookup(
  "reportingScheduleLookup",
  actions.getReportingSchedules
);

export const invoiceGroupingLevelsLookup = registerLookup(
  "invoiceGroupingLevelsLookup",
  actions.getInvoiceGroupingLevels
);

export const billingFrequenciesLookup = registerLookup(
  "billingFrequenciesLookup",
  actions.getBillingFrequencies
);

export const actionStatusLookup = registerLookup(
  "actionStatusLookup",
  actions.getActionStatus
);

export const actionPrioritiesLookup = registerLookup(
  "actionPrioritiesLookup",
  actions.getActionPriorities
);

export const actionLinkTypesLookup = registerLookup(
  "actionLinkTypesLookup",
  actions.getActionLinkTypes
);

export const endUsersLookup = registerLookup(
  "endUsersLookup",
  actions.getEndUsers
);

export const requestedCorrectionsLookup = registerLookup(
  "requestedCorrectionsLookup",
  actions.getRequestedCorrectionTypes
);

export const resourceBackupTypesLookup = registerLookup(
  "resourceBackupTypesLookup",
  actions.getResourceBackupTypes
);

export const nonComplianceReportTypesLookup = registerLookup(
  "nonComplianceReportTypesLookup",
  actions.getNonComplianceReportTypes,
  {
    cacheExpiry: 600 * 1000,
  }
);

export const nonComplianceReportCategoriesLookup = registerLookup(
  "nonComplianceReportCategoriesLookup",
  actions.getNonComplianceReportCategories
);

export const nonComplianceReportIdentificationsLookup = registerLookup(
  "nonComplianceReportIdentificationsLookup",
  actions.getNonComplianceReportIdentifications
);

export const scopeRejectionReasonsLookup = registerLookup(
  "scopeRejectionReasonsLookup",
  actions.getScopeRejectionReasons
);

export const expenseTypesLookup = registerLookup(
  "expenseTypeLookup",
  actions.getExpenseTypes
);

export const contractStatusLookup = registerLookup(
  "contractStatusLookup",
  actions.getContractStatus
);

export const contractApproverUsersLookup = registerLookup(
  "contractApproverUsersLookup",
  actions.getContractApproverUsers
);

export const resourceDivisionLookup = registerLookup(
  "resourceDivisionLookup",
  actions.getResourceDivisions
);

export const bankPaymentTermsLookup = registerLookup(
  "bankPaymentTermsLookup",
  actions.getBankPaymentTerms
);

export const taxRatesLookup = registerLookup(
  "taxRatesLookup",
  actions.getTaxRates
);

export const officeBankAccountLookup = registerLookup(
  "officeBankAccountLookup",
  actions.getOfficeBankAccounts
);

export const approvalStatusLookup = registerLookup(
  "approvalStatusLookup",
  actions.getApprovalStatus
);

export const contractInvoiceBillingAssignmentOfficeLookup = registerLookup(
  "contractInvoiceBillingAssignmentOfficeLookup",
  ({ search, contractId, selectedOfficeIds }) =>
    actions.getBillingAssignmentOffices({
      search,
      contractId,
      selectedOfficeIds,
    }),
  {
    cache: false,
  }
);
