import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 33.226 33.225"
  >
    <g id="search" transform="translate(0.001 -0.225)">
      <path
        id="Path_4724"
        data-name="Path 4724"
        d="M0,0H3.014V4.018H0Z"
        transform="translate(13 19.026) rotate(45)"
        fill="#e6c315"
      />
      <path
        id="Path_4725"
        data-name="Path 4725"
        d="M0,0H2.009V4.018H0Z"
        transform="translate(12.29 18.316) rotate(45)"
        fill="#e6c315"
      />
      <path
        id="Path_4726"
        data-name="Path 4726"
        d="M134.737,325.52l-1.421-1.421-2.841,1.421,2.841,1.421a1,1,0,1,0,1.421-1.421Z"
        transform="translate(-121.737 -302.229)"
        fill="#ffd478"
      />
      <path
        id="Path_4727"
        data-name="Path 4727"
        d="M129.217,301.628a1,1,0,0,0,0-1.421l-1.421-1.421a1,1,0,0,0-1.421,1.421v1.421H127.8A1,1,0,0,0,129.217,301.628Z"
        transform="translate(-117.638 -278.337)"
        fill="#ffd500"
      />
      <path
        id="Path_4728"
        data-name="Path 4728"
        d="M196.956,283.094l-1.421-1.421-1.421,1.421,1.421,1.421a1,1,0,0,0,1.421-1.421Z"
        transform="translate(-181.115 -262.644)"
        fill="#ffd478"
      />
      <path
        id="Path_4729"
        data-name="Path 4729"
        d="M171.643,259.2a1,1,0,0,0,0-1.421l-1.421-1.421a1,1,0,0,0-1.421,1.421l1.421,1.421A1,1,0,0,0,171.643,259.2Z"
        transform="translate(-157.223 -238.752)"
        fill="#ffd500"
      />
      <path
        id="Path_4730"
        data-name="Path 4730"
        d="M23.877,346.733l-1.421-1.421-9.276,9.276a3.014,3.014,0,0,0,4.262,0l6.435-6.435A1,1,0,0,0,23.877,346.733Z"
        transform="translate(-12.298 -322.021)"
        fill="#207d9a"
        style={{ mixBlendMode: "multiply", isolation: "isolate" }}
      />
      <path
        id="Path_4731"
        data-name="Path 4731"
        d="M3.724,329.277l6.435-6.435a1,1,0,0,0,0-1.421L8.738,320a1,1,0,0,0-1.421,0L.882,326.435a3.014,3.014,0,0,0,0,4.262c.392.392,1.664-.244,2.841-1.421Z"
        transform="translate(0 -298.13)"
        fill="#207d9a"
      />
      <circle
        id="Ellipse_27"
        data-name="Ellipse 27"
        cx="11"
        cy="11"
        r="11"
        transform="translate(11.225 0.225)"
        fill="#ffd500"
      />
      <path
        id="Path_4732"
        data-name="Path 4732"
        d="M332,30V48.082A9.041,9.041,0,1,0,332,30Z"
        transform="translate(-309.766 -27.826)"
        fill="#e5f2ff"
      />
      <path
        id="Path_4733"
        data-name="Path 4733"
        d="M213.073,39.041c0-4.993-3.148-9.041-7.032-9.041a9.041,9.041,0,1,0,0,18.082C209.925,48.082,213.073,44.034,213.073,39.041Z"
        transform="translate(-183.807 -27.826)"
        fill="#fff"
      />
      <g
        id="Group_7160"
        data-name="Group 7160"
        transform="translate(17.106 10.702)"
      >
        <circle
          id="Ellipse_28"
          data-name="Ellipse 28"
          cx="1"
          cy="1"
          r="1"
          transform="translate(8.119 -0.478)"
          fill="#4367b1"
        />
        <circle
          id="Ellipse_29"
          data-name="Ellipse 29"
          cx="1"
          cy="1"
          r="1"
          transform="translate(4.119 -0.478)"
          fill="#4367b1"
        />
        <circle
          id="Ellipse_30"
          data-name="Ellipse 30"
          cx="1"
          cy="1"
          r="1"
          transform="translate(0.119 -0.478)"
          fill="#4367b1"
        />
      </g>
    </g>
  </svg>,
  "Lookups"
);
