import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Button } from "@mui/material";

const TableMenuButton = ({ variant = "contained", ...props }) => (
  <Button
    {...props}
    variant={variant}
    disableElevation
    sx={{
      borderRadius: "50%",
      width: 32,
      height: 32,
      minWidth: "unset",
      zIndex: 1300,
    }}
  >
    <MoreHorizIcon />
  </Button>
);

export default TableMenuButton;
