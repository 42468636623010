import { useAuthClaims, DataList } from "@redriver/cinnamon-mui";

const UserDetails = () => {
  const { userName, userEmail, ssoProvider } = useAuthClaims();
  return (
    <DataList>
      <DataList.Item title="Name">{userName}</DataList.Item>
      <DataList.Item title="Email">{userEmail}</DataList.Item>
      {ssoProvider && (
        <DataList.Item title="SSO">Single Sign-On in use</DataList.Item>
      )}
    </DataList>
  );
};

export default UserDetails;
