import { Form } from "@redriver/cinnamon-mui";
import { getWorkOrders } from "./actions";

const WorkOrderTypeAhead = ({ clientId, contractId, ...props }) => (
  <Form.TypeAhead
    lookupAction={getWorkOrders}
    lookupParams={{ clientId, contractId }}
    {...props}
  />
);

export default WorkOrderTypeAhead;
