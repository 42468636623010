import { Form } from "@redriver/cinnamon-mui";
import { getJobResources } from "./actions";

const JobResourceTypeAhead = ({
  jobId,
  jobScopeId,
  jobAssignmentId,
  ...props
}) => (
  <Form.TypeAhead
    {...props}
    lookupAction={getJobResources}
    lookupParams={{ jobId, jobScopeId, jobAssignmentId }}
  />
);

export default JobResourceTypeAhead;
