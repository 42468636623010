import { useState } from "react";
import { useDispatch } from "react-redux";
import { Badge, Avatar } from "@mui/material";
import { SignalR, notifyInfo } from "@redriver/cinnamon-mui";
import { NavButton } from "components/navigation";
import { SystemRoutes } from "constants/routes";
import NotificationsIcon from "@mui/icons-material/Notifications";

const ActionsNavButton = () => {
  const [totalUnread, setTotalUnread] = useState(0);
  const dispatch = useDispatch();

  return (
    <SignalR
      hubRoute="signalr/actions"
      onConnected={async (hub) => {
        const unread = await hub.invoke("GetTotalUnreadActions");
        setTotalUnread(unread);
      }}
    >
      <SignalR.Callback
        methodName="OnActionAssigned"
        dependencies={[]}
        callback={(action) => {
          dispatch(
            notifyInfo(
              `${action.title}\n${action.linkedEntityType?.label}: ${action.linkedEntity?.label}\nPriority:${action.priority?.label}`,
              {
                title: "New Action Received",
                expiry: 0,
                customData: {
                  actionId: action.id,
                },
              }
            )
          );
          setTotalUnread((total) => total + 1);
        }}
      />
      <SignalR.Callback
        methodName="OnActionCompleted"
        dependencies={[]}
        callback={(action) => {
          dispatch(
            notifyInfo(
              `${action.title} completed by ${action.completedBy?.label}`,
              {
                title: "Action Completed",
                expiry: 0,
                customData: {
                  actionId: action.id,
                },
              }
            )
          );
        }}
      />
      <SignalR.Callback
        methodName="OnActionRead"
        dependencies={[]}
        callback={(action) => {
          setTotalUnread(action.totalUnread);
        }}
      />
      <NavButton link={SystemRoutes.Actions} active={false} sx={{ px: 1.5 }}>
        <Avatar
          sx={(theme) => ({
            width: 48,
            height: 48,
            overflow: "visible",
            bgcolor: theme.palette.menu.active,
          })}
        >
          <Badge badgeContent={totalUnread} color="error">
            <NotificationsIcon color="secondary" />
          </Badge>
        </Avatar>
      </NavButton>
    </SignalR>
  );
};

export default ActionsNavButton;
