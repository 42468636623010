export const AppTitle = "Energy Engineering";

export const PageTitles = {
  Login: `${AppTitle} - Login`,
  Logout: `${AppTitle} - Logout`,
  SetupUser: `${AppTitle} - Create New Account`,
  VerifyEmail: `${AppTitle} - Verify Email Address`,
  ForgottenPassword: `${AppTitle} - Reset Password`,
  ResetPassword: `${AppTitle} - Create New Password`,
  Clients: `${AppTitle} - Clients`,
  Client: (client: string) => `${AppTitle} - ${client}`,
  Projects: `${AppTitle} - Projects`,
  Project: (project: string) => `${AppTitle} - ${project}`,
  Contracts: `${AppTitle} - Contracts`,
  Contract: (contract: string) => `${AppTitle} - ${contract}`,
  Enquiries: `${AppTitle} - Enquiries`,
  Enquiry: (enquiry: string) => `${AppTitle} - ${enquiry}`,
  EnquiryScope: (scope: string) => `${AppTitle} - ${scope}`,
  Jobs: `${AppTitle} - Jobs`,
  Job: (job: string) => `${AppTitle} - ${job}`,
  JobScope: (scope: string) => `${AppTitle} - ${scope}`,
  Resources: `${AppTitle} - Resources`,
  Resource: (resource: string) => `${AppTitle} - ${resource}`,
  ResourceSearch: `${AppTitle} - Resource Search`,
  Agencies: `${AppTitle} - Agencies`,
  Agency: (agency: string) => `${AppTitle} - ${agency}`,
  Vendors: `${AppTitle} - Vendors`,
  Vendor: (vendor: string) => `${AppTitle} - ${vendor}`,
  ReadyToInvoice: `${AppTitle} - Ready to Invoice`,
  Currencies: `${AppTitle} - Currencies`,
  ExchangeRates: `${AppTitle} - Exchange Rates`,
  Reports: `${AppTitle} - Reports`,
  Users: `${AppTitle} - Users`,
  Roles: `${AppTitle} - Roles`,
  Offices: `${AppTitle} - EE Offices`,
  Lookups: `${AppTitle} - Lookups`,
  Countries: `${AppTitle} - Countries`,
  Commodities: `${AppTitle} - Commodities`,
  CertificateTypes: `${AppTitle} - Certificate Types`,
  GlobalRates: `${AppTitle} - Global Rates`,
  GlobalInvoicing: `${AppTitle} - Global Invoicing`,
  Actions: `${AppTitle} - Actions`,
  Account: `${AppTitle} - My Account`,
  TaxRates: `${AppTitle} - Tax Rates`,
  DraftInvoices: `${AppTitle} - Draft Invoices`,
  DraftInvoice: (clientName: string) =>
    `${PageTitles.DraftInvoices} - ${clientName}`,
  CommittedInvoices: `${AppTitle} - Committed Invoices`,
  CommittedInvoice: (clientName: string) =>
    `${PageTitles.CommittedInvoices} - ${clientName}`,
  CreditNotes: `${AppTitle} - Credit Notes`,
  CreditNote: (clientName: string) =>
    `${PageTitles.CreditNotes} - ${clientName}`,
};
