import React from "react";
import { Typography } from "@mui/material";
import { ListBuilder } from "@redriver/cinnamon-mui";
import { HeaderContainer, ContentContainer } from "components/containers";
import { TablePagination } from "components/pagination";
import { getSearchResults } from "./actions";
import GlobalSearchRow from "./GlobalSearchRow";

const GlobalSearchResults = ({ search }) => (
  <ListBuilder
    loadAction={getSearchResults}
    loadParams={search}
    dataTransform={(data) => data.results}
    totalTransform={(data) => data.totalResults}
    renderList={(data, state, events) => (
      <React.Fragment>
        <HeaderContainer variant="shadow">
          <Typography variant="h5">Search Results ({state.total})</Typography>
        </HeaderContainer>
        <ContentContainer>
          {data.map((r) => (
            <GlobalSearchRow key={`${r.category}_${r.id}`} result={r} />
          ))}
          <TablePagination
            value={state.pagination}
            onChange={events.onChangePagination}
            totalItems={state.total}
          />
        </ContentContainer>
      </React.Fragment>
    )}
  />
);

export default GlobalSearchResults;
