import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 32.631 32.634"
  >
    <g id="customer_2_" data-name="customer (2)" transform="translate(-0.023)">
      <path
        id="Path_5849"
        data-name="Path 5849"
        d="M101.937,239.894l-5.545,8.031a5.76,5.76,0,0,1-4.589,2.295H75.422l-.994-10.058.994-.9c1.453-1.281,2.409-2.161,4.283-2.6a6.637,6.637,0,0,1,5.934,1.453c.382.325.612.631,1.09.631H91.8a1.912,1.912,0,0,1,0,3.824H87.915a.956.956,0,1,0,0,1.912h4.519a2.311,2.311,0,0,0,1.415-.612l5.163-6.425a1.906,1.906,0,0,1,2.524-.249A1.927,1.927,0,0,1,101.937,239.894Z"
        transform="translate(-69.662 -221.41)"
        fill="#ffdba9"
      />
      <g
        id="Group_7785"
        data-name="Group 7785"
        transform="translate(17.297 15.422)"
        opacity="0.27"
        style={{ mixBlendMode: "multiply", isolation: "isolate" }}
      >
        <path
          id="Path_5850"
          data-name="Path 5850"
          d="M271.029,276.78V272h4.844a1.912,1.912,0,0,1,0,3.824h-3.888A.947.947,0,0,0,271.029,276.78Z"
          transform="translate(-271.029 -270.085)"
          fill="#ffdba9"
        />
        <path
          id="Path_5851"
          data-name="Path 5851"
          d="M286.007,245.023l-5.545,8.031a5.76,5.76,0,0,1-4.589,2.295h-4.844v-6.693a.947.947,0,0,0,.956.956H276.5a2.311,2.311,0,0,0,1.415-.612l5.163-6.425a1.906,1.906,0,0,1,2.524-.249A1.927,1.927,0,0,1,286.007,245.023Z"
          transform="translate(-271.029 -241.961)"
          fill="#ffdba9"
        />
      </g>
      <path
        id="Path_5852"
        data-name="Path 5852"
        d="M5.861,296.373.125,284.9a.956.956,0,0,1,.428-1.283l2.114-1.057a2.872,2.872,0,0,1,3.849,1.283l4.026,8.052a2.867,2.867,0,0,1-1.283,3.849L7.144,296.8A.957.957,0,0,1,5.861,296.373Z"
        transform="translate(0 -264.267)"
        fill="#29abd4"
      />
      <path
        id="Path_5853"
        data-name="Path 5853"
        d="M185.829,90a5.863,5.863,0,0,0-5.8,5.8v1.02a.947.947,0,0,0,.956.956h9.688a.947.947,0,0,0,.956-.956V95.8A5.863,5.863,0,0,0,185.829,90Z"
        transform="translate(-168.532 -84.264)"
        fill="#0a67b2"
      />
      <path
        id="Path_5854"
        data-name="Path 5854"
        d="M276.829,95.8v1.02a.947.947,0,0,1-.956.956h-4.844V90A5.863,5.863,0,0,1,276.829,95.8Z"
        transform="translate(-253.732 -84.264)"
        fill="#0855a3"
      />
      <path
        id="Path_5855"
        data-name="Path 5855"
        d="M213.917,0a3.891,3.891,0,0,0-3.888,3.824,3.889,3.889,0,0,0,7.776,0A3.891,3.891,0,0,0,213.917,0Z"
        transform="translate(-196.62)"
        fill="#0a67b2"
      />
      <path
        id="Path_5856"
        data-name="Path 5856"
        d="M274.917,3.824a3.891,3.891,0,0,1-3.888,3.824V0A3.891,3.891,0,0,1,274.917,3.824Z"
        transform="translate(-253.732)"
        fill="#0855a3"
      />
    </g>
  </svg>,
  "Clients"
);
