import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 20.926 26.389"
  >
    <g id="report" transform="translate(-53)">
      <g id="Group_7979" data-name="Group 7979" transform="translate(53 1.446)">
        <path
          id="Path_5892"
          data-name="Path 5892"
          d="M71.606,53H55.319A2.324,2.324,0,0,1,53,50.674v-20.3a2.324,2.324,0,0,1,2.319-2.324H71.606a2.324,2.324,0,0,1,2.319,2.324v20.3A2.324,2.324,0,0,1,71.606,53Z"
          transform="translate(-53 -28.055)"
          fill="#bde0f1"
        />
      </g>
      <path
        id="Path_5893"
        data-name="Path 5893"
        d="M263.2,28.055H255V53h8.2a2.324,2.324,0,0,0,2.319-2.324v-20.3A2.324,2.324,0,0,0,263.2,28.055Z"
        transform="translate(-191.589 -26.609)"
        fill="#bde0f1"
      />
      <g id="Group_7982" data-name="Group 7982" transform="translate(57.201)">
        <path
          id="Path_5896"
          data-name="Path 5896"
          d="M146.251,4.958H135.273a.774.774,0,0,1-.773-.775A4.184,4.184,0,0,1,138.675,0h4.175a4.184,4.184,0,0,1,4.175,4.183A.774.774,0,0,1,146.251,4.958Z"
          transform="translate(-134.5)"
          fill="#207d9a"
        />
      </g>
      <path
        id="Path_5903"
        data-name="Path 5903"
        d="M257.139,0H255V4.958h5.541a.774.774,0,0,0,.773-.775A4.184,4.184,0,0,0,257.139,0Z"
        transform="translate(-191.589)"
        fill="#207d9a"
      />
      <path
        id="resources"
        d="M26.874,17.365l-.623-.08a.529.529,0,0,0-.452.161,3.489,3.489,0,0,0-.817,1.426.539.539,0,0,0,.087.475l.38.5-.38.5a.539.539,0,0,0-.087.475,3.489,3.489,0,0,0,.817,1.426.529.529,0,0,0,.452.161l.623-.08.242.584a.533.533,0,0,0,.365.314,3.43,3.43,0,0,0,1.634,0,.533.533,0,0,0,.365-.314l.242-.584.623.08a.529.529,0,0,0,.452-.161,3.487,3.487,0,0,0,.817-1.426.539.539,0,0,0-.087-.475l-.38-.5.38-.5a.539.539,0,0,0,.087-.475,3.488,3.488,0,0,0-.817-1.426.529.529,0,0,0-.452-.161l-.623.08-.242-.584a.533.533,0,0,0-.365-.314,3.427,3.427,0,0,0-1.634,0,.533.533,0,0,0-.365.314Zm-2.409,4.9a4.568,4.568,0,0,1-.5-1.131,1.616,1.616,0,0,1,.164-1.28,1.617,1.617,0,0,1-.164-1.28,4.56,4.56,0,0,1,1.068-1.865,1.588,1.588,0,0,1,1.182-.5,1.6,1.6,0,0,1,1.018-.783h0a2.638,2.638,0,0,0-1.753-.664H22.3a2.671,2.671,0,0,0-2.66,2.681V20.68A1.576,1.576,0,0,0,21.2,22.262ZM28.3,17.976a1.875,1.875,0,1,0,1.86,1.875A1.869,1.869,0,0,0,28.3,17.976Zm0,1.065a.81.81,0,1,1-.8.81A.808.808,0,0,1,28.3,19.041ZM23.888,8.333a2.679,2.679,0,1,0,2.658,2.679A2.67,2.67,0,0,0,23.888,8.333Z"
        transform="translate(38.828 -0.943)"
        fill="#207d9a"
      />
    </g>
  </svg>,
  "ResourceReport"
);
