import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 26.363 27.531"
  >
    <g id="countries" transform="translate(-10.86)">
      <path
        id="Path_4715"
        data-name="Path 4715"
        d="M14.008,107.869A13.182,13.182,0,0,0,24.042,129.6q.36,0,.716-.019l2.931-2.931v-6.062l-4.956-2.211v-8.805Z"
        transform="translate(0 -102.069)"
        fill="#29abd4"
      />
      <path
        id="Path_4716"
        data-name="Path 4716"
        d="M244.857,123.422a13.192,13.192,0,0,0-1.782-14.2l-11.4,1.63v8.805l4.956,2.211Z"
        transform="translate(-208.939 -103.347)"
        fill="#29abd4"
      />
      <path
        id="Path_4717"
        data-name="Path 4717"
        d="M14.353,117.575a14.6,14.6,0,0,1,3.112-9.032l-3.457-.674a13.185,13.185,0,0,0,5.629,20.976,14.63,14.63,0,0,1-5.284-11.27Z"
        transform="translate(0 -102.069)"
        fill="#29abd4"
        opacity="0.49"
        style={{ mixBlendMode: "multiply", isolation: "isolate" }}
      />
      <path
        id="Path_4718"
        data-name="Path 4718"
        d="M91.315,40.617a13.184,13.184,0,0,1-11.16,7.437l-.342-.831a1.238,1.238,0,0,1,.426-1.479,1.234,1.234,0,0,0,.52-1.007,1.647,1.647,0,0,0-.334-1.266l-.646-.688a1.237,1.237,0,0,1-.119-1.543l.173-.254a1.237,1.237,0,0,0-.636-1.872L77.322,38.5a.736.736,0,0,1-.408-.33l-1.285-2.211a.737.737,0,0,0-1.265-.017l-.308.5a.738.738,0,0,1-1.342-.573l.666-2.548a.737.737,0,0,1,.376-.47l1.356-.7a.737.737,0,0,0,.383-.816,1.429,1.429,0,0,0-1.154-1.1,4.644,4.644,0,0,1-1.691-.451L70.682,28.59a2.276,2.276,0,0,1-1-1.309l-.273-.938h0a13.182,13.182,0,0,1,20.128.073L89,27.367a1.287,1.287,0,0,1-1.018.652,1.286,1.286,0,0,0-1.03.674l-.491.914a1.286,1.286,0,0,1-1.225.675c-.306-.02-.364-.03-.51-.03a2.387,2.387,0,0,0-1.759.773c-.9.978-.465,2.016-1.2,3l-1.118,1.492a.778.778,0,0,0,.166,1.1l.969.7a.779.779,0,0,0,.652.123,8.842,8.842,0,0,1,1.734-.4,1.5,1.5,0,0,1,1.071.449c.812.826,1.7.176,2.54.624a8.191,8.191,0,0,1,.986.547Z"
        transform="translate(-55.397 -20.542)"
        fill="#7ed63e"
      />
      <path
        id="Path_4719"
        data-name="Path 4719"
        d="M76.205,29.152a13.182,13.182,0,0,0-6.8,4.232h0l.273.938a2.276,2.276,0,0,0,1,1.309l.822.5a14.727,14.727,0,0,1,4.7-5.189V29.152Z"
        transform="translate(-55.398 -27.585)"
        fill="#7ed63e"
        opacity="0.42"
        style={{ mixBlendMode: "multiply", isolation: "isolate" }}
      />
      <path
        id="Path_4720"
        data-name="Path 4720"
        d="M133.575,0a2.89,2.89,0,0,0-2.852,3.093,7.214,7.214,0,0,0,2.029,4.1,1.1,1.1,0,0,0,1.625,0,6.984,6.984,0,0,0,2.038-4.346A2.849,2.849,0,0,0,133.575,0Z"
        transform="translate(-113.412 -0.001)"
        fill="#f00f0f"
      />
      <path
        id="Path_4721"
        data-name="Path 4721"
        d="M327.856,265.314A2.89,2.89,0,0,0,325,268.407a7.214,7.214,0,0,0,2.029,4.1,1.1,1.1,0,0,0,1.625,0,6.984,6.984,0,0,0,2.038-4.346A2.849,2.849,0,0,0,327.856,265.314Z"
        transform="translate(-297.246 -251.048)"
        fill="#f00f0f"
      />
      <path
        id="Path_4722"
        data-name="Path 4722"
        d="M132.964,3.027A4.305,4.305,0,0,1,134.146.059a2.854,2.854,0,0,0-3.424,3.034,7.214,7.214,0,0,0,2.029,4.1,1.1,1.1,0,0,0,1.625,0c.156-.172.324-.367.495-.584a4.317,4.317,0,0,1-1.906-3.584Z"
        transform="translate(-113.412 0)"
        fill="#f00f0f"
        opacity="0.5"
        style={{ mixBlendMode: "multiply", isolation: "isolate" }}
      />
      <path
        id="Path_4723"
        data-name="Path 4723"
        d="M327.245,268.34a4.305,4.305,0,0,1,1.182-2.968A2.854,2.854,0,0,0,325,268.406a7.214,7.214,0,0,0,2.029,4.1,1.1,1.1,0,0,0,1.625,0c.156-.172.324-.367.495-.584A4.317,4.317,0,0,1,327.245,268.34Z"
        transform="translate(-297.246 -251.047)"
        fill="#f00f0f"
        opacity="0.5"
        style={{ mixBlendMode: "multiply", isolation: "isolate" }}
      />
      <g
        id="Group_7159"
        data-name="Group 7159"
        transform="translate(19.109 1.806)"
      >
        <circle
          id="Ellipse_25"
          data-name="Ellipse 25"
          cx="1.043"
          cy="1.043"
          r="1.043"
          transform="translate(0)"
          fill="#e8e7f9"
        />
        <circle
          id="Ellipse_26"
          data-name="Ellipse 26"
          cx="1.043"
          cy="1.043"
          r="1.043"
          transform="translate(10.447 14.266)"
          fill="#e8e7f9"
        />
      </g>
    </g>
  </svg>,
  "Countries"
);
