import { Avatar, Typography, Stack } from "@mui/material";
import { useAuthClaims } from "@redriver/cinnamon-mui";
import { NavMenu } from "components/navigation";
import { SystemRoutes } from "constants/routes";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MyAccountIcon from "assets/icons/menu/MyAccount";
import LogOutIcon from "assets/icons/menu/LogOut";

const AccountNavMenu = () => {
  const { userName } = useAuthClaims();
  const [firstName, lastName] = userName.split(" ");
  return (
    <NavMenu
      trigger={
        <Stack flexDirection="row" alignItems="center">
          <Avatar
            sx={(theme) => ({
              width: 48,
              height: 48,
              fontSize: "1.4rem",
              fontWeight: 600,
              letterSpacing: 1,
              bgcolor: theme.palette.secondary.main,
            })}
          >
            {firstName && firstName[0]}
            {lastName && lastName[0]}
          </Avatar>
          <ArrowDropDownIcon
            sx={{
              fontSize: "1.7rem",
              mr: "-0.5em",
            }}
          />
        </Stack>
      }
      direction="left"
      sx={{ pl: 1.5 }}
    >
      <Typography variant="overline" display="block" textAlign="center">
        {userName}
      </Typography>
      <NavMenu.Item link={SystemRoutes.Account} icon={<MyAccountIcon />}>
        My Account
      </NavMenu.Item>
      <NavMenu.Item link={SystemRoutes.Logout} icon={<LogOutIcon />}>
        Log Out
      </NavMenu.Item>
    </NavMenu>
  );
};

export default AccountNavMenu;
