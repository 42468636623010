import { Form } from "@redriver/cinnamon-mui";
import { billingAreasLookup } from "modules/lookups";

const BillingAreaTypeAhead = ({ maxResults = 10, ...props }) => (
  <Form.TypeAhead
    lookup={billingAreasLookup}
    lookupParams={{ maxResults }}
    {...props}
  />
);

export default BillingAreaTypeAhead;
