import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 32 20"
  >
    <path
      id="Path_4688"
      data-name="Path 4688"
      d="M33.923,83.718a3.86,3.86,0,1,1,0-7.718,3.859,3.859,0,1,1,0,7.718Zm0,0"
      transform="translate(-28.07 -71.238)"
      fill="#29abd4"
    />
    <path
      id="Path_4689"
      data-name="Path 4689"
      d="M5.853,196A5.844,5.844,0,0,0,0,201.789v1.93H7.782l3.013-2.75-.708-3.1A5.755,5.755,0,0,0,5.853,196Zm0,0"
      transform="translate(0 -183.718)"
      fill="#29abd4"
    />
    <path
      id="Path_4690"
      data-name="Path 4690"
      d="M364.859,83.718a3.859,3.859,0,1,1,3.859-3.859A3.863,3.863,0,0,1,364.859,83.718Zm0,0"
      transform="translate(-338.712 -71.238)"
      fill="#207d9a"
    />
    <path
      id="Path_4691"
      data-name="Path 4691"
      d="M351.164,196a5.754,5.754,0,0,0-4.235,1.867l-.566,3.462,2.871,2.389h7.782v-1.93A5.844,5.844,0,0,0,351.164,196Zm0,0"
      transform="translate(-325.017 -183.718)"
      fill="#207d9a"
    />
    <path
      id="Path_4692"
      data-name="Path 4692"
      d="M171.789,0a5.821,5.821,0,1,0,5.789,5.853A5.861,5.861,0,0,0,171.789,0Zm0,0"
      transform="translate(-155.789)"
      fill="#7edaf7"
    />
    <path
      id="Path_4693"
      data-name="Path 4693"
      d="M137.435,189.683H121a8.932,8.932,0,0,1,2.173-5.847,7.859,7.859,0,0,1,12.089,0A8.932,8.932,0,0,1,137.435,189.683Zm0,0"
      transform="translate(-113.218 -169.683)"
      fill="#7edaf7"
    />
    <g id="Group_7028" data-name="Group 7028" transform="translate(15.833)">
      <path
        id="Path_4694"
        data-name="Path 4694"
        d="M256,11.641V0a5.821,5.821,0,0,1,0,11.641Zm0,0"
        transform="translate(-256)"
        fill="#7edaf7"
      />
      <path
        id="Path_4695"
        data-name="Path 4695"
        d="M264.683,189.683H256V181a8.742,8.742,0,0,1,8.683,8.683Zm0,0"
        transform="translate(-256 -169.683)"
        fill="#7edaf7"
      />
    </g>
  </svg>,
  "Users"
);
