import { createSvgIcon } from "@mui/material";

export default createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 28.154 28.154"
  >
    <g id="Group_8001" data-name="Group 8001" transform="translate(-1592 -389)">
      <g id="contract" transform="translate(1592 389)">
        <path
          id="Path_5909"
          data-name="Path 5909"
          d="M20.595,4.949H16.471V.825A.817.817,0,0,0,15.646,0H2.474A2.471,2.471,0,0,0,0,2.474V25.68a2.471,2.471,0,0,0,2.474,2.474H18.945A2.471,2.471,0,0,0,21.42,25.68V5.774A.817.817,0,0,0,20.595,4.949Z"
          fill="#d1dde7"
        />
        <path
          id="Path_5910"
          data-name="Path 5910"
          d="M205.248,5.774V25.68a2.471,2.471,0,0,1-2.474,2.474h-8.248V0h4.949a.817.817,0,0,1,.825.825V4.949h4.124A.817.817,0,0,1,205.248,5.774Z"
          transform="translate(-183.828)"
          fill="#d1dde7"
        />
        <path
          id="Path_5912"
          data-name="Path 5912"
          d="M409.7,148.68a2.521,2.521,0,0,0-3.5,0l-.841.858-.594.577a.8.8,0,0,0,0,1.155l1.171,1.171,1.171,1.171a.8.8,0,0,0,1.155,0l.594-.577.841-.858A2.459,2.459,0,0,0,409.7,148.68Z"
          transform="translate(-382.28 -139.838)"
          fill="#0a67b2"
        />
        <path
          id="Path_5913"
          data-name="Path 5913"
          d="M271.6,302.174l-1.7-1.7a.062.062,0,0,0-.1,0l-.049.033a.77.77,0,0,0-.2.33l-1.171,3.514a.773.773,0,0,0,.2.841v.016a.8.8,0,0,0,.841.181l3.514-1.171a.769.769,0,0,0,.33-.2l.033-.033a.062.062,0,0,0,0-.1C272.758,303.328,272.164,302.735,271.6,302.174Z"
          transform="translate(-253.582 -283.928)"
          fill="#fedbab"
        />
        <g
          id="Group_7999"
          data-name="Group 7999"
          transform="translate(3.308 8.248)"
        >
          <path
            id="Path_5914"
            data-name="Path 5914"
            d="M72.49,151.65H60.969a.825.825,0,0,1,0-1.65H72.49A.825.825,0,0,1,72.49,151.65Z"
            transform="translate(-60.152 -150)"
            fill="#0a67b2"
          />
          <path
            id="Path_5915"
            data-name="Path 5915"
            d="M69.191,211.65H60.969a.825.825,0,0,1,0-1.65h8.222A.825.825,0,0,1,69.191,211.65Z"
            transform="translate(-60.152 -206.701)"
            fill="#0a67b2"
          />
          <path
            id="Path_5916"
            data-name="Path 5916"
            d="M69.191,271.65H60.969a.825.825,0,0,1,0-1.65h8.222A.825.825,0,0,1,69.191,271.65Z"
            transform="translate(-60.152 -263.401)"
            fill="#0a67b2"
          />
          <path
            id="Path_5917"
            data-name="Path 5917"
            d="M69.191,331.65H60.969a.825.825,0,0,1,0-1.65h8.222A.825.825,0,0,1,69.191,331.65Z"
            transform="translate(-60.152 -320.102)"
            fill="#0a67b2"
          />
          <path
            id="Path_5918"
            data-name="Path 5918"
            d="M185.443,423.65h-4.949a.825.825,0,0,1,0-1.65h4.949A.825.825,0,0,1,185.443,423.65Z"
            transform="translate(-173.105 -407.043)"
            fill="#0a67b2"
          />
        </g>
        <path
          id="Path_5919"
          data-name="Path 5919"
          d="M275.844,331.8c.561.561,1.155,1.155,1.7,1.716a.062.062,0,0,1,0,.1l-.033.033a.769.769,0,0,1-.33.2l-3.514,1.171a.8.8,0,0,1-.841-.181v-.016Z"
          transform="translate(-257.823 -313.555)"
          fill="#fec478"
        />
        <path
          id="Path_5920"
          data-name="Path 5920"
          d="M434.086,164.3l-.841.858-.594.577a.8.8,0,0,1-1.155,0l-1.171-1.171,3.761-3.761A2.46,2.46,0,0,1,434.086,164.3Z"
          transform="translate(-406.662 -151.957)"
          fill="#0a67b2"
        />
        <path
          id="Path_5921"
          data-name="Path 5921"
          d="M301.131,179.614l-.594.577c-3.266,3.25-2.178,2.161-5.114,5.1,0,.033-1.171,1.2-1.188,1.221a.768.768,0,0,1-.33.2l-3.382-3.382a.769.769,0,0,1,.2-.33c.016-.017,1.188-1.188,1.221-1.188,2.144-2.145,1.089-1.072,5.1-5.114l.577-.594Z"
          transform="translate(-274.548 -166.416)"
          fill="#cc0808"
        />
        <path
          id="Path_5922"
          data-name="Path 5922"
          d="M330.331,209.949l-.594.577c-3.266,3.25-2.178,2.161-5.114,5.1,0,.033-1.171,1.2-1.188,1.221a.768.768,0,0,1-.33.2l-1.683-1.683,7.16-7.159Z"
          transform="translate(-303.748 -196.751)"
          fill="#cc0808"
        />
        <g
          id="Group_8000"
          data-name="Group 8000"
          transform="translate(10.697 8.248)"
        >
          <path
            id="Path_5923"
            data-name="Path 5923"
            d="M196.175,331.65h-1.65V330h1.65A.825.825,0,0,1,196.175,331.65Z"
            transform="translate(-194.525 -320.102)"
            fill="#0a67b2"
          />
          <path
            id="Path_5924"
            data-name="Path 5924"
            d="M196.175,271.65h-1.65V270h1.65A.825.825,0,0,1,196.175,271.65Z"
            transform="translate(-194.525 -263.401)"
            fill="#0a67b2"
          />
          <path
            id="Path_5925"
            data-name="Path 5925"
            d="M196.175,211.65h-1.65V210h1.65A.825.825,0,0,1,196.175,211.65Z"
            transform="translate(-194.525 -206.701)"
            fill="#0a67b2"
          />
          <path
            id="Path_5926"
            data-name="Path 5926"
            d="M199.474,423.65h-4.949V422h4.949A.825.825,0,0,1,199.474,423.65Z"
            transform="translate(-194.525 -407.043)"
            fill="#0a67b2"
          />
          <path
            id="Path_5927"
            data-name="Path 5927"
            d="M199.474,151.65h-4.949V150h4.949A.825.825,0,0,1,199.474,151.65Z"
            transform="translate(-194.525 -150)"
            fill="#0a67b2"
          />
        </g>
        <path
          id="Path_5911"
          data-name="Path 5911"
          d="M276.124,6.6H270.35a.824.824,0,0,1-.825-.825V0h1.8a2.476,2.476,0,0,1,1.74.721L275.4,3.062a2.48,2.48,0,0,1,.72,1.738Z"
          transform="translate(-254.704)"
          fill="#cfe1f0"
        />
      </g>
    </g>
  </svg>,
  "ContractReport"
);
